import instance from './instance'

export function getWhatsApp(id = '') {
    return instance.get(`/settings/whatsapp/${id}`)
}

export function postWhatsApp(description,settings,activated) {
    return instance.post(`/settings/whatsapp/`, {description,settings,activated})
}

export function putWhatsApp(id, description,settings,activated) {
    return instance.put(`/settings/whatsapp/`, {id, description,settings,activated})
}

export function postWhatsAppHSM(whatsapp_id, data) {
    return instance.post(`/settings/whatsapp/${whatsapp_id}/hsm`, data)
}

export function putWhatsAppHSM(id_HSM, whatsapp_id, data) {
    return instance.put(`/settings/whatsapp/${whatsapp_id}/hsm/${id_HSM}`, data)
}

export function getWhatsAppHSM(id = '') {
    return instance.get(`/settings/whatsapp/${id}/hsm`)
}