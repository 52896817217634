import {
    PROTOCOL_COUNT, PROTOCOL_COUNTDEPARTMENT, PROTOCOL_COUNTTYPE,
    PROTOCOL_MESSAGE, PROTOCOL_ADDMESSAGE,
    PROTOCOL_HISTORY, PROTOCOL_NOTE, PROTOCOL_DISABLEIA,
    PROTOCOL_SELECT, PROTOCOL_SELECTUPDATE, PROTOCOL_RESET,
    PROTOCOL_ALL, PROTOCOL_ALLUPDATE, PROTOCOL_ALLRESET,
    PROTOCOL_ALLHUMAN, PROTOCOL_ALLHUMANUPDATE, PROTOCOL_ALLHUMANRESET,
    PROTOCOL_ALLBOT, PROTOCOL_ALLBOTUPDATE, PROTOCOL_ALLBOTRESET,
    PROTOCOL_OPEN, PROTOCOL_OPENUPDATE, PROTOCOL_OPENRESET,
    PROTOCOL_OPENBOT, PROTOCOL_OPENBOTUPDATE, PROTOCOL_OPENBOTRESET,
    PROTOCOL_OPENHUMAN, PROTOCOL_OPENHUMANUPDATE, PROTOCOL_OPENHUMANRESET,
    PROTOCOL_OPENWAITING, PROTOCOL_OPENWAITINGUPDATE, PROTOCOL_OPENWAITINGRESET,
    PROTOCOL_OPENWAITINGCLIENT, PROTOCOL_OPENWAITINGCLIENTUPDATE, PROTOCOL_OPENWAITINGCLIENTRESET,
    PROTOCOL_OPENWAITINGOPERATOR, PROTOCOL_OPENWAITINGOPERATORUPDATE, PROTOCOL_OPENWAITINGOPERATORRESET,
    PROTOCOL_CLOSED, PROTOCOL_CLOSEDUPDATE, PROTOCOL_CLOSEDRESET,
    PROTOCOL_CLOSEDBOT, PROTOCOL_CLOSEDBOTUPDATE, PROTOCOL_CLOSEDBOTRESET,
    PROTOCOL_NPS, PROTOCOL_NPSUPDATE, PROTOCOL_NPSRESET,
    PROTOCOL_NPSHUMAN, PROTOCOL_NPSHUMANUPDATE, PROTOCOL_NPSHUMANRESET,
    PROTOCOL_NPSBOT, PROTOCOL_NPSBOTUPDATE, PROTOCOL_NPSBOTRESET,
    PROTOCOL_FLOW, PROTOCOL_FLOWUPDATE, PROTOCOL_FLOWRESET,
    PROTOCOL_FLOWHUMAN, PROTOCOL_FLOWHUMANUPDATE, PROTOCOL_FLOWHUMANRESET,
    PROTOCOL_FLOWBOT, PROTOCOL_FLOWBOTUPDATE, PROTOCOL_FLOWBOTRESET,
    PROTOCOL_VIDEOVOICERUN, PROTOCOL_VIDEOVOICERUNUPDATE, PROTOCOL_VIDEOVOICERUNRESET,
    PROTOCOL_VIDEOVOICEQUEUE, PROTOCOL_VIDEOVOICEQUEUEUPDATE, PROTOCOL_VIDEOVOICEQUEUERESET,
    PROTOCOL_CLOSEDHUMAN, PROTOCOL_CLOSEDHUMANUPDATE, PROTOCOL_CLOSEDHUMANRESET, PROTOCOL_CUSTOMERUPDATE,
    EnumStatus, EnumSupport, PROTOCOL_LIVESTREAM, PROTOCOL_LIVESTREAMRESET, PROTOCOL_LIVESTREAMUPDATE, PROTOCOL_PUTCPC
} from '../actions'
import socket from '../../api/socket'
import moment from 'moment'

export function protocolSelect(currentState = {select: false, load: false}, action) {
    switch (action.type) {
        case PROTOCOL_SELECT:
            return {
                ...action
            }
        case PROTOCOL_SELECTUPDATE:
            return {
                ...currentState,
                ...action
            }
        case PROTOCOL_PUTCPC:    
            return {
              ...currentState,
              customers: currentState.customers.map(
                (customer, customerIndex) => {
                  if (customerIndex === 0) {
                    customer.data = {
                      ...customer.data,
                      ...action.payload,
                    };
                  }
                  return customer;
                }
              ),
            };
        case PROTOCOL_CUSTOMERUPDATE:
            /*if (action.hasOwnProperty("historico") && currentState.customers && Number.isInteger(action.customer_index)) {
                return {
                    ...currentState,
                    customers: currentState.customers.map(x => x.id === currentState.customers[action.customer_index].id ? {
                        ...x,
                        historico: x.historico ? [...x.historico, ...action.historico.data].sort((a, b) => b.codigo - a.codigo) : action.historico.data.sort((a, b) => b.codigo - a.codigo)
                    } : x)
                }
            } else if (action.hasOwnProperty("cpc") && currentState.customers && Number.isInteger(action.customer_index)) {
                return {
                    ...currentState,
                    customers: currentState.customers.map(x => x.id === currentState.customers[action.customer_index].id ? {
                        ...x,
                        isSensivel: action.sensivel,
                        descSensivel: action.descSensivel,
                    } : x)
                }
            } else if (action.hasOwnProperty("carencia") && currentState.customers && Number.isInteger(action.customer_index)) {
                *let newData = currentState.customers[action.customer_index]
                if (newData && newData.data && Array.isArray(newData.data.contratos)) {
                    newData.data.contratos = newData.data.contratos.map(x => {
                        let familia = x.familia.map(y => {
                            let carencia = y.carencia === "NAO" ? [] : (action.carencia.matricula === y.matricula ? action.carencia.data : y.carencia)
                            return ({...y, carencia})
                        })
                        return {...x, familia}
                    })
                }
                return {
                    ...currentState,
                    customers: currentState.customers.map(x => x.id === currentState.customers[action.customer_index].id ? {...newData} : x)
                }*
                let newData = currentState.customers[action.customer_index]

                if (newData && newData.data && Array.isArray(newData.data.contratos)) {
                    newData.data.contratos = newData.data.contratos.map(x => {
                        let familia = action.carencia.length > 0 ? x.familia.map(y => {
                            let carencia = y.carencia === "NAO" ? [] : action.carencia.filter(z => z.matricula === y.matricula)[0].data
                            return ({...y, carencia})
                        }) : x.familia
                        return {...x, familia}
                    })
                }
                return {
                    ...currentState,
                    customers: currentState.customers.map(x => x.id === currentState.customers[action.customer_index].id ? {...newData} : x)
                }
            } else if (action.hasOwnProperty("mensalidades") && currentState.customers && Number.isInteger(action.customer_index)) {
                let newData = currentState.customers[action.customer_index]
                if(newData && newData.data && Array.isArray(newData.data.contratos)) {
                    newData.data.contratos = newData.data.contratos.map(x => {
                        let mensalidades = action.mensalidades.filter(y => y.contract_id === x.contrato)
                        return mensalidades.length > 0 ? ({...x, mensalidades: mensalidades[0].data}) : {...x}
                    })
                }
                return {
                    ...currentState,
                    customers: currentState.customers.map(x => x.id === currentState.customers[action.customer_index].id ? {...newData} : x)
                }
            }*/
            return {...currentState}
        case PROTOCOL_NOTE:
            return {
                ...currentState,
                notes: Array.isArray(currentState.notes) ? [...currentState.notes, action.notes] : [action.notes]
            }
        case PROTOCOL_HISTORY:
            return {
                ...currentState,
                history: action.history
            }
        case PROTOCOL_DISABLEIA:
            return {
                ...currentState,
                protocol_ia_enabled: !currentState.protocol_ia_enabled
            }
        case PROTOCOL_RESET:
            return {select: false, ...action}
        case PROTOCOL_MESSAGE:
            return (currentState.id === action.message.protocol_id) ? {
                ...currentState,
                messages: currentState.messages.find(({message_id}) => action.message.message_id === message_id) ? currentState.messages.map(message => message.message_id !== action.message.message_id ? message : ({
                    ...action.message,
                })) : [...currentState.messages, {
                    ...action.message,
                }]
            } : currentState
        case PROTOCOL_ADDMESSAGE:
            return {
                ...currentState,
                ...action,
                messages: [...action.messages, currentState]
            }
        default:
            return currentState
    }
}

export function protocolAll(currentState = {}, action) {
    switch (action.type) {
        case PROTOCOL_ALLRESET:
            return {}
        case PROTOCOL_ALLUPDATE:
            let protocols = currentState && Array.isArray(currentState.protocols) ? [...currentState.protocols] : []
            let protocolIndex = protocols.map(x => x.id).indexOf(Number(action.id))
            if (action && action.auth.role <= 2 && action.protocol.user_id !== action.auth.id) {
                action.protocol && (protocols = protocols.filter(current => current.id !== action.protocol.id))
            } else {
                action.protocol && (protocolIndex === -1 ? protocols.push(action.protocol) : (protocols = protocols.map(current => current.id === action.protocol.id ? {...current, ...action.protocol} : current)))
            }
            return {
                ...currentState,
                type: action.type,
                protocols
            }
        case PROTOCOL_ALL:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    protocols: [...currentState.protocols, ...action.protocols]
                }
        default:
            return currentState
    }
}

export function protocolAllBot(currentState = {}, action) {
    switch (action.type) {
        case PROTOCOL_ALLBOTRESET:
            return {}
        case PROTOCOL_ALLBOTUPDATE:
            let protocols = currentState && Array.isArray(currentState.protocols) ? [...currentState.protocols] : []
            let protocolIndex = protocols.map(x => x.id).indexOf(Number(action.id))
            if (action.auth.role <= 2 && action.protocol.user_id !== action.auth.id) {
                action.protocol && (protocols = protocols.filter(current => current.id !== action.protocol.id))
            } else {
                action.protocol && (protocolIndex === -1 && action.support === EnumSupport.BOT ? protocols.push(action.protocol) : (protocols = action.support !== EnumSupport.BOT ? protocols.filter(current => current.id !== action.protocol.id) : protocols.map(current => current.id === action.protocol.id ? {...current, ...action.protocol} : current)))
            }
            return {
                ...currentState,
                type: action.type,
                protocols
            }
        case PROTOCOL_ALLBOT:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    protocols: [...currentState.protocols, ...action.protocols]
                }
        default:
            return currentState
    }
}

export function protocolAllHuman(currentState = {}, action) {
    switch (action.type) {
        case PROTOCOL_ALLHUMANRESET:
            return {}
        case PROTOCOL_ALLHUMANUPDATE:
            let protocols = currentState && Array.isArray(currentState.protocols) ? [...currentState.protocols] : []
            let protocolIndex = protocols.map(x => x.id).indexOf(Number(action.id))
            if (action.auth.role <= 2 && action.protocol.user_id !== action.auth.id) {
                action.protocol && (protocols = protocols.filter(current => current.id !== action.protocol.id))
            } else {
                action.protocol && (protocolIndex === -1 && action.support === EnumSupport.HUMAN ? protocols.push(action.protocol) : (protocols = action.support !== EnumSupport.HUMAN ? protocols.filter(current => current.id !== action.protocol.id) : protocols.map(current => current.id === action.protocol.id ? {...current, ...action.protocol} : current)))
            }
            return {
                ...currentState,
                type: action.type,
                protocols
            }
        case PROTOCOL_ALLHUMAN:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    protocols: [...currentState.protocols, ...action.protocols]
                }
        default:
            return currentState
    }
}

export function protocolOpen(currentState = {}, action) {
    switch (action.type) {
        case PROTOCOL_OPENRESET:
            return {}
        case PROTOCOL_OPENUPDATE:
            let protocols = currentState && Array.isArray(currentState.protocols) ? [...currentState.protocols] : []
            let protocolIndex = protocols.map(x => x.id).indexOf(Number(action.id))
            if (action.auth.role <= 2 && action.protocol.user_id !== action.auth.id) {
                action.protocol && (protocols = protocols.filter(current => current.id !== action.protocol.id))
            } else {
                action.protocol && ((protocolIndex === -1 && (action.status === EnumStatus.WAITING || action.status === EnumStatus.AWAITING_OPERATOR || action.status === EnumStatus.AWAITING_CUSTOMER)) ? protocols.push(action.protocol) : (protocols = (action.status !== EnumStatus.WAITING && action.status !== EnumStatus.AWAITING_OPERATOR && action.status !== EnumStatus.AWAITING_CUSTOMER) ? protocols.filter(current => current.id !== action.protocol.id) : (protocols.map(current => current.id === action.protocol.id ? {...current, ...action.protocol} : current))))
            }
            return {
                ...currentState,
                type: action.type,
                protocols
            }
        case PROTOCOL_OPEN:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    protocols: [...currentState.protocols, ...action.protocols]
                }
        default:
            return currentState
    }
}

export function protocolOpenBot(currentState = {}, action) {
    switch (action.type) {
        case PROTOCOL_OPENBOTRESET:
            return {}
        case PROTOCOL_OPENBOTUPDATE:
            let protocols = currentState && Array.isArray(currentState.protocols) ? [...currentState.protocols] : []
            let protocolIndex = protocols.map(x => x.id).indexOf(Number(action.id))
            if (action.auth.role <= 2 && action.protocol.user_id !== action.auth.id) {
                action.protocol && (protocols = protocols.filter(current => current.id !== action.protocol.id))
            } else {
                action.protocol && ((protocolIndex === -1 && (action.status === EnumStatus.WAITING || action.status === EnumStatus.AWAITING_OPERATOR || action.status === EnumStatus.AWAITING_CUSTOMER) && action.support === EnumSupport.BOT) ? protocols.push(action.protocol) : (protocols = ((action.status !== EnumStatus.WAITING && action.status !== EnumStatus.AWAITING_OPERATOR && action.status !== EnumStatus.AWAITING_CUSTOMER) || action.support !== EnumSupport.BOT) ? protocols.filter(current => current.id !== action.protocol.id) : (protocols.map(current => current.id === action.protocol.id ? {...current, ...action.protocol} : current))))
            }
            return {
                ...currentState,
                type: action.type,
                protocols
            }
        case PROTOCOL_OPENBOT:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    protocols: [...currentState.protocols, ...action.protocols]
                }
        default:
            return currentState
    }
}

export function protocolOpenHuman(currentState = {}, action) {
    switch (action.type) {
        case PROTOCOL_OPENHUMANRESET:
            return {}
        case PROTOCOL_OPENHUMANUPDATE:
            let protocols = currentState && Array.isArray(currentState.protocols) ? [...currentState.protocols] : []
            let protocolIndex = protocols.map(x => x.id).indexOf(Number(action.id))
            if (action.auth.role <= 2 && action.protocol.user_id !== action.auth.id) {
                action.protocol && (protocols = protocols.filter(current => current.id !== action.protocol.id))
            } else {
                action.protocol && ((protocolIndex === -1 && (action.status === EnumStatus.WAITING || action.status === EnumStatus.AWAITING_CUSTOMER || action.status === EnumStatus.AWAITING_OPERATOR) && action.support === EnumSupport.HUMAN) ? protocols.push(action.protocol) : (protocols = ((action.status !== EnumStatus.WAITING && action.status !== EnumStatus.AWAITING_OPERATOR && action.status !== EnumStatus.AWAITING_CUSTOMER) || action.support !== EnumSupport.HUMAN) ? protocols.filter(current => current.id !== action.protocol.id) : (protocols.map(current => current.id === action.protocol.id ? {...current, ...action.protocol} : current))))
            }
            return {
                ...currentState,
                type: action.type,
                protocols
            }
        case PROTOCOL_OPENHUMAN:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    protocols: [...currentState.protocols, ...action.protocols]
                }
        default:
            return currentState
    }
}

export function protocolNPS(currentState = {}, action) {
    switch (action.type) {
        case PROTOCOL_NPSRESET:
            return {}
        case PROTOCOL_NPSUPDATE:
            let protocols = currentState && Array.isArray(currentState.protocols) ? [...currentState.protocols] : []
            let protocolIndex = protocols.map(x => x.id).indexOf(Number(action.id))
            if (action.auth.role <= 2 && action.protocol.user_id !== action.auth.id) {
                action.protocol && (protocols = protocols.filter(current => current.id !== action.protocol.id))
            } else {
                action.protocol && ((protocolIndex === -1 && (action.status === EnumStatus.NPS)) ? protocols.push(action.protocol) : (protocols = (action.status !== EnumStatus.NPS) ? protocols.filter(current => current.id !== action.protocol.id) : (protocols.map(current => current.id === action.protocol.id ? {...current, ...action.protocol} : current))))
            }
            return {
                ...currentState,
                type: action.type,
                protocols
            }
        case PROTOCOL_NPS:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    protocols: [...currentState.protocols, ...action.protocols]
                }
        default:
            return currentState
    }
}

export function protocolNPSHuman(currentState = {}, action) {
    switch (action.type) {
        case PROTOCOL_NPSHUMANRESET:
            return {}
        case PROTOCOL_NPSHUMANUPDATE:
            let protocols = currentState && Array.isArray(currentState.protocols) ? [...currentState.protocols] : []
            let protocolIndex = protocols.map(x => x.id).indexOf(Number(action.id))
            if (action.auth.role <= 2 && action.protocol.user_id !== action.auth.id) {
                action.protocol && (protocols = protocols.filter(current => current.id !== action.protocol.id))
            } else {
                action.protocol && ((protocolIndex === -1 && action.status === EnumStatus.NPS && action.support === EnumSupport.HUMAN) ? protocols.push(action.protocol) : (protocols = (action.status !== EnumStatus.NPS || action.support !== EnumSupport.HUMAN) ? protocols.filter(current => current.id !== action.protocol.id) : (protocols.map(current => current.id === action.protocol.id ? {...current, ...action.protocol} : current))))
            }
            return {
                ...currentState,
                type: action.type,
                protocols
            }
        case PROTOCOL_NPSHUMAN:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    protocols: [...currentState.protocols, ...action.protocols]
                }
        default:
            return currentState
    }
}

export function protocolNPSBot(currentState = {}, action) {
    switch (action.type) {
        case PROTOCOL_NPSBOTRESET:
            return {}
        case PROTOCOL_NPSBOTUPDATE:
            let protocols = currentState && Array.isArray(currentState.protocols) ? [...currentState.protocols] : []
            let protocolIndex = protocols.map(x => x.id).indexOf(Number(action.id))
            if (action.auth.role <= 2 && action.protocol.user_id !== action.auth.id) {
                action.protocol && (protocols = protocols.filter(current => current.id !== action.protocol.id))
            } else {
                action.protocol && ((protocolIndex === -1 && action.status === EnumStatus.NPS && action.support === EnumSupport.BOT) ? protocols.push(action.protocol) : (protocols = (action.status !== EnumStatus.NPS || action.support !== EnumSupport.BOT) ? protocols.filter(current => current.id !== action.protocol.id) : (protocols.map(current => current.id === action.protocol.id ? {...current, ...action.protocol} : current))))
            }
            return {
                ...currentState,
                type: action.type,
                protocols
            }
        case PROTOCOL_NPSBOT:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    protocols: [...currentState.protocols, ...action.protocols]
                }
        default:
            return currentState
    }
}

export function protocolFlow(currentState = {}, action) {
    switch (action.type) {
        case PROTOCOL_FLOWRESET:
            return {}
        case PROTOCOL_FLOWUPDATE:
            let protocols = currentState && Array.isArray(currentState.protocols) ? [...currentState.protocols] : []
            let protocolIndex = protocols.map(x => x.id).indexOf(Number(action.id))
            if (action.auth.role <= 2 && action.protocol.user_id !== action.auth.id) {
                action.protocol && (protocols = protocols.filter(current => current.id !== action.protocol.id))
            } else {
                action.protocol && ((protocolIndex === -1 && (action.status === EnumStatus.FLOW)) ? protocols.push(action.protocol) : (protocols = (action.status !== EnumStatus.FLOW) ? protocols.filter(current => current.id !== action.protocol.id) : (protocols.map(current => current.id === action.protocol.id ? {...current, ...action.protocol} : current))))
            }
            return {
                ...currentState,
                type: action.type,
                protocols
            }
        case PROTOCOL_FLOW:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    protocols: [...currentState.protocols, ...action.protocols]
                }
        default:
            return currentState
    }
}

export function protocolFlowHuman(currentState = {}, action) {
    switch (action.type) {
        case PROTOCOL_FLOWHUMANRESET:
            return {}
        case PROTOCOL_FLOWHUMANUPDATE:
            let protocols = currentState && Array.isArray(currentState.protocols) ? [...currentState.protocols] : []
            let protocolIndex = protocols.map(x => x.id).indexOf(Number(action.id))
            if (action.auth.role <= 2 && action.protocol.user_id !== action.auth.id) {
                action.protocol && (protocols = protocols.filter(current => current.id !== action.protocol.id))
            } else {
                action.protocol && ((protocolIndex === -1 && action.status === EnumStatus.FLOW && action.support === EnumSupport.HUMAN) ? protocols.push(action.protocol) : (protocols = (action.status !== EnumStatus.FLOW || action.support !== EnumSupport.HUMAN) ? protocols.filter(current => current.id !== action.protocol.id) : (protocols.map(current => current.id === action.protocol.id ? {...current, ...action.protocol} : current))))
            }
            return {
                ...currentState,
                type: action.type,
                protocols
            }
        case PROTOCOL_FLOWHUMAN:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    protocols: [...currentState.protocols, ...action.protocols]
                }
        default:
            return currentState
    }
}

export function protocolFlowBot(currentState = {}, action) {
    switch (action.type) {
        case PROTOCOL_FLOWBOTRESET:
            return {}
        case PROTOCOL_FLOWBOTUPDATE:
            let protocols = currentState && Array.isArray(currentState.protocols) ? [...currentState.protocols] : []
            let protocolIndex = protocols.map(x => x.id).indexOf(Number(action.id))
            if (action.auth.role <= 2 && action.protocol.user_id !== action.auth.id) {
                action.protocol && (protocols = protocols.filter(current => current.id !== action.protocol.id))
            } else {
                action.protocol && ((protocolIndex === -1 && action.status === EnumStatus.FLOW && action.support === EnumSupport.BOT) ? protocols.push(action.protocol) : (protocols = (action.status !== EnumStatus.FLOW || action.support !== EnumSupport.BOT) ? protocols.filter(current => current.id !== action.protocol.id) : (protocols.map(current => current.id === action.protocol.id ? {...current, ...action.protocol} : current))))
            }
            return {
                ...currentState,
                type: action.type,
                protocols
            }
        case PROTOCOL_FLOWBOT:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    protocols: [...currentState.protocols, ...action.protocols]
                }
        default:
            return currentState
    }
}

export function protocolOpenWaiting(currentState = {}, action) {
    switch (action.type) {
        case PROTOCOL_OPENWAITINGRESET:
            return {}
        case PROTOCOL_OPENWAITINGUPDATE:
            let protocols = currentState && Array.isArray(currentState.protocols) ? [...currentState.protocols] : []
            let protocolIndex = protocols.map(x => x.id).indexOf(Number(action.id))
            if (action.auth.role <= 2 && action.protocol.user_id !== action.auth.id) {
                action.protocol && (protocols = protocols.filter(current => current.id !== action.protocol.id))
            } else {
                action.protocol && ((protocolIndex === -1 && action.status === EnumStatus.WAITING && action.support === EnumSupport.HUMAN) ? protocols.push(action.protocol) : (protocols = (action.status !== EnumStatus.WAITING || action.support !== EnumSupport.HUMAN) ? protocols.filter(current => current.id !== action.protocol.id) : (protocols.map(current => current.id === action.protocol.id ? {...current, ...action.protocol} : current))))
            }
            return {
                ...currentState,
                type: action.type,
                protocols
            }
        case PROTOCOL_OPENWAITING:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    protocols: [...currentState.protocols, ...action.protocols]
                }
        default:
            return currentState
    }
}

export function protocolVideoVoiceQueue(currentState = {}, action) {
    switch (action.type) {
        case PROTOCOL_VIDEOVOICEQUEUERESET:
            return {}
        case PROTOCOL_VIDEOVOICEQUEUEUPDATE:
            let protocols = currentState && Array.isArray(currentState.protocols) ? [...currentState.protocols] : []
            let protocolIndex = protocols.map(x => x.id).indexOf(Number(action.id))
            if (action.auth.role <= 2 && action.protocol.user_id !== action.auth.id) {
                action.protocol && (protocols = protocols.filter(current => current.id !== action.protocol.id))
            } else {
                action.protocol && ((protocolIndex === -1 && action.status === EnumStatus.VIDEO_VOICE_QUEUE && action.support === EnumSupport.HUMAN) ? protocols.push(action.protocol) : (protocols = (action.status !== EnumStatus.VIDEO_VOICE_QUEUE || action.support !== EnumSupport.HUMAN) ? protocols.filter(current => current.id !== action.protocol.id) : (protocols.map(current => current.id === action.protocol.id ? {...current, ...action.protocol} : current))))
            }
            return {
                ...currentState,
                type: action.type,
                protocols
            }
        case PROTOCOL_VIDEOVOICEQUEUE:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    protocols: [...currentState.protocols, ...action.protocols]
                }
        default:
            return currentState
    }
}

export function protocolVideoVoiceRun(currentState = {}, action) {
    switch (action.type) {
        case PROTOCOL_VIDEOVOICERUNRESET:
            return {}
        case PROTOCOL_VIDEOVOICERUNUPDATE:
            let protocols = currentState && Array.isArray(currentState.protocols) ? [...currentState.protocols] : []
            let protocolIndex = protocols.map(x => x.id).indexOf(Number(action.id))
            if (action.auth.role <= 2 && action.protocol.user_id !== action.auth.id) {
                action.protocol && (protocols = protocols.filter(current => current.id !== action.protocol.id))
            } else {
                action.protocol && ((protocolIndex === -1 && action.status === EnumStatus.VIDEO_VOICE_RUN && action.support === EnumSupport.HUMAN) ? protocols.push(action.protocol) : (protocols = (action.status !== EnumStatus.VIDEO_VOICE_RUN || action.support !== EnumSupport.HUMAN) ? protocols.filter(current => current.id !== action.protocol.id) : (protocols.map(current => current.id === action.protocol.id ? {...current, ...action.protocol} : current))))
            }
            return {
                ...currentState,
                type: action.type,
                protocols
            }
        case PROTOCOL_VIDEOVOICERUN:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    protocols: [...currentState.protocols, ...action.protocols]
                }
        default:
            return currentState
    }
}

export function protocolOpenWaitingClient(currentState = {}, action) {
    switch (action.type) {
        case PROTOCOL_OPENWAITINGCLIENTRESET:
            return {}
        case PROTOCOL_OPENWAITINGCLIENTUPDATE:
            let protocols = currentState && Array.isArray(currentState.protocols) ? [...currentState.protocols] : []
            const protocolIndex = protocols.map(x => x.id).indexOf(Number(action.id))
            if (action.auth.role <= 2 && action.protocol.user_id !== action.auth.id) {
                action.protocol && (protocols = protocols.filter(current => current.id !== action.protocol.id))
            } else {
                action.protocol && ((protocolIndex === -1 && action.status === EnumStatus.AWAITING_CUSTOMER && action.support === EnumSupport.HUMAN) ? protocols.push(action.protocol) : (protocols = (action.status !== EnumStatus.AWAITING_CUSTOMER || action.support !== EnumSupport.HUMAN) ? protocols.filter(current => current.id !== action.protocol.id) : (protocols.map(current => current.id === action.protocol.id ? {...current, ...action.protocol} : current))))
            }
            return {
                ...currentState,
                type: action.type,
                protocols
            }
        case PROTOCOL_OPENWAITINGCLIENT:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    protocols: [...currentState.protocols, ...action.protocols]
                }
        default:
            return currentState
    }
}

export function protocolLiveStream(currentState = {}, action) {
    switch (action.type) {
        case PROTOCOL_LIVESTREAMRESET:
            return {}
        case PROTOCOL_LIVESTREAMUPDATE:
            let protocols = currentState && Array.isArray(currentState.protocols) ? [...currentState.protocols] : []
            const protocolIndex = protocols.map(x => x.id).indexOf(Number(action.id))
            if (action.auth.role <= 2 && action.protocol.user_id !== action.auth.id) {
                action.protocol && (protocols = protocols.filter(current => current.id !== action.protocol.id))
            } else {
                action.protocol && ((protocolIndex === -1 && action.status === EnumStatus.LIVE_STREAM && action.support === EnumSupport.HUMAN) ? protocols.push(action.protocol) : (protocols = (action.status !== EnumStatus.LIVE_STREAM || action.support !== EnumSupport.HUMAN) ? protocols.filter(current => current.id !== action.protocol.id) : (protocols.map(current => current.id === action.protocol.id ? {...current, ...action.protocol} : current))))
            }
            return {
                ...currentState,
                type: action.type,
                protocols
            }
        case PROTOCOL_LIVESTREAM:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    protocols: [...currentState.protocols, ...action.protocols]
                }
        default:
            return currentState
    }
}

export function protocolOpenWaitingOperator(currentState = {}, action) {
    switch (action.type) {
        case PROTOCOL_OPENWAITINGOPERATORRESET:
            return {}
        case PROTOCOL_OPENWAITINGOPERATORUPDATE:
            let protocols = currentState && Array.isArray(currentState.protocols) ? [...currentState.protocols] : []
            let protocolIndex = protocols.map(x => x.id).indexOf(Number(action.id))
            if (action.auth.role <= 2 && action.protocol.user_id !== action.auth.id) {
                action.protocol && (protocols = protocols.filter(current => current.id !== action.protocol.id))
            } else {
                action.protocol && ((protocolIndex === -1 && (action.status === EnumStatus.AWAITING_OPERATOR && action.support === EnumSupport.HUMAN)) ? protocols.push(action.protocol) : (protocols = (action.status !== EnumStatus.AWAITING_OPERATOR || action.support !== EnumSupport.HUMAN) ? protocols.filter(current => current.id !== action.protocol.id) : (protocols.map(current => current.id === action.protocol.id ? {...current, ...action.protocol} : current))))
            }
            return {
                ...currentState,
                type: action.type,
                protocols
            }
        case PROTOCOL_OPENWAITINGOPERATOR:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    protocols: [...currentState.protocols, ...action.protocols]
                }
        default:
            return currentState
    }
}

export function protocolClosed(currentState = {}, action) {
    switch (action.type) {
        case PROTOCOL_CLOSEDRESET:
            return {}
        case PROTOCOL_CLOSEDUPDATE:
            let protocols = currentState && Array.isArray(currentState.protocols) ? [...currentState.protocols] : []
            let protocolIndex = protocols.map(x => x.id).indexOf(Number(action.id))
            if (action.auth.role <= 2 && action.protocol.user_id !== action.auth.id) {
                action.protocol && (protocols = protocols.filter(current => current.id !== action.protocol.id))
            } else {
                action.protocol && ((protocolIndex === -1 && action.status === EnumStatus.CLOSED) ? protocols.push(action.protocol) : (protocols = (action.status !== EnumStatus.CLOSED) ? protocols.filter(current => current.id !== action.protocol.id) : (protocols.map(current => current.id === action.protocol.id ? {...current, ...action.protocol} : current))))
            }
            return {
                ...currentState,
                type: action.type,
                protocols
            }
        case PROTOCOL_CLOSED:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    protocols: [...currentState.protocols, ...action.protocols]
                }
        default:
            return currentState
    }
}

export function protocolClosedBot(currentState = {}, action) {
    switch (action.type) {
        case PROTOCOL_CLOSEDBOTRESET:
            return {}
        case PROTOCOL_CLOSEDBOTUPDATE:
            let protocols = currentState && Array.isArray(currentState.protocols) ? [...currentState.protocols] : []
            let protocolIndex = protocols.map(x => x.id).indexOf(Number(action.id))
            if (action.auth.role <= 2 && action.protocol.user_id !== action.auth.id) {
                action.protocol && (protocols = protocols.filter(current => current.id !== action.protocol.id))
            } else {
                action.protocol && ((protocolIndex === -1 && (action.status === EnumStatus.CLOSED && action.support === EnumSupport.BOT)) ? protocols.push(action.protocol) : (protocols = (action.status === EnumStatus.CLOSED || action.support === EnumSupport.BOT) ? protocols.filter(current => current.id !== action.protocol.id) : (protocols.map(current => current.id === action.protocol.id ? {...current, ...action.protocol} : current))))
            }
            return {
                ...currentState,
                type: action.type,
                protocols
            }
        case PROTOCOL_CLOSEDBOT:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    protocols: [...currentState.protocols, ...action.protocols]
                }
        default:
            return currentState
    }
}

export function protocolClosedHuman(currentState = {}, action) {
    switch (action.type) {
        case PROTOCOL_CLOSEDHUMANRESET:
            return {}
        case PROTOCOL_CLOSEDHUMANUPDATE:
            let protocols = currentState && Array.isArray(currentState.protocols) ? [...currentState.protocols] : []
            let protocolIndex = protocols.map(x => x.id).indexOf(Number(action.id))
            if (action.auth.role <= 2 && action.protocol.user_id !== action.auth.id) {
                action.protocol && (protocols = protocols.filter(current => current.id !== action.protocol.id))
            } else {
                action.protocol && ((protocolIndex === -1 && (action.status === EnumStatus.CLOSED && action.support === EnumSupport.HUMAN)) ? protocols.push(action.protocol) : (protocols = (action.status !== EnumStatus.CLOSED || action.support !== EnumSupport.HUMAN) ? protocols.filter(current => current.id !== action.protocol.id) : (protocols.map(current => current.id === action.protocol.id ? {...current, ...action.protocol} : current))))
            }
            return {
                ...currentState,
                type: action.type,
                protocols
            }
        case PROTOCOL_CLOSEDHUMAN:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    protocols: [...currentState.protocols, ...action.protocols]
                }
        default:
            return currentState
    }
}

export function protocolCount(currentState = [], action) {
    switch (action.type) {
        case PROTOCOL_COUNT:
            return action.data
        default:
            return currentState
    }
}

export function protocolDepartment(currentState = [], action) {
    switch (action.type) {
        case PROTOCOL_COUNTDEPARTMENT:
            return action.data
        default:
            return currentState
    }
}

export function protocolCountType(currentState = [], action) {
    switch (action.type) {
        case PROTOCOL_COUNTTYPE:
            return action
        default:
            return currentState
    }
}