import {getTab, postTab, putTab} from '../../api/tab'
import {history} from '../'
export const TAB_CREATE = 'TAB_CREATE',
    TAB_UPDATE = 'TAB_UPDATE',
    TAB_LIST = 'TAB_LIST'

export function tabList(department) {
    return dispatch => {
        getTab(department).then(response => {
            dispatch({
                type: TAB_LIST,
                list: response.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401){
                history.push('/login')
            }
        })
    }
}

export function tabCreate(description, status, department, activated, watson, cpc, xThis) {
    return dispatch => {
        postTab(description, status, department, activated, watson, cpc).then(response => {
            dispatch({
                type: TAB_CREATE,
                list: response.data
            })
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(t.notifyCreate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error.response)
        })
    }
}


export function tabUpdate(id, description, status, department, activated, watson, cpc, xThis) {
    return dispatch => {
        putTab(id, description, status, department, activated, watson, cpc).then(response => {
            dispatch({
                type: TAB_UPDATE,
                list: response.data
            })
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(t.notifyUpdate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error)
        })
    }
}