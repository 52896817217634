import {
	getCRMBusiness,
	getCRMTemplate,
	postCRMTemplate,
	postBusinessFile,
	putCRMBusiness,
	putCRMTemplate, putCRMTemplateActive,
} from '../../api/crm'
import {history} from '../'
export const CRM_CREATE = 'CRM_CREATE',
	CRM_UPDATE = 'CRM_UPDATE',
	CRMBUSINESS_LIST = 'CRMBUSINESS_LIST',
	CRMBUSINESS_UPDATE = 'CRMBUSINESS_UPDATE',
	CRMTEMPLATE_UPDATE = 'CRMTEMPLATE_UPDATE',
	CRMBUSINESS_CREATE = 'CRMBUSINESS_CREATE',
	CRMBUSINESS_CREATEERROR = 'CRMBUSINESS_CREATEERROR',
	CRMTEMPLATE_LIST = 'CRMTEMPLATE_LIST'

export function crmBusinessList(department) {
	return dispatch => {
		getCRMBusiness(department).then(response => {
			dispatch({
				type: CRMBUSINESS_LIST,
				list: response.data,
			})
		}).catch(error => {
			const status = error.response.status
			if (status === 401) {
				history.push('/login')
			}
		})
	}
}

export function crmBusinessCreateFile(formData, xThis) {
	return dispatch => {
		postBusinessFile(formData).then(response => {
			/*dispatch({
				type: CRMBUSINESS_CREATE,
				list: {
					_id: response.data.businessId,
					active: true,
					name: formData.get('name'),
					templateId: formData.get('templateId'),
				},
				err: null
			})*/
			xThis.setState(prevState => ({
				loading: false, listMailing: [{
					_id: response.data.businessId,
					active: true,
					name: formData.get('name'),
					templateId: formData.get('templateId'),
				}, ...prevState.listMailing],
			}))
			const { enqueueSnackbar } = xThis.props
			enqueueSnackbar('CRM importado com sucesso!', { variant: 'success' })
			xThis._dialogCreate.handleClickOpen()
		}).catch(err => {
			const status = err.response.status
			if (status === 401) {
				history.push('/login')
			}
			xThis.setState({sendMailingError: err.response.data, loading: false,})
			console.log('err', err)
		})
	}
}

export function crmBusinessUpdate(id, active, active_until, xThis) {
	return dispatch => {
		putCRMBusiness(id, active, active_until).then(response => {
			xThis._dialogUpdate.handleClickOpen()
			xThis.setState(prevState => ({ campaignSelect: false, listMailing: prevState.listMailing.map(x => x._id === id ? ({...x, active}) : x) }), () => {
				xThis.props.enqueueSnackbar('CRM alterado com sucesso!', { variant: 'success' })
			})
		}).catch(error => {
			const status = error.response.status
			if (status === 401) {
				history.push('/login')
			}
		})
	}
}

export function crmTemplateUpdate(data, xThis) {
	return dispatch => {
		putCRMTemplate(data._id, data).then(response => {
			dispatch({
				type: CRMTEMPLATE_UPDATE,
				list: { ...data },
			})

			xThis.setState({ createCRM: false }, () => {
				xThis.props.enqueueSnackbar('Template alterado com sucesso!', { variant: 'success' })
			})
		}).catch(error => {
			const status = error.response.status
			if (status === 401) {
				history.push('/login')
			}
		})
	}
}
export function crmTemplateUpdateActive(data, xThis) {
	return dispatch => {
		putCRMTemplateActive(data._id, data.active).then(response => {
			dispatch({
				type: CRMTEMPLATE_UPDATE,
				list: { ...data },
			})

			xThis.setState({ createCRM: false }, () => {
				xThis.props.enqueueSnackbar('Template alterado com sucesso!', { variant: 'success' })
			})
		}).catch(error => {
			const status = error.response.status
			if (status === 401) {
				history.push('/login')
			}
		})
	}
}

export function crmTemplateList(after) {
	return dispatch => {
		getCRMTemplate().then(response => {
			dispatch({
				type: CRMTEMPLATE_LIST,
				list: response.data,
			})
			if (after){
				after()
			}
		}).catch(error => {
			console.log(error)
			const status = error?.response?.status
			if (status === 401) {
				history.push('/login')
			}
		})
	}
}

export function crmTemplateCreate(data, xThis) {
	return dispatch => {
		postCRMTemplate(data).then(response => {
			dispatch({
				type: CRM_CREATE,
				list: { ...data, ...response.data, active: true },
			})
			const { enqueueSnackbar } = xThis.props
			enqueueSnackbar('CRM cadastrado com sucesso!', { variant: 'success' })
		}).catch(error => {
			console.log('err', error.response)
		})
	}
}


export function crmUpdate(id, name, fields, data, xThis) {
	return dispatch => {
		putCRMTemplate(id, name, fields, data).then(response => {
			dispatch({
				type: CRM_UPDATE,
				list: response.data,
			})
			const { enqueueSnackbar } = xThis.props
			enqueueSnackbar('CRM atualizado com sucesso!', { variant: 'success' })
			xThis._dialog.handleClickOpen()
		}).catch(error => {
			console.log('err', error)
		})
	}
}