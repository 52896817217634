import instance from './instance'

export function getBreak(id = '') {
    return instance.get(`/settings/break/${id !== '' ? `id/${id}` : ''}`)
}

export function postBreak(data) {
    return instance.post(`/settings/break/`, data)
}

export function putBreak(data) {
    return instance.put(`/settings/break/`, data)
}
