import {
    EMAILIMAP_CREATE,
    EMAILIMAP_LIST,
    EMAILIMAP_UPDATE
} from "../actions";

export function emailIMap(currentState = {}, action) {
    switch (action.type) {
        case EMAILIMAP_CREATE:
            return {
                type: action.type,
                list: { ...currentState.list, ...action.list, config: [...currentState.list.config, ...action.list.config] }
            }
        case EMAILIMAP_LIST:
            return currentState = action
        case EMAILIMAP_UPDATE:
            return {
                type: action.type,
                list: { ...currentState.list, ...action.list, config: currentState.list.config.map(config => config.id === action.list.config.id ? action.list.config : config)}
            }
        default:
            return currentState
    }
}