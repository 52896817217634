import instance from './instance';

export function getEmailMarketing(id= null) {
    return instance.get(`/emailmarketing/code${id ? `/${id}` : ''}`)
}

export function postEmailMarketing(data) {
    return instance.post(`/emailmarketing/code`, data)
}

export function putEmailMarketing(id, data) {
    return instance.put(`/emailmarketing/code/${id}`, data)
}


export function getEmailMarketingImage(id = null) {
    return instance.get(`/emailmarketing/image${id ? `/${id}` : ''}`)
}


export function putEmailMarketingImage(id, data) {
    return instance.put(`/emailmarketing/image/${id}`, {data})
}

export function postEmailMarketingImage(data) {
    return instance.post(`/emailmarketing/image`, data)
}