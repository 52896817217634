import instance from './instance'
import instancev2 from './instancev2'

export function getProtocolCRM(department, channel, page,limit, search) {
    return instance.get(`/protocol/search_crm?department=${department}&channel=${channel}&page=${page}&limit=${limit}${search ? `&search=${search}` : ''}`)
}

export function getProtocol(department, user, date,channel, closed, support,status, page, orderBy, search) {
    return instance.get(`protocol?department=${department}${orderBy ? `&order=${orderBy}`: ''}${user ? `&users=${user}`: ''}${date ? `&date=${date}`: ''}&channel=${channel}&closed=${closed}&support=${support}&status=${status}&page=${page}&limit=200${search ? `&search=${search}` : ''}`)
}

export function postCreateSession(protocol_id) {
    return instance.post(`video/create_session`, {protocol_id})
}

export function postFeeling(data) {
    return instancev2.post(`feelings`, data)
}
export function postProtocolWhatsapp(formData) {
    //return instance.post(``,{department, email, subject, cc, bcc, name: subject, text, channel})
    return instance.post(`/protocol`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export function getProtocolPDF(id) {
    return instancev2.get(`/protocol/export?protocol=${id}`)
}

export function postProtocolEmail(formData) {
    //return instance.post(``,{department, email, subject, cc, bcc, name: subject, text, channel})
    return instance.post(`/protocol`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export function postProtocolPhone(cpf,department, tab, note) {
    return instance.post(`protocol/phone`,{cpf,department, tab, note})
}

export function postTranscription(data) {
    return instancev2.post(`transcription`, data)

}

export function putProtocolFixed(data) {
    return instancev2.put(`protocol/fix_protocol`,data)
}

export function postNote(data) {
    return instance.post(`note`,data)
}

export function getNote(id) {
    return instance.get(`note/${id}`)
}

export function getProtocolCount() {
    return instancev2.get(`protocol/count`)
}

export function getProtocolById(id) {
    return instance.get(`protocol/${id}`)
}

export function getProtocolHistory(contact_id, phone, search) {
    return instance.get(`protocol/history${search ? `/search` : ''}?${contact_id ? `contact_id=${contact_id}`: ''}${contact_id && phone ? `&` : ''}${phone ? `phone=${phone}` : ''}${search ? `&search=${search}` : ''}`)
}

export function getProtocolDeparment() {
    return instance.get(`/protocol/count/department`)
}

export function putProtocol(id, tab) {
    return instance.put(`/protocol/closed`, {id, tab})
}
export function putTabProtocol({protocolo, matricula, grupo, subgrupo, tab, ocorrencia}) {
    return instance.put(`/protocol/tab`, {protocolo, matricula ,grupo,subgrupo,tab,ocorrencia})
}
export function categoryProtocol(id, tab) {
    return instance.put(`/protocol/categorize`, {id, tab})
}

export function statusProtocol(id, workflow_status) {
    return instance.put(`/protocol/workflow_status`, {id, workflow_status})
}
export function putPauseProtocol(id, data) {
    return instancev2.put(`/protocol/pause/${id}`, data)
}

export function putProtocolTransfer(protocol_id,note,new_department_id,new_user_id) {
    return instancev2.put(`/protocol/transfer`, {protocol_id,note,new_department_id,new_user_id})
}

export function getSearch(search, page = 1) {
    return instance.get(`/protocol?search=${search}&page=${page}`)
}

export function putAssign(protocol_id, id) {
    return instance.put(`/protocol/assign`, {protocol_id, id})
}

export function putBank(protocol_id, data) {
    return instance.put(`protocol/update/business`, {protocol_id, data})
}

export function putDisable(id) {
    return instance.put(`/protocol/disableia`, {id})
}


export function getMessage(protocol = '', page = 1,limit = 500) {
    return instance.get(`/message/${protocol}?page=${page}${limit && `&limit=${limit}`}`)
}

export function postMessage(formData) {
    return instancev2.post(`/message`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export function putForwards(protocol,forward,forward_messages) {
    return instance.post('/message/forward', {protocol,forward,forward_messages})
}

export function postEmailForwards(data) {
    return instancev2.post('/protocol/forward', data)
}

export function postScheduling(protocol_id,text,date,file) {
    return instance.post(`scheduling`,{protocol_id,text,date,file})
}

export function putCpc(item_id, lote_id, templateId, data) {
    return instance.put(`/crm/business/${lote_id}/data/${item_id}/cpc`, data, {
        headers: {
            templateId
        }
    })
}
