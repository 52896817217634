import {
    CHANNEL_LIST,
} from "../actions";

export function channel(currentState = {}, action) {
    switch (action.type) {
        case CHANNEL_LIST:
            return currentState = action
        default:
            return currentState
    }
}