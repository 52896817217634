import instance from './instance'

export function getRoleFunction(id) {
    return instance.get(`/settings/role_function${id ? `/${id}` : ''}`)
}

export function postRoleFunction(data) {
    return instance.post(`/settings/role_function/`, data)
}

export function putRoleFunction(data) {
    return instance.put(`/settings/role_function/`, data)
}
