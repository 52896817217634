import instance from "./instancev2";
import instancev2 from "./instancev2";

export function getGroup(id = '') {
    return instance.get(`/group/${id}`)
}

export function postGroup(data) {
    return instance.post(`/group`, {...data})
}

export function putGroup(data) {
    return instance.put(`/group`, {...data})
}


export function getMember(group = '', member= '') {
    return instance.get(`/group/${group}/member/${member}`)
}

export function postRemoveMember(data) {
    return instance.post(`/group/remove`, {...data})
}
export function postRemoveAdminMember(data) {
    return instance.post(`/group/removeadmin`, {...data})
}

export function postAddAdminMember(data) {
    return instance.post(`/group/admin`, {...data})
}


export function postAddMember(data) {
    return instance.post(`/group/addmember`, {...data})
}


export function postGroupOccorrence(data) {
    return instance.post(`/group/occorrence`, {...data})
}

export function postGroupMessage(formData) {
    return instancev2.post(`/group/message`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}


export function postNoteGroup(data) {
    return instance.post(`note_group`,data)
}

export function putGroupUpdateCustomer(protocol, customer, data) {
    return instance.put(`/group/${protocol}/member/${customer}`, data)
}


export function putGroupMemberUpdate(data) {
    return instance.put(`/group/member`, data)
}

export function postGroupTab(data) {
    return instance.post(`/group/tab`, data)
}

export function postGroupTicket(data) {
    return instance.post(`/group/ticket`, data)
}
