import React from 'react'

import {Container, Spinner} from './styled'

const Loader = () => (
    <Container className='loader'>
        <Spinner>
            <div className="dot1"/>
            <div className="dot2"/>
        </Spinner>
    </Container>
);

export default Loader