import {
    FLOW_CREATE,
    FLOW_LIST,
    TEMPLATE_CREATE,
    TEMPLATE_LIST
} from "../actions";

export function flow(currentState = {}, action) {
    switch (action.type) {
        case FLOW_CREATE:
            return {
                type: action.type,
                flow: currentState.flow ? currentState.flow.concat(action.flow) : [action.flow]
            }
        case FLOW_LIST:
            return currentState = action
        default:
            return currentState
    }
}
export function template(currentState = {}, action) {
    switch (action.type) {
        case TEMPLATE_CREATE:
            return {
                type: action.type,
                template: currentState.template ? currentState.template.concat(action.template) : [action.template]
            }
        case TEMPLATE_LIST:
            return currentState = action
        default:
            return currentState
    }
}