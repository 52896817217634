import {getBlockedWord,postBlockedWord,putBlockedWord} from '../../api/blockedWord'
import { history } from '../'
export const BLOCKEDWORD_CREATE = 'BLOCKEDWORD_CREATE',
    BLOCKEDWORD_UPDATE = 'BLOCKEDWORD_UPDATE',
    BLOCKEDWORD_LIST = 'BLOCKEDWORD_LIST'

export function blockedWordList(page) {
    return dispatch => {

        getBlockedWord(page).then(response => {
            dispatch({
                type: BLOCKEDWORD_LIST,
                list: response.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401){
                history.push('/login')
            }
        })
    }
}

export function blockedWordCreate(word,xThis) {
    return dispatch => {
        postBlockedWord(word).then(response => {
            dispatch({
                type: BLOCKEDWORD_CREATE,
                list: response.data
            })
            const {enqueueSnackbar, t} = xThis.props
            enqueueSnackbar(t.notifyCreate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error.response)
        })
    }
}


export function blockedWordUpdate(id, word, xThis) {
    return dispatch => {
        putBlockedWord(id, word).then(response => {
            dispatch({
                type: BLOCKEDWORD_UPDATE,
                list: response.data
            })
            const {enqueueSnackbar, t} = xThis.props
            enqueueSnackbar(t.notifyUpdate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error.response)
        })
    }
}