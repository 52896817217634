import {
    dispatchWorkspace,
    dispatchWorkflow
} from "../actions";

export function workspace(currentState = false, action) {
    const {type, ...data} = action
    const actionAvailable = {
        [dispatchWorkspace.WORKSPACE_CREATE]: () => {
            return {
                ...currentState,
                workspaces: Array.isArray(currentState.workspaces) ? [...currentState.workspaces, data] : [data],
            }
        },
        [dispatchWorkspace.WORKSPACE_LIST]: () => {
            return { ...currentState,...data }
        },
        [dispatchWorkspace.WORKSPACE_UPDATE]: () => {
            return {
                ...currentState,
                workspaces: currentState.workspaces.map(currWorkspace => currWorkspace.id === data.id ? ({...currWorkspace, ...data}) : currWorkspace),
            }
        },
        
        [dispatchWorkspace.WORKSPACE_SELECT]: () => {
            return {
                ...currentState,
                ...data
            }
        },
        [dispatchWorkspace.WORKSPACE_SELECTUPDATE]: () => {
            return {
                ...currentState,
                select: {
                    ...currentState.select,
                    ...data
                }
            }
        },
        [dispatchWorkspace.WORKSPACE_RESET]: () => {
            delete currentState.select
            return {
                ...currentState,
                ...data
            }
        },
    }

    return actionAvailable[type] ? actionAvailable[type]() : currentState
}

export function workflow(currentState = false, action) {
    const {type, ...data} = action
    const actionAvailable = {
        [dispatchWorkflow.WORKFLOW_CREATE]: () => {
            return {
                type: action.type,
                //list: currentState.list ? currentState.list.concat(action.list) : [action.list]
            }
        },
        [dispatchWorkflow.WORKFLOW_LIST]: () => {
            return { ...currentState,...data }
        },
        [dispatchWorkflow.WORKFLOW_UPDATE]: () => {
            return {
                type: action.type,
                ...currentState,
                ...data
            }
        },
        [dispatchWorkflow.WORKFLOW_SELECT]: () => {
            return {
                ...currentState,
                ...data
            }
        },
        [dispatchWorkflow.WORKFLOW_SELECTUPDATE]: () => {
            return {
                ...currentState,
                select: {
                    ...currentState.select,
                    ...data
                }
            }
        },
        
        [dispatchWorkflow.WORKFLOW_RESET]: () => {
            const { select, ...newState } = currentState; 
            return {
                ...newState,
                ...data
            }
        },
    }

    return actionAvailable[action.type] ? actionAvailable[action.type]() : currentState
}