import { getSiebelHistoric } from '../../api/siebel'
import {history} from '../'
export const SIEBEL_HISTORIC_LIST = 'SIEBEL_HISTORIC_LIST'

export function SiebelHistoricList(data) {
    return dispatch => {
        getSiebelHistoric(data).then(response => {
            dispatch({
                type: SIEBEL_HISTORIC_LIST,
                list: response.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 400) {
                console.error('siebel.js: ', error.response.data.error)
                dispatch({
                    type: SIEBEL_HISTORIC_LIST,
                    error: error.response.data.error
                })
            }
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}