import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
    Button,
    ButtonContainer,
    SelectMultiple,
    InputContainer,
    FormHelperText,
} from '../../styled'
import classNames from 'classnames'
import {ReactComponent as Widget} from 'images/icons/widget.svg'
import {ReactComponent as Whatsapp} from 'images/icons/whatsapp.svg'
import {ReactComponent as Sms} from 'images/icons/sms.svg'
import {ReactComponent as Email} from 'images/icons/email.svg'
import {ReactComponent as Messenger} from 'images/icons/messenger.svg'
import {ReactComponent as Phone} from 'images/icons/phone.svg'
import {ReactComponent as Video} from 'images/icons/video.svg'
import {ReactComponent as Instagram} from 'images/icons/insta.svg'
import {ReactComponent as Telegram} from 'images/icons/telegram.svg'
import {ReactComponent as ReclameAqui} from 'images/icons/reclameAqui.svg'
import {ReactComponent as LandingPage} from 'images/icons/landingPage.svg'
import {ReactComponent as ConsumidorGov} from 'images/icons/consumidorgov.svg'
import {ReactComponent as GoogleChat} from 'images/icons/googleChat.svg'
import {ReactComponent as Youtube} from 'images/icons/youtube.svg'
import {ReactComponent as Slack} from 'images/icons/slack.svg'

const FormInitState = {
    id_department: {value: null, error: ''},
    id_channel: {value: null, error: ''},
    offline: {value: null, error: ''},
    crm: {value: null, error: ''},
}

export const channelsData = [
    {id: 1, name: 'chat', icon: <Widget/>, label: 'Chat'},
    {id: 2, name: 'whatsapp', icon: <Whatsapp/>, label: 'WhatsApp', key: 'customer_phome', type: 'phone'},
    {id: 3, name: 'sms', icon: <Sms/>, label: 'SMS', key: 'customer_phome', type: 'phone'},
    {id: 4, name: 'email', icon: <Email/>, label: 'E-mail', key: 'customer_email', type: 'email'},
    {id: 5, name: 'messenger', icon: <Messenger/>, label: 'Messenger', key: 'customer_email', type: 'email'},
    {id: 6, name: 'phone', icon: <Phone/>, label: 'Ligação', key: 'customer_phome', type: 'phone'},
    {id: 7, name: 'video', icon: <Video/>, label: 'Video'},
    {id: 8, name: 'instagram_chat', icon: <Instagram/>, label: 'Instagram', key: 'customer_email', type: 'email'},
    {id: 9, name: 'telegram', icon: <Telegram/>, label: 'Telegram', key: 'customer_phome', type: 'phone'},
    {id: 10, name: 'reclame_aqui', icon: <ReclameAqui/>, label: 'Reclame AQUI'},
    {id: 11, name: 'landing_page', icon: <LandingPage/>, label: 'Landing Page'},
    {id: 12, name: 'rcs', icon: <Widget/>, label: 'RCS'},
    {id: 13, name: 'consumidor', icon: <ConsumidorGov/>, label: 'Consumidor.gov'},
    {id: 14, name: 'google_chat', icon: <GoogleChat/>, label: 'Google Chat'},
    {id: 15, name: 'youtube', icon: <Youtube/>, label: 'Youtube'},
    {id: 16, name: 'inbot_chat', icon: <Widget/>, label: 'Inbot Chat'},
    {id: 17, name: 'slack', icon: <Slack/>, label: 'Slack'},
]


const Protocol = ({setStep, setData, sendProtocol, department, data, sending, options}) => {
    const { i18nState } = useSelector(state => state)
	const t = i18nState.translations[i18nState.lang]['DialogsModals']
    const [form, setForm] = useState({...FormInitState})

    const hasChannel = (channels) => {
        return Object.keys(channels).filter(x => x !== 'watson' && channels[x].length > 0).length > 0
    }

    useEffect(() => {
        if (data) {
            const formKeys = Object.keys(FormInitState)
            const newData = {...data}

            Object.keys(data).map(key => {
                if (!formKeys.includes(key)) {
                    delete newData[key]
                }
            })

            // setData({ ...data,...newData })

            setForm(formKeys.reduce((prev, curr) => {
                prev[curr] = {...FormInitState[curr], value: data[curr]}
                return prev
            }, {}))

            if (data?.protocol) {
                handleForm({target: {name: 'id_department', value: data?.protocol?.department_id}})
                const {businessId, templateId, _id} = data.protocol.data || {}
                //console.log(data.protocol)
                if (businessId && templateId && _id) {
                    handleForm({target: {name: 'crm', value: true}})
                    handleForm({
                        target: {
                            name: 'customer', value: {
                                crm_template_id: templateId,
                                crm_business_id: businessId,
                                crm_register_id: _id
                            }
                        }
                    })
                } else {
                    const crm_id = Array.isArray(data.protocol.customers) && data.protocol.customers.length > 0 && data.protocol.customers[0].crm_contact_id
                    handleForm({target: {name: 'crm', value: !!crm_id}})
                    if (!!crm_id) {
                        handleForm({target: {name: 'crm_id', value: crm_id}})
                    }
                }
            }
        }
    }, [])

    const phase = []
    if (data && data.phase) {
        department.filter(x => x.phase.length > 0 && x.phase.filter(y => y.id === data.phase ? phase.push(y) : ''))
    }

    const departmentAvailable = department.filter(x => (
        form.offline.value === true || (
            phase.length > 0 && phase[0].department_can_create_protocol.length > 0 ?
                phase[0].department_can_create_protocol.indexOf(x.id) >= 0 && form.offline.value === false && hasChannel(x.channels) :
                form.offline.value === false && hasChannel(x.channels)
        )
    )).filter(x => (form.crm.value === false || (form.crm.value === true && x.schema))).map(dept => ({
        ...dept,
        value: dept.id,
        label: dept.name,
    }))

    const departmentSelected = form.id_department?.value && departmentAvailable.find(dept => dept.value === form.id_department.value)

    const handleForm = (e) => {
        const {name, type} = e.target
        const value = type === 'checkbox' ? e.target.checked : e.target.value
        if ((name === 'crm' || name === 'offline') && (!data || !data.protocol)) {
            setForm(prevState => ({
                ...prevState,
                id_department: {...prevState['id_department'], value: null},
                id_channel: {...prevState['id_channel'], value: null},
            }))
        }
        setForm(prevState => ({
            ...prevState,
            [name]: {...prevState[name], value},
        }))
    }

    const handleSubmit = async () => {
        let error = false
        let newForm = {}
        const value = Object.keys(form).reduce((prev, curr) => {
            newForm[curr] = {...form[curr], error: ''}
            switch (curr) {
                case 'offline':
                    if (typeof form[curr].value !== 'boolean') {
                        newForm[curr] = {
                            ...form[curr],
                            error: t?.newProtocol?.fields?.error?.erroProtocol,
                        }
                        error = true
                    }
                    break
                case 'crm':
                    if (typeof form.offline.value === 'boolean' && typeof form[curr].value !== 'boolean') {
                        newForm[curr] = {
                            ...form[curr],
                            error: t?.newProtocol?.fields?.error?.erroCrmOrDetached,
                        }
                        error = true
                    }
                    break
                case 'id_department':
                    if (typeof form.offline.value === 'boolean' && typeof form.crm.value === 'boolean' && !form[curr].value) {
                        newForm[curr] = {...form[curr], error: t?.newProtocol?.fields?.error?.erroDepartment,}
                        error = true
                    }
                    break
                case 'id_channel':
                    if (typeof form.offline.value === 'boolean' && typeof form.crm.value === 'boolean' && (!form.crm.value || form.offline.value) && !form[curr].value) {
                        newForm[curr] = {...form[curr], error: t?.newProtocol?.fields?.error?.erroChannel,}
                        error = true
                    }
                    break
                default:
                    break
            }
            if (form[curr].value !== null) {
                prev[curr] = form[curr].value
            }
            return prev
        }, {})
        setForm(newForm)
        if (!error) {
            setData({...data, ...value})
            if (value.offline === true && value.crm === false) {
                sendProtocol({...data, ...value})
            } else if (value.crm === true) {
                if (value.offline === true && data && data.protocol) {
                    sendProtocol({...data, ...value})
                } else {
                    setStep('searchCustomer')
                }
            } else {
                if (channelsData.find(x => x.id === value.id_channel).name === 'video') {
                    sendProtocol({...data, ...value})
                } else {
                    setStep(`channel_${channelsData.find(x => x.id === value.id_channel).name}`)
                }
            }
        }
    }

    const handleValidChannel = (channel) => {
        if (form.offline.value === true) {
            return true
        }
        if (departmentSelected && departmentSelected.channels) {
            switch (channel.id) {
                case 2:
                    return Array.isArray(departmentSelected.channels.whatsapp) && departmentSelected.channels.whatsapp.length > 0
                case 3:
                    return (Array.isArray(departmentSelected.channels.sms_zenvia) && departmentSelected.channels.sms_zenvia.length > 0) || (Array.isArray(departmentSelected.channels.sms_pointer) && departmentSelected.channels.sms_pointer.length > 0) || (Array.isArray(departmentSelected.channels.sms_nexmo) && departmentSelected.channels.sms_nexmo.length > 0) || (Array.isArray(departmentSelected.channels.sms_m2c) && departmentSelected.channels.sms_m2c.length > 0)
                case 4:
                    return Array.isArray(departmentSelected.channels.email_imap) && departmentSelected.channels.email_imap.length > 0
                case 6:
                    return Array.isArray(departmentSelected.channels.phone) && departmentSelected.channels.phone.length > 0
                case 7:
                    return Array.isArray(departmentSelected.channels.video_nexmo) && departmentSelected.channels.video_nexmo.length > 0
                case 14:
                    return Array.isArray(departmentSelected.channels.googlechat) && departmentSelected.channels.googlechat.length > 0
                default:
                    return false
            }
        } else {
            return false
        }
    }

    return (
        <>
            <InputContainer className={classNames({error: form.offline.error.length > 0})}>
                <p dangerouslySetInnerHTML={{__html: t?.newProtocol?.fields?.paragraphProtocol}} />
                <div>
                    <Button
                        disabled={data?.protocol?.department_id && !hasChannel(department.find(dept => dept.id === data?.protocol?.department_id).channels)}
                        className={classNames({active: form.offline.value === false}, 'online')}
                        onClick={() => handleForm({target: {name: 'offline', value: false}})}>{t?.newProtocol?.fields?.connectOnline}</Button>
                    <span>ou</span>
                    <Button className={classNames({active: form.offline.value === true}, 'offline')}
                            onClick={() => handleForm({target: {name: 'offline', value: true}})}>{t?.newProtocol?.fields?.connectOffline}</Button>
                </div>
                <FormHelperText>{form.offline.error}</FormHelperText>
            </InputContainer>
            {typeof form.offline.value === 'boolean' && (!data || !data.protocol) &&
                <InputContainer className={classNames({error: form.crm.error.length > 0})}>
                    <p dangerouslySetInnerHTML={{__html: t?.newProtocol?.fields?.contactCrmOrdetached}} />
                    <div>
                        <Button className={classNames({active: form.crm.value === true}, 'crm')}
                                onClick={() => handleForm({target: {name: 'crm', value: true}})}>{t?.newProtocol?.options?.crm}</Button>
                        <span>ou</span>
                        <Button className={classNames({active: form.crm.value === false}, 'separate')}
                                onClick={() => handleForm({target: {name: 'crm', value: false}})}>{t?.newProtocol?.options?.detached}</Button>
                    </div>
                    <FormHelperText>{form.crm.error}</FormHelperText>
                </InputContainer>}
            {typeof form.offline.value === 'boolean' && typeof form.crm.value === 'boolean' && (!data || !data.protocol?.department_id) &&
                <InputContainer className={classNames({error: form.id_department?.error?.length > 0})}>
                    <label dangerouslySetInnerHTML={{__html: t?.newProtocol?.fields?.department}} />
                    <SelectMultiple inputId='department' name='department' options={departmentAvailable}
                                    placeholder={t?.newProtocol?.fields?.channels?.whatsapp?.placeholder?.number}
                                    value={departmentSelected}
                                    error={form.id_departmen?.error}
                                    onChange={e => handleForm({
                                        target: {name: 'id_department', value: e.value},
                                    })}/>
                    <FormHelperText>{form.id_department?.error}</FormHelperText>
                </InputContainer>}
            {form && form.id_department.value && form.id_department.value !== '' && <>
                {form && form.offline && typeof form.offline.value === 'boolean' && typeof form.crm.value === 'boolean' && (!form.crm.value || form.offline.value) &&
                    <InputContainer className={classNames({error: form.id_channel?.error?.length > 0})}>
                        <p dangerouslySetInnerHTML={{__html: t?.newProtocol?.fields?.channel}} />
                        <div className='channelList'>
                            {channelsData.filter(x => handleValidChannel(x)).map(x => <Button
                                onClick={() => handleForm({target: {name: 'id_channel', value: x.id}})}
                                className={classNames({active: form.id_channel.value === x.id}, x.name)}>
                                {x.icon}
                                <span>{x.label}</span></Button>)}
                        </div>
                        <FormHelperText>{form.id_channel.error}</FormHelperText>
                    </InputContainer>}
            </>}
            <ButtonContainer className={classNames({
                end: !(options.length > 1),
            })}>
                {options.length > 1 &&
                    <Button classes={{root: 'back'}} onClick={() => setStep('action')}>{t?.newProtocol?.options?.back}</Button>}
                <Button classes={{root: 'next'}} disabled={sending}
                        onClick={() => !sending && handleSubmit()}>{sending ? t?.newProtocol?.options?.sending : t?.newProtocol?.options?.next}</Button>
            </ButtonContainer>
        </>
    )
}

Protocol.propTypes = {
    setStep: PropTypes.func,
    sending: PropTypes.bool,
    sendProtocol: PropTypes.func,
    setData: PropTypes.func,
    department: PropTypes.arrayOf(PropTypes.object),
    options: PropTypes.arrayOf(PropTypes.string),
}

Protocol.defaultProps = {
    setStep: () => {
    },
    sending: false,
    sendProtocol: () => {
    },
    setData: () => {
    },
    department: [],
    options: [],
}

export default Protocol
