import instance from './instancev2'

export function getWorkspace(id = '') {
	return instance.get(`/workspace/${id}`)
}
export function postWorkspace(data) {
	return instance.post(`/workspace/`, data)
}
export function putWorkspace(data) {
	return instance.put(`/workspace/`, data)
}

export function postWorkspaceLink(data) {
	return instance.post(`/workspace/link`, data)
}

export function postWorkflow(data) {
	return instance.post(`/workflow/`, data)
}
export function putWorkflow(data) {
	return instance.put(`/workflow/`, data)
}
export function postWorkflowShare(data) {
	return instance.post(`/workflow/link`, data)
}
export function getWorkflow(id = '', search) {
	return instance.get(`/workflow/${id}?short=true${search ? `&search=${search}` : ''}`)
}
export function putWorkflowFavorite(data) {
	return instance.put(`/workflow/favorite`, data)
}
export function putWorkflowOrder(data) {
	return instance.put(`/workflow/order`, data)
}
export function putWorkspaceOrder(data) {
	return instance.put(`/workspace/order`, data)
}
export function getTicket(id = '', from = '') {
	return instance.get(`/ticket/${id}`, { params: { from } })
}
export function putTicketReply(id = '', data) {
	return instance.put(`/ticket/reply/${id}`, data)
}
export function getTicketList(id = '', data) {
	return instance.post(`/workflow/${id}/list`, data)
}

export function getTicketListV2(id = '', data) {
	return instance.post(`/workflow/${id}/listv2`, data)
}

export function putTicket(data) {
	return instance.put(`/ticket`, data)
}
export function putSlaTicket(data) {
	return instance.put(`/ticket/sla`, data)
}

export function putTicketLinkResponsible(data) {
	return instance.put(`/ticket/link_responsibles?id_ticket=${data.id_ticket}&id_user=${JSON.stringify(data.id_user)}`, data)
}

export const getChannelsByTicket = ({ idWorkflow, idTicket, contacts }) => {
	return instance.get(`/workflow/channels?workflow_id=${idWorkflow}&ticket_id=${idTicket}&contacts=${contacts || []}`)
}

export const sendMessageV2 = (data) => {
	return instance.post(`/ticket/message`, data)
}

export function sendMessage(data) {
	return instance.post(`/ticket/send`, data)
}
export function postTicketMove(data) {
	return instance.post(`/ticket/finish`, data)
}

export function postLinkCustomerTicket(data) {
	return instance.put(`/ticket/link_customer`, data)
}

export function createTicket(data) {
	return instance.post(`/ticket`, data)
}

export function getWorkflowUsers(workspaceId) {
	return instance.get(`/workspace/users/${workspaceId}`)
}

export function getHasHsm(ticketId) {
	return instance.get(`ticket/whatsapp/${ticketId}`)
}
export function createProtocolTicket(data) {
	return instance.post(`/protocolticket`, data)
}

export function searchContact(data) {
	return instance.post(`/ticket/search`, data)
}
export function getWfFieldCRM() {
	return instance.get(`/workspace/fields-form`)
}
export function getAllPhases() {
	return instance.get(`/workspace/company/all_phases`)
}
export function getSummableFieldCRM() {
	return instance.get(`/workspace/summable-fields`)
}

export function getDistributionWorkflow(idWorkflow) {
	return instance.get(`/distribution/${idWorkflow}`)
}

// Ticket History
export function getTicketHistoric({ id_crm, table_name, column_name, id_template, page }) {
	return instance.get(`/ticket/historic?id_crm=${id_crm}&table_name=${table_name}&column_name=${column_name}&id_template=${id_template}&offset=${page}&limit=20`)
}

export function getTicketChildren({ id_father, page }) {
	return instance.get(`/ticket/historic/child?id_father=${id_father}&offset=${page}&limit=10`)
}
