import {getSLA, getUnit, postSLA, putSLA} from '../../api/sla'
import {history} from '../'
export const SLA_CREATE = 'SLA_CREATE',
    SLA_UPDATE = 'SLA_UPDATE',
    SLA_LIST = 'SLA_LIST',
    UNIT_LIST = 'UNIT_LIST'

export function slaList() {
   return dispatch => {
         getSLA().then(response => {
            dispatch({
               type: SLA_LIST,
               list: response.data
            })
         }).catch(error => {
            const status = error.response.status
            if (status === 401){
               history.push('/login')
            }
         })
   }
}

export function unitTime() {
   return dispatch => {
       getUnit().then(response => {
           dispatch({
               type: UNIT_LIST,
               list: response.data
           })
       }).catch(error => {
           console.log(error)
           const status = error?.response?.status
           if (status === 401){
               history.push('/login')
           }
       })
   }
}

export function slaCreate(time, departments, channels, unit_of_time, activated, enqueueSnackbar, t, dialog) {
    return dispatch => {
        postSLA(time, departments, channels, unit_of_time, activated).then(response => {
            dispatch({
                type: SLA_CREATE,
                list: {...response.data, time, departments, channels, unit_of_time, activated}
            })
            enqueueSnackbar(t.notifyCreate, {variant: 'success'})
            dialog.current.handleClickOpen()
        }).catch(error => {
           console.log('err', error.response)
           enqueueSnackbar('Já existe sla para esse departamento e canal', {variant: 'error'})
        })
    }
}


export function slaUpdate(id, id_unit_of_time, time, activated, departments, channels, enqueueSnackbar, t, dialog) {
    return dispatch => {
        putSLA(id, id_unit_of_time, time, activated, departments, channels).then(response => {
            dispatch({
                type: SLA_UPDATE,
                list: {id, id_unit_of_time, time, activated, departments, channels}
            })
            enqueueSnackbar(t.notifyUpdate, {variant: 'success'})
            dialog.current.handleClickOpen()
        }).catch(error => {
           console.log('err', error)
           enqueueSnackbar('Já existe sla para esse departamento e canal', {variant: 'error'})
        })
    }
}