import {
    CONTACT_LIST
} from "../actions";

export function contact(currentState = {}, action) {
    switch (action.type) {
        case CONTACT_LIST:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    list: [...currentState.list, ...action.list]
                }
        default:
            return currentState
    }
}