import instance from './instance'
///settings/blockedword?limit=1000&offset=0
export function getBlockedWord(page) {
    return instance.get(`/settings/blockedword${Number.isInteger(page) ? `?limit=50&offset=${page}` : '?limit=1000'}`)
}

export function postBlockedWord(word) {
    return instance.post(`/settings/blockedword/`, {word})
}

export function putBlockedWord(id, word) {
    return instance.put(`/settings/blockedword/`, {id, word})
}
