import instance from './instance'

export function getSLA() {
    return instance.get(`/settings/sla`)
}
export function getUnit() {
    return instance.get(`/settings/unit_of_time`)
}

export function postSLA(time, departments, channels, unit_of_time, activated) {
    return instance.post(`/settings/sla/`, {time, departments, channels, unit_of_time, activated})
}

export function putSLA(id, id_unit_of_time, time, activated, departments, channels) {
    return instance.put(`/settings/sla/`, {id, id_unit_of_time, time, activated, departments, channels})
}
