import instance from './instance'

export function getAllPhaseCustomer(data) {

	return instance.post(`/ticket/wfcustomer/`, data)
}
export function getAllPhase(id) {
	return instance.get(`/ticket/phase/${id ?? ''}`)
}

export function getAllPhaseByDepartmentAndStatus(department, status, search, enable, offset,limit = 15 ) {
	return instance.get(`/ticket/phase?department=${department}&status=${JSON.stringify(status)}${search && search.length > 0 && `&search=${search}`}&enable=${enable}&limit=${limit}&offset=${offset}`)
}

export function getPhasePaged(phase, status, search, enable, offset,limit = 15 ) {
	return instance.get(`/ticket/phase/paged/${phase}?status=${JSON.stringify(status)}${search && search.length > 0 && `&search=${search}`}&enable=${enable}&limit=${limit}&offset=${offset}`)
}

export function getAllPhaseByFilter(filter) {
	return instance.get(`/ticket/filter?department=${filter.department}&type=tickets_${filter.type}${filter.sla && filter.sla.length > 0 ? `&sla=${filter.sla}` : ''}`)
}

export function putCloseTicket(id) {
	return instance.put(`/ticket/close/${id}`)
}
export function getDepartmentTicket(id) {
	return instance.get(`/ticket/department`)
}

export function getDashPhase(id) {
	return instance.get(`/ticket/dash/${id}`)
}

export function putTicket(id, data) {
	return instance.put(`/ticket/${id}`, data)
}

export function postPhase(data) {
	return instance.post(`/ticket/phase/`, data)
}

export function putOrderPhase(department, data) {
	return instance.put(`/ticket/phase/order/${department}`, data)
}

export function putPhase(id, data) {
	return instance.put(`/ticket/phase/${id}`, data)
}

export function updatePhase(id, data) {
	return instance.put(`/ticket/phase/${id}`, data)
}

export function postTicket(data) {
	return instance.post(`/ticket`, data)
}

export function getTicket(id = '') {
	return instance.get(`/ticket/${id}`)
}

export function getTicketHistory(id = '') {
	return instance.get(`/ticket/history/${id}`)
}

export function postTicketStart(data) {
	return instance.post(`/ticket/start_ticket`, data)
}

export function postTabTicket(data) {
	return instance.post(`/ticket/tab`, data)
}


export function postTicketCustomer(data) {
	return instance.post(`/ticket/customer`, data)
}

export function postTicketActivities(formData) {
	return instance.post(`/ticket/activities`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

export function postTicketAttachments(formData) {
	return instance.post(`/ticket/Attachments`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

export function postTicketCallMe(ticket, data) {
	return instance.post(`/ticket/${ticket}/derivate_to_voice`, data)
}

export function updateTicketPhase(idTicket, data) {
	return instance.put(`/ticket/${idTicket}`, data)
}

export function searchUser(user, idDepartment) {
	return instance.get(`/settings/users/search/?search=${user}&department=${idDepartment}`)
}

export function closeAllTickets(id) {
	return instance.put(`/ticket/phase/close_massive/${id}`)
}

export function moveAllTickets(id, data) {
	return instance.put(`/ticket/phase/transfer_massive/${id}`, data)
}

export function disablePhase(id, data) {
	return instance.put(`/ticket/phase/disable/${id}`, data)
}

export function responsible(id,data) {
	return instance.put(`/ticket/responsible/${id}`, data)
}
