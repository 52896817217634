import instance from "./instancev2";

export const getUser = () => instance.get('/answer/users')

export const getBlockedWord = () => instance.get('/answer/blokedword')

export const getFaq = () => instance.get('/answer/faqs')

export const getDepartment = () => instance.get('/answer/departments')

export const getByDepartment = (id) => instance.get(`/answer/department/${id}`)
