import {getCampaign,getContactSearch, postCampaign, getContact, putCampaign} from '../../api/campaign'
import { history } from '../'
export const CAMPAIGN_CREATE = 'CAMPAIGN_CREATE',
    CAMPAIGN_UPDATE = 'CAMPAIGN_UPDATE',
    CAMPAIGN_LIST = 'CAMPAIGN_LIST',
    CONTACT_LIST = 'CONTACT_LIST'

export function campaignList(page) {
    return dispatch => {
        getCampaign('',page).then(response => {
            const {campaign, ...props} = response.data
            dispatch({
                type: CAMPAIGN_LIST,
                list: campaign,
                currentPage: page,
                ...props
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401){
                history.push('/login')
            }
        })
    }
}

export function campaignCreate(formData,xThis) {
    return dispatch => {
        postCampaign(formData).then(response => {
            dispatch({
                type: CAMPAIGN_CREATE,
                list: response.data
            })
            const {enqueueSnackbar} = xThis.props
            enqueueSnackbar('Campanha importada com sucesso!', {variant: 'success'})
            xThis._dialogCreate.handleClickOpen()
        }).catch(error => {
            const status = error.response.status
            if (status === 401){
                history.push('/login')
            }
        })
    }
}

export function campaignUpdate(id, activated,xThis) {
    return dispatch => {
        putCampaign(id, activated).then(response => {
            dispatch({
                type: CAMPAIGN_UPDATE,
                list: response.data
            })
            const {enqueueSnackbar} = xThis.props
            enqueueSnackbar('Campanha atualizada com sucesso!', {variant: 'success'})
        }).catch(error => {
            console.log('err', error)
        })
    }
}

export function contactSearch(search) {
    getContactSearch(search).then(response => {
        return response
    }).catch(error => {
        console.log(error)
    })
}
export function contactList(id,page) {
    return dispatch => {
        getContact(id,'',page).then(response => {
            const {contacts, ...props} = response.data
            dispatch({
                type: CONTACT_LIST,
                currentPage: page,
                list: contacts,
                ...props
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401){
                history.push('/login')
            }
        })
    }
}