import {getFlow,postFlow, putFlow} from '../../api/flowv2'
import {history} from '../'

export const FLOWV2_CREATE = 'FLOWV2_CREATE',
    FLOWV2_LIST = 'FLOWV2_LIST',
    FLOWV2_UPDATE = 'FLOWV2_UPDATE'

export function flowList() {
    return dispatch => {
        getFlow().then(response => {
            dispatch({
                type: FLOWV2_LIST,
                list: response.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401){
                history.push('/login')
            }
        })
    }
}

export function flowCreate(data, onSend) {
    return dispatch => {
        postFlow(data).then(response => {
            dispatch({
                type: FLOWV2_CREATE,
                flow: response.data
            });
            (onSend && onSend.success) && onSend.success()
        }).catch(error => {
            //console.log('err', error.response);
            (onSend && onSend.error) && onSend.error(error.response)
        })
    }
}

export function flowUpdate(id, onSend) {
    return dispatch => {
        putFlow(id).then(res => {
            dispatch({
                type: FLOWV2_UPDATE,
                flow: {
                    id,
                    status: "CANCELLED"
                }
            })
            (onSend && onSend.success) && onSend.success()
        }).catch(err => {
            if(onSend && onSend.error) {
                onSend.error()
            }
        })
    }
}
