import { getEmailMarketing, postEmailMarketing, putEmailMarketing } from '../../api/emailmarketing'
import {history} from '../'

export const EMAILMARKETING_CREATE = 'EMAILMARKETING_CREATE',
	EMAILMARKETING_UPDATE = 'EMAILMARKETING_UPDATE',
	EMAILMARKETING_LIST = 'EMAILMARKETING_LIST'

export function emailMarketingList() {
	return (dispatch) => {
		getEmailMarketing()
			.then((response) => {
				dispatch({
					type: EMAILMARKETING_LIST,
					list: response.data,
				})
			})
			.catch((error) => {
				const status = error.response.status
				if (status === 401) {
					history.push('/login')
				}
			})
	}
}

export function emailMarketingCreate(data, props) {
	return (dispatch) => {
		postEmailMarketing(data)
			.then((response) => {
				dispatch({
					type: EMAILMARKETING_CREATE,
					list: response.data,
				})

				const { enqueueSnackbar, history } = props
				history.push({pathname: '/configuracoes/email-marketing'})
				enqueueSnackbar('E-mail marketing criada com sucesso', { variant: 'success' })
			})
			.catch((error) => {
				console.log('err', error.response)
			})
	}
}

export function emailMarketingUpdate(id, data, props) {
	return (dispatch) => {
		putEmailMarketing(id, data)
			.then((response) => {
				dispatch({
					type: EMAILMARKETING_UPDATE,
					list: response.data,
				})
				const { enqueueSnackbar } = props
				enqueueSnackbar('E-mail marketing atualizado com sucesso', { variant: 'success' })
			})
			.catch((error) => {
				console.log('err', error)
			})
	}
}
