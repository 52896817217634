import instance from './instance'

export function getEmailService(id = '') {
    return instance.get(`/settings/email-ses/${id}`) //TODO terminar email, pendente de @back
}

export function postEmailService(email,activated,forwardEmail, emailCopy = '') {
    return instance.post(`/settings/email-ses/`, {email,forward_email_history: forwardEmail,email_copy: emailCopy})
}

export function putEmailService(id, email,activated,forwardEmail, emailCopy = '') {
    return instance.put(`/settings/email-ses/`, {id, email,activated,forward_email_history: forwardEmail,email_copy: emailCopy})
}
