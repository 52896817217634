import instance from './instance'

export function getFlow(id = '') {
    return instance.get(`/digiflow/campaign/${id}`)
}
export function getTemplate(id = '') {
    return instance.get(`/digiflow/template/${id}`)
}

export function getRelatory(id = '') {
    return instance.get(`/rel_whats/generate/${id}`)
}

export function postFlow(description,type,start,status,crmLote,flows) {
    return instance.post(`/digiflow/campaign`, {description,type,start,status,crmLote,flows})
}

export function postTemplate(description,type,daysOfWeek,start,minSend,maxSend,crmTable,flows) {
    return instance.post(`/digiflow/template`, {description,type,daysOfWeek,start,minSend,maxSend,crmTable,flows})
}

export function putTemplate(data) {
    return instance.put(`/digiflow/template/`,data)
}