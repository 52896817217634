import {getHorary, postHorary, putHorary} from '../../api/horary'
import {history} from '../'
export const HORARY_CREATE = 'HORARY_CREATE',
    HORARY_UPDATE = 'HORARY_UPDATE',
    HORARY_LIST = 'HORARY_LIST'

export function horaryList() {
    return dispatch => {
        getHorary().then(response => {
            dispatch({
                type: HORARY_LIST,
                list: response.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401){
                history.push('/login')
            }
        })
    }
}

export function horaryCreate(description,message_outside_attendance,offline_operator_message,department,days,activated,apply_to_bot, xThis) {
    return dispatch => {
        postHorary(description,message_outside_attendance,offline_operator_message,department,days,activated,apply_to_bot).then(response => {
            dispatch({
                type: HORARY_CREATE,
                list: response.data
            })
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(t.notifyCreate , {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error.response)
        })
    }
}


export function horaryUpdate(id, description,message_outside_attendance,offline_operator_message,department,days,activated,apply_to_bot, xThis) {
    return dispatch => {
        putHorary(id, description,message_outside_attendance,offline_operator_message,department,days,activated,apply_to_bot).then(response => {
            dispatch({
                type: HORARY_UPDATE,
                list: response.data
            })
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(t.notifyUpdate , {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error)
        })
    }
}