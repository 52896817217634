import socket, { socketConnectAuth } from '../../api/socket'
import {
	USER_AUTH,
	USER_ERROR,
	USER_LOGIN,
	USER_LOGOUT,
	USER_ME_UPDATE,
} from '../actions'
let socketConnected = false
export function auth(currentState = null, action) {
	switch (action.type) {
		case USER_LOGIN:
		case USER_AUTH:
			if (action && action.id && !socketConnected) {
				socketConnectAuth()
				setTimeout(() => {
					socket.emit('join', `count_${action.id}`)
					socket.emit('join', `user_${action.id}`)
					socket.emit('join', `notification`)
					socket.emit('join', `notification_${action.id}`)
					socket.emit('join', 'backoffice')
				}, 300)
				socketConnected = true
			}

			return (currentState = {
				isLogged: true,
				...action,
			})
		case USER_LOGOUT:
			socketConnected = false
			return (currentState = { ...action, isLogged: false })
		case USER_ERROR:
			return (currentState = { ...action })
		case USER_ME_UPDATE:
			return { ...currentState, ...action }
		default:
			return currentState
	}
}
