import {history} from '../'
import {
    getWorkspace,
    postWorkspace,
    putWorkspace,
    postWorkflow,
    putWorkflow,
    getWorkflow,
    putWorkflowFavorite, postWorkspaceLink, postWorkflowShare, putWorkflowOrder, putWorkspaceOrder,
} from 'api/workflow'

export const dispatchWorkspace = {
    WORKSPACE_CREATE: 'WORKSPACE_CREATE',
    WORKSPACE_UPDATE: 'WORKSPACE_UPDATE',
    WORKSPACE_LIST: 'WORKSPACE_LIST',
    WORKSPACE_SELECT: 'WORKSPACE_SELECT',
    WORKSPACE_SELECTUPDATE: 'WORKSPACE_SELECTUPDATE',
    WORKSPACE_RESET: 'WORKSPACE_RESET',
}

export const dispatchWorkflow = {
    WORKFLOW_CREATE: 'WORKFLOW_CREATE',
    WORKFLOW_UPDATE: 'WORKFLOW_UPDATE',
    WORKFLOW_LIST: 'WORKFLOW_LIST',
    WORKFLOW_SELECT: 'WORKFLOW_SELECT',
    WORKFLOW_SELECTUPDATE: 'WORKFLOW_SELECTUPDATE',
    WORKFLOW_RESET: 'WORKFLOW_RESET',
}

export function workspaceList(id, onSuccess, onError) {
    return (dispatch, getState) => {
        dispatch({
            type: dispatchWorkspace.WORKSPACE_LIST,
            load: true,
        })
        getWorkspace(id).then(response => {
            dispatch({
                type: dispatchWorkspace.WORKSPACE_LIST,
                ...response.data,
                load: false
            })
            onSuccess?.()
        }).catch(error => {
            const status = error.response.status
            onError?.(error.response.data)
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function workspaceCreate({onSuccess, onError, ...data}) {
    return (dispatch, getState) => {
        postWorkspace(data).then(response => {
            dispatch({
                type: dispatchWorkspace.WORKSPACE_CREATE,
                ...response.data,
            })
            onSuccess?.(response.data)
        }).catch(error => {
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
            onError?.(error.response.data)
        })
    }
}

export function workspaceSelect({onSuccess, onError, ...data}) {
    return (dispatch, getState) => {
        dispatch({
            type: dispatchWorkspace.WORKSPACE_SELECT,
            select: {
                load: true,
            },
        })
        getWorkspace(data.id).then(response => {
            dispatch({
                type: dispatchWorkspace.WORKSPACE_SELECT,
                select: response.data,
            })
            onSuccess?.()
        }).catch(error => {
            console.log(error)
            const status = error?.response?.status
            dispatch({
                type: dispatchWorkspace.WORKSPACE_SELECT,
                select: false,
            })
            onError?.(error?.response?.data)
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function workspaceUpdate({onSuccess, onError, ...data}) {
    return (dispatch, getState) => {
        putWorkspace(data).then(response => {
            dispatch({
                type: dispatchWorkspace.WORKSPACE_UPDATE,
                ...response.data,
            })
            onSuccess?.(response.data)
        }).catch(error => {
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
            onError?.(error.response.data)
        })
    }
}
  

export function workspaceUpdateShare({onSuccess, onError, ...data}) {
    return (dispatch, getState) => {
        postWorkspaceLink(data).then(response => {
            dispatch({
                type: dispatchWorkspace.WORKSPACE_UPDATE,
                ...response.data,
            })
            onSuccess?.(response.data)
        }).catch(error => {
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
            onError?.(error.response.data)
        })
    }
}

export function workspaceOrder(data, onSuccess, onError) {
    return (dispatch, getState) => {
        putWorkspaceOrder(data.order).then(response => {
            dispatch({
                type: dispatchWorkspace.WORKSPACE_SELECT,
                workspaces: data.order.map(currId => getState().workspace.workspaces.find(({id}) => id === currId))
            })
            onSuccess?.(response.data)
        }).catch(error => {
            const status = error.response.status
            onError?.(error.response.data)
            if (status === 401) {
                history.push('/login')
            }
        })

    }
}

export function workflowOrder(data, onSuccess, onError) {
    return (dispatch, getState) => {
        putWorkflowOrder(data).then(response => {
            if (getState().workspace.select?.id === data.id_workspace) {
                dispatch({
                    type: dispatchWorkspace.WORKSPACE_SELECT,
                    select: {
                        ...getState().workspace.select,
                        workflows: data.order.map((currId) => getState().workspace.select.workflows.find(({id}) => id === currId))
                    }
                })
            }
            if (data.id_workspace === 'favorite') {
                dispatch({
                    type: dispatchWorkspace.WORKSPACE_LIST,
                    favorite: data.order.map(currId => getState().workspace.favorite.find(({id}) => id === currId)),
                })
            } else {
                dispatch({
                    type: dispatchWorkspace.WORKSPACE_LIST,
                    workspaces: getState().workspace.workspaces.map((wks) => data.id_workspace !== wks.id ? wks : ({
                        ...wks,
                        workflows: data.order.map((currId) => wks.workflows.find(({id}) => id === currId))
                    })),
                })
            }
            onSuccess?.(response.data)
        }).catch(error => {
            const status = error.response.status
            onError?.(error.response.data)
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function workflowFavorite(data, onSuccess, onError) {
    return (dispatch, getState) => {
        putWorkflowFavorite({id: data.id}).then(response => {
            if (getState().workspace.favorite.find(({id}) => data.id === id)) {
                dispatch({
                    type: dispatchWorkspace.WORKSPACE_LIST,
                    favorite: getState().workspace.favorite.filter(({id}) => data.id !== id),
                })
            } else {
                dispatch({
                    type: dispatchWorkspace.WORKSPACE_LIST,
                    favorite: [...getState().workspace.favorite, data],
                })
            }
            if (getState().workspace.select) {
                dispatch({
                    type: dispatchWorkspace.WORKSPACE_SELECT,
                    select: {
                        ...getState().workspace.select,
                        workflows: getState().workspace.select.workflows.map((wf) => wf.id === data.id ? ({
                            ...wf,
                            favorite: !wf.favorite
                        }) : wf)
                    }
                })
            }
            if (getState().workflow?.id === data.id) {
                dispatch({
                    type: dispatchWorkflow.WORKFLOW_SELECT,
                    favorite: !getState().workflow.favorite
                })
            }
            onSuccess?.(response.data)
        }).catch(error => {
            console.log(error)
            const status = error.response.status
            onError?.(error.response.data)
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function workflowList(id, onSuccess, onError) {
    return (dispatch, getState) => {
        dispatch({
            type: dispatchWorkflow.WORKFLOW_LIST,
            load: true,
        })
        getWorkflow(id).then(response => {
            dispatch({
                type: dispatchWorkflow.WORKFLOW_LIST,
                ...response.data,
                load: false
            })
            onSuccess?.()
        }).catch(error => {
            const status = error.response.status
            onError?.(error.response.data)
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}
export function resetWorkflowState() {
    return {
        type: dispatchWorkflow.WORKFLOW_RESET
    };
}

export function workflowCreate({onSuccess, onError, ...data}) {
    return (dispatch, getState) => {
        postWorkflow(data).then(response => {
            dispatch({
                type: dispatchWorkflow.WORKFLOW_CREATE,
                ...response.data,
            })
            if (getState().workspace) {
                const currWorkspace = getState().workspace.workspaces.find(({id}) => id === response.data.id_workspace)
                if (currWorkspace) {
                    dispatch({
                        type: dispatchWorkspace.WORKSPACE_UPDATE,
                        id: currWorkspace.id,
                        workflows: Array.isArray(currWorkspace.workflows) ? [...currWorkspace.workflows, response.data] : [response.data]
                    })
                }
            }
            onSuccess?.(response.data)
        }).catch(error => {
            const status = error?.response?.status
            if (status === 401) {
                history.push('/login')
            }
            onError?.(error?.response?.data)
        })
    }
}

export function workflowSelect({onSuccess, onError, ...data}) {
    return (dispatch, getState) => {
        dispatch({
            type: dispatchWorkflow.WORKFLOW_SELECT,
            load: true
        })
        getWorkflow(data.id, data.search).then(response => {
            dispatch({
                type: dispatchWorkflow.WORKFLOW_SELECT,
                ...response.data,
                load: false
            })
            onSuccess?.()
        }).catch(error => {
            console.log(error)
            const status = error?.response?.status
            if (status === 401) {
                history.push('/login')
            }
            onError?.(error?.response?.data)
        })
    }
}

export function workflowUpdate({onSuccess, onError, ...data}) {
    return (dispatch, getState) => {
        putWorkflow(data).then(response => {
            dispatch({
                type: dispatchWorkflow.WORKFLOW_UPDATE,
                ...response.data,
            })
            onSuccess?.(response.data)
        }).catch(error => {
            console.log(error)
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
            onError?.(error.response.data)
        })
    }
}

export function workflowUpdateShare({onSuccess, onError, ...data}) {
    return (dispatch, getState) => {
        postWorkflowShare(data).then(response => {
            dispatch({
                type: dispatchWorkflow.WORKFLOW_UPDATE,
                ...response.data,
            })
            onSuccess?.(response.data)
        }).catch(error => {
            console.log(error)
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
            onError?.(error.response.data)
        })
    }
}