import {getEmailIMap,postEmailIMap,putEmailIMap} from '../../api/emailIMap'
import {history} from '../'
export const EMAILIMAP_CREATE = 'EMAILIMAP_CREATE',
    EMAILIMAP_UPDATE = 'EMAILIMAP_UPDATE',
    EMAILIMAP_LIST = 'EMAILIMAP_LIST'

export function emailIMapList() {
    return dispatch => {

        getEmailIMap().then(response => {
            dispatch({
                type: EMAILIMAP_LIST,
                list: response.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401){
                history.push('/login')
            }
        })
    }
}

export function emailIMapCreate(data,xThis) {
    return dispatch => {
        postEmailIMap(data).then(response => {
            dispatch({
                type: EMAILIMAP_CREATE,
                list: { ...response.data, config: [{...data, ...response.data.config[0]}] }
            })
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(t.notifyCreate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error)
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(error.response.data.error, {variant: 'error'})
        })
    }
}


export function emailIMapUpdate(data, xThis) {
    return dispatch => {
        putEmailIMap(data).then(response => {
            dispatch({
                type: EMAILIMAP_UPDATE,
                list: response.data
            })
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(t.notifyUpdate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error.response)
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(error.response.data.error, {variant: 'error'})
        })
    }
}