import {getEmailService,postEmailService,putEmailService} from '../../api/emailService'
import {history} from '../'
export const EMAILSERVICE_CREATE = 'EMAILSERVICE_CREATE',
    EMAILSERVICE_UPDATE = 'EMAILSERVICE_UPDATE',
    EMAILSERVICE_LIST = 'EMAILSERVICE_LIST'

export function emailServiceList() {
    return dispatch => {

        getEmailService().then(response => {
            dispatch({
                type: EMAILSERVICE_LIST,
                list: response.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401){
                history.push('/login')
            }
        })
    }
}

export function emailServiceCreate(email,activated,forwardEmail, emailCopy,xThis) {
    return dispatch => {
        postEmailService(email,activated,forwardEmail, emailCopy).then(response => {
            dispatch({
                type: EMAILSERVICE_CREATE,
                list: response.data
            })
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(t.notifyCreate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error.response)
        })
    }
}


export function emailServiceUpdate(id, email,activated,forwardEmail, emailCopy, xThis) {
    return dispatch => {

        putEmailService(id, email,activated,forwardEmail, emailCopy).then(response => {
            dispatch({
                type: EMAILSERVICE_UPDATE,
                list: response.data
            })
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(t.notifyUpdate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error)
        })
    }
}