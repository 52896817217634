import {
    getDash,
    getMonitoring,
    getAnalytic,
    getAnalyticLogs,
    getAnalyticTicket,
    getAnalyticOccurrence,
    getDashProtocol,
    getDashCRM,
    getAttendanceByChannel,
    getAgents,
    getDepartments,
    getProtocolByHour,
    getAttendanceTime,
    getTabs,
    getTickets,
    getAttendance, getAnalyticTransfer, getAnalyticMonitoring,
} from '../../api/dash'
import {getCRMTemplate} from "../../api/crm";
import {history} from '../'
export const DASH_LIST = 'DASH_LIST',
    DASH_ATTENDANCE_LIST = 'DASH_ATTENDANCE_LIST',
    DASH_ATTENDANCE_BY_CHANNEL_LIST = 'DASH_ATTENDANCE_BY_CHANNEL_LIST',
    DASH_AGENTS_LIST = 'DASH_AGENTS_LIST',
    DASH_DEPARTMENTS_LIST = 'DASH_DEPARTMENTS_LIST',
    DASH_PROTOCOL_BY_HOUR_LIST = 'DASH_PROTOCOL_BY_HOUR_LIST',
    DASH_ATTENDANCE_TIME_LIST = 'DASH_ATTENDANCE_TIME_LIST',
    DASH_TABS_LIST = 'DASH_TABS_LIST',
    DASH_TICKETS_LIST = 'DASH_TICKETS_LIST',
    MONITORING_LIST = 'MONITORING_LIST',
    ANALYTIC_LIST = 'ANALYTIC_LIST',
    DASH_UPDATE = 'DASH_UPDATE',
    DASH_ANALYTIC = 'DASH_ANALYTIC',
    DASH_RESET = 'DASH_RESET',
    DASH_ANALYTIC_FLOW = 'DASH_ANALYTIC_FLOW',
    DASH_ANALYTIC_TICKET = 'DASH_ANALYTIC_TICKET',
    DASH_ANALYTIC_MONITORING = 'DASH_ANALYTIC_MONITORING',
    DASH_ANALYTIC_OCCURRENCE = 'DASH_ANALYTIC_OCCURRENCE',
    DASH_ANALYTIC_TRANSFER = 'DASH_ANALYTIC_TRANSFER',
    DASH_ANALYTIC_PROTOCOL = 'DASH_ANALYTIC_PROTOCOL'

export function dashList({date, channel, department, user}, offset, rows) {
    return dispatch => {
        getDash(date, channel, department, user, offset, rows).then(v => {
            dispatch({
                type: DASH_LIST,
                list: {...v.data}
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function dashAttendanceList({date, channel, department, user}, offset, rows) {
    return dispatch => {
        getAttendance(date, channel, department, user, offset, rows).then(v => {
            dispatch({
                type: DASH_ATTENDANCE_LIST,
                list: {...v.data}
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function dashAttendanceByChannelList({date, channel, department, user}, offset, rows) {
    return dispatch => {
        getAttendanceByChannel(date, channel, department, user, offset, rows).then(v => {
            dispatch({
                type: DASH_ATTENDANCE_BY_CHANNEL_LIST,
                list: v.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function dashAgentsList({date, channel, department, user}, offset, rows) {
    return dispatch => {
        getAgents(date, channel, department, user, offset, rows).then(v => {
            const userType = {
                99: 'Administrador',
                1: 'Operador',
                2: `Operador N2`,
                3: 'Backoffice',
                6: 'Dashboard',
                8: 'Marketing',
                5: 'Monitor',
                7: 'Supervisor',
            }
            dispatch({
                type: DASH_AGENTS_LIST,
                list: v.data.map(({perfil,...data}) => ({perfil: userType[perfil],...data}))
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function dashDepartmentsList({date, channel, department, user}, offset, rows) {
    return dispatch => {
        getDepartments(date, channel, department, user, offset, rows).then(v => {
            dispatch({
                type: DASH_DEPARTMENTS_LIST,
                list: v.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function dashProtocolByHourList({date, channel, department, user}, offset, rows) {
    return dispatch => {
        getProtocolByHour(date, channel, department, user, offset, rows).then(v => {
            dispatch({
                type: DASH_PROTOCOL_BY_HOUR_LIST,
                list: {...v.data}
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function dashAttendanceTimeList({date, channel, department, user}, offset, rows) {
    return dispatch => {
        getAttendanceTime(date, channel, department, user, offset, rows).then(v => {
            dispatch({
                type: DASH_ATTENDANCE_TIME_LIST,
                list: {...v.data}
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function dashTabsList({date, channel, department, user}, offset, rows) {
    return dispatch => {
        getTabs(date, channel, department, user, offset, rows).then(v => {
            dispatch({
                type: DASH_TABS_LIST,
                list: v.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function dashTicketsList({date, channel, department, user}, offset, rows) {
    return dispatch => {
        getTickets(date, channel, department, user, offset, rows).then(v => {
            dispatch({
                type: DASH_TICKETS_LIST,
                list: {...v.data}
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function monitoringList(filter) {
    return dispatch => {
        getMonitoring(filter).then(v => {
            dispatch({
                type: MONITORING_LIST,
                list: {...v.data}
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function analyticList() {
    return dispatch => {
        getAnalytic().then(v => {
            dispatch({
                type: ANALYTIC_LIST,
                list: {...v.data}
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function analyticTicketList({date, channel, department, user, offset, rows,history_phase}) {
    return dispatch => {
        getAnalyticTicket(date, channel, department, user, offset, rows,history_phase).then(v => {
            dispatch({
                type: DASH_ANALYTIC_TICKET,
                ...v.data,
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function analyticMonitoringList({date, user, offset, rows}) {
    return dispatch => {
        getAnalyticMonitoring(date, user, offset, rows).then(v => {
            dispatch({
                type: DASH_ANALYTIC_MONITORING,
                list: v.data.data,
                ...v.data,
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function analyticOccurrenceList({date, channel, department, user, offset, rows}) {
    return dispatch => {
        getAnalyticOccurrence(date, channel, department, user, offset, rows).then(v => {
            dispatch({
                type: DASH_ANALYTIC_OCCURRENCE,
                ...v.data,
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function analyticTransferList({date, channel, department, user, offset, rows}) {
    return dispatch => {
        getAnalyticTransfer(date, channel, department, user, offset, rows).then(v => {
            dispatch({
                type: DASH_ANALYTIC_TRANSFER,
                ...v.data,
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function dashAnalyticFlow({date, channel, department, user, offset, rows}) {
    return dispatch => {
        getDashCRM(date, channel, department, user, offset, rows).then(crm => {
            return dispatch({
                type: DASH_ANALYTIC_FLOW,
                list: {
                    crm: crm.data.list,
                    crmTotal: crm.data.total
                }
            })
        }).catch(err => console.log(err))
    }
}

export function dashAnalyticProtocol({date, channel, department, user, offset, rows}) {
    return dispatch => {
        getDashProtocol(date, channel, department, user, offset, rows).then(protocols => {
            if (department?.length === 1 && protocols.data.list.length > 0 && protocols.data.list[0].crm_fields) {

                getCRMTemplate(protocols.data.list[0].crm_fields._template_id)
                    .then((template) => {

                        return dispatch({
                            type: DASH_ANALYTIC_PROTOCOL,
                            list: {
                                protocolos: protocols.data.list,
                                protocolTotal: protocols.data.total,
                                protocolos_template: template.data.fields?.filter(x => x.type !== 'array' && x.visible)
                            }
                        })
                    }).catch((err) => console.log(err))
            } else {

                return dispatch({
                    type: DASH_ANALYTIC_PROTOCOL,
                    list: {
                        protocolos: protocols.data.list,
                        protocolTotal: protocols.data.total,
                        protocolos_template: []
                    }
                })
            }
        }).catch(err => console.log(err))
    }
}

export function dashAnalytic({date, channel, department, user, offset, rows}) {
    return dispatch => {
        getDash(date, channel, department, user).then(dash => {
            return dispatch({
                type: DASH_ANALYTIC,
                list: {...dash.data}
            })
        }).catch(err => console.log(err))
    }
}