import instance from './instance'

export function getSms(id = '') {
    return instance.get(`/settings/sms/${id}`)
}

export function postSms(description,settings,department,activated) {
    return instance.post(`/settings/sms/`, {description,settings,department,activated})
}

export function putSms(id, description,settings,department,activated) {
    return instance.put(`/settings/sms/`, {id, description,settings,department,activated})
}
