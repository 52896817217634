import {getDepartment, postDepartment, putDepartment} from '../../api/department'
import {history} from '../'
export const DEPARTMENT_CREATE = 'DEPARTMENT_CREATE',
    DEPARTMENT_UPDATE = 'DEPARTMENT_UPDATE',
    DEPARTMENT_LIST = 'DEPARTMENT_LIST';

export function departmentList() {
    return dispatch => {
        getDepartment().then(response => {
            dispatch({
                type: DEPARTMENT_LIST,
                list: response.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function departmentCreate(data, xThis) {
    return dispatch => {
        postDepartment(data).then(response => {
            dispatch({
                type: DEPARTMENT_CREATE,
                list: response.data
            })
            const {enqueueSnackbar, t} = xThis?.props
            enqueueSnackbar(t.notifyCreate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error)
        })
    }
}


export function departmentUpdate(data,xThis) {
    return dispatch => {
        putDepartment(data).then(response => {
            dispatch({
                type: DEPARTMENT_UPDATE,
                list: {positions: data.positions, ...response.data}
            })
            const {enqueueSnackbar, t} = xThis?.props
            enqueueSnackbar(t.notifyUpdate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error)
        })
    }
}
