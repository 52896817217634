import {getCategory, postCategory, putCategory} from '../../api/category'
import {history} from '../'
export const CATEGORY_CREATE = 'CATEGORY_CREATE',
    CATEGORY_UPDATE = 'CATEGORY_UPDATE',
    CATEGORY_LIST = 'CATEGORY_LIST'

export function categoryList(department) {
    return dispatch => {
        getCategory(department).then(response => {
            dispatch({
                type: CATEGORY_LIST,
                list: response.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401){
                history.push('/login')
            }
        })
    }
}

export function categoryCreate(description, department, sla, activated, xThis) {
    return dispatch => {
        postCategory(description, department, sla, activated).then(response => {
            dispatch({
                type: CATEGORY_CREATE,
                list: response.data
            })
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(t.notifyCreate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error.response)
        })
    }
}


export function categoryUpdate(id, description, department, sla, activated, xThis) {
    return dispatch => {
        putCategory(id, description, department, sla, activated).then(response => {
            dispatch({
                type: CATEGORY_UPDATE,
                list: response.data
            })
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(t.notifyUpdate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error)
        })
    }
}