import { SOUL_HISTORIC_LIST } from "../actions"

const defaultState = {
    list: null
}

export function soul(currentState = defaultState, action) {
    switch (action.type) {
        case SOUL_HISTORIC_LIST:
            return action
            
        default:
            return currentState
    }
}