import instance from './instance'

export function getEmailIMap(id = '') {
    return instance.get(`/settings/email-imap/${id}`)
}

export function postEmailIMap(data) {
    return instance.post(`/settings/email-imap/service/`, data)
}

export function putEmailIMap(data) {
    return instance.put(`/settings/email-imap/service/`, data)
}
