import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
`

export const Spinner = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;
  animation: sk-rotate 1.5s infinite linear;

  .dot1, .dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    border-radius: 100%;
    animation: sk-bounce 2.0s infinite ease-in-out;
  }

  .dot1 {
    background-color: ${({theme}) => theme.primary};
  }

  .dot2 {
    background-color: ${({theme}) => theme.secondary};
    top: auto;
    bottom: 0;
    animation-delay: -1.0s;
  }


  @keyframes sk-rotate {
    100% { transform: rotate(360deg); }
  }

  @keyframes sk-bounce {
    0%, 100% { transform: scale(0.0); }
    50% { transform: scale(1.0); }
  }
`