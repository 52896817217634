import {
    CRM_CREATE,
    CRMBUSINESS_LIST,
    CRMTEMPLATE_LIST,
    CRMBUSINESS_UPDATE,
    CRMTEMPLATE_UPDATE,
    CRMBUSINESS_CREATEERROR,
    CRMBUSINESS_CREATE
} from "../actions";

export function crmBusiness(currentState = {}, action) {
    switch (action.type) {
        case CRMBUSINESS_LIST:
            return currentState = action
        case CRMBUSINESS_CREATE:
            return {
                list: currentState.list ? [action.list, ...currentState.list] : [action.list]
            }
        case CRMBUSINESS_CREATEERROR:
            return {
                ...currentState,
                err: action.err
            }
        case CRMBUSINESS_UPDATE:
            return {
                type: action.type,
                list: currentState.list.map(current => current._id === action.list._id ? {...current,...action.list} : current)
            }
        default:
            return currentState
    }
}

export function crmTemplate(currentState = null, action) {
    switch (action.type) {
        case CRM_CREATE:
            return {
                type: action.type,
                list: currentState.list ? currentState.list.concat(action.list) : [action.list]
            }
        case CRMTEMPLATE_UPDATE:
            return {
                type: action.type,
                list: currentState.list.map(current => {
                    if (current._id === action.list._id) {
                        return {...current, ...action.list}
                    } else {
                        return current
                    }
                })
            }
        case CRMTEMPLATE_LIST:
            return currentState = action
        default:
            return currentState
    }
}