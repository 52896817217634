import instance from "../instance";

export function logout() {
    return instance.get('/authentication/logout')
}

export function auth() {
    return instance.post('/auth')
}

export function me() {
    return instance.get('/authentication/me')
}