import instance from './instancev2'

export function getFlow(id = '') {
    return instance.get(`/smartflow/campaigns/${id}`)
}

export function postFlow(data) {
    return instance.post(`/smartflow/campaigns`, {...data})
}

export function simulateFlow(data) {
    return instance.post(`/smartflow/campaigns/simulate`, {...data})
}

export function exportSimulateFlow(data) {
    return instance.post(`/smartflow/campaigns/simulate/customers/export`, {...data})
}

export function exportCustomerFlow(data) {
    return instance.post(`/smartflow/campaigns/simulate`, {...data})
}

export function putFlow(id) {
    return instance.put(`/smartflow/campaigns/${id}`)
}