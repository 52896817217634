import {getInstagram, putInstagram, postInstagram} from '../../api/instagram'
import {history} from '../'
export const INSTAGRAM_CREATE = 'INSTAGRAM_CREATE',
    INSTAGRAM_UPDATE = 'INSTAGRAM_UPDATE',
    INSTAGRAM_LIST = 'INSTAGRAM_LIST'

export function instagramList() {
    return dispatch => {
        getInstagram().then(response => {
            dispatch({
                type: INSTAGRAM_LIST,
                list: response.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401){
                history.push('/login')
            }
        })
    }
}

export function instagramCreate(data,xThis) {
    return dispatch => {
        postInstagram(data).then(response => {
            dispatch({
                type: INSTAGRAM_CREATE,
                list: response.data
            })
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(t.notifyCreate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error.response)
        })
    }
}


export function instagramUpdate(data, xThis) {
    return dispatch => {

        putInstagram(data).then(response => {
            dispatch({
                type: INSTAGRAM_UPDATE,
                list: response.data
            })
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(t.notifyUpdate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error)
        })
    }
}