import { history } from '../../'
import { getMonitoring } from '../../../api/dash'
import { getUser, getUserReport, putUserStatus } from '../../../api/user'
import { userMe } from '../auth'

export const USER_CREATE = 'USER_CREATE',
	USER_UPDATE = 'USER_UPDATE',
	USER_LIST = 'USER_LIST',
	USERSTATUS_UPDATE = 'USERSTATUS_UPDATE',
	USERSTATUS_LIST = 'USERSTATUS_LIST'

export function userList() {
	return dispatch => {
		/*Data de Criação: (Data da criação do Usuário)
        Criado Por: (Quem criou)
        Visto por último Em:  (Último Acesso)
        Protocolos no Mês Anterior: (Total de Protocolos atendidos/Ativos no mês anterior)
        Perfil: (Perfil do cadastro)*/
		getUser()
			.then(response => {
				getUserReport().then(({ data }) => {
					dispatch({
						type: USER_LIST,
						list: response.data.map(u => ({
							...data.find(x => x.id === u.id),
							...u,
						})),
					})
				})
			})
			.catch(error => {
				const status = error.response.status
				if (status === 401) {
					history.push('/login')
				}
			})
	}
}

export function userStatusList(x) {
	return dispatch => {
		x
			? dispatch({
					type: USERSTATUS_LIST,
					list: x,
				})
			: getMonitoring()
					.then(response => {
						dispatch({
							type: USERSTATUS_LIST,
							list: response.data,
						})
					})
					.catch(error => {
						const status = error.response.status
						if (status === 401) {
							history.push('/login')
						}
					})
	}
}

export function userStatusUpdate(data, onSuccess, onError) {
	return dispatch => {
		putUserStatus(data)
			.then(() => {
				onSuccess && onSuccess()
				dispatch(userMe())
			})
			.catch(error => {
				const status = error.response.status
				onError && onError(error.response.data.error)
				setTimeout(() => {
					dispatch(userMe())
				}, 300)
				if (status === 401) {
					history.push('/login')
				}
			})
	}
}
