import instance from './instance'

export function getWatson(id = '') {
    return instance.get(`/settings/watson/${id}`)
}

export function postWatson(data) {
    return instance.post(`/settings/watson/`, data)
}

export function putWatson(data) {
    return instance.put(`/settings/watson/`, data)
}
