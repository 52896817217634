import io from 'socket.io-client'

import { env } from 'env'
const socket = io.connect(`${env.REACT_APP_API_URL}`, {
	reconnection: true,
	autoConnect: false,
	timeout: 500,
	reconnectionDelay: 1000,
	reconnectionDelayMax: 5000,
	reconnectionAttempts: Infinity,
})

export const socketConnectAuth = () => {
	if (socket?.connected) {
		socket.disconnect()
	}

	socket.io.on('reconnect_attempt', param => {
		console.log('reconnect_ attempt', param)
	})

	socket.io.on('reconnect', param => {
		console.log('reconnect', param)
	})

	socket.on('disconnect', channel => {
		console.log('disconnect', channel) // false
	})

	socket.io.opts.query = {
		token: localStorage.getItem('token'),
	}
	socket.connect()
}

export default socket
