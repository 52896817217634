import {TOGGLE_PREVIEW} from "../actions"

let getDefaultChatState = () => ({
    showPreview: false,
    data: {}
})

export function chat(currentState = getDefaultChatState(), action) {
    switch (action.type) {
        case TOGGLE_PREVIEW:
            return {
                ...currentState,
                showPreview: !currentState.showPreview,
                data: action.data
            }
        default:
            return currentState
    }
}