import {
    USER_CREATE,
    USER_LIST,
    USER_UPDATE,
    USER_STATUS,
    USERSTATUS_LIST,
    USERSTATUS_UPDATE,
} from "../actions";

export function user(currentState = {}, action) {
    switch (action.type) {
        case USER_CREATE:
            return {
                type: action.type,
                list: currentState.list ? currentState.list.concat(action.list) : [action.list]
            }
        case USER_LIST:
            return currentState = action
        case  USER_UPDATE:
            return {
                type: action.type,
                list:  currentState.list.map(current => current.id  === action.list.id ? action.list : current)
            }
        case USER_STATUS:
            return {
                list: Array.isArray(currentState.list) && currentState.list.map(current => current.id  === action.user_id ? {...current, id: action.user_id, status: action.user_status} : current)
            }
        default:
            return currentState
    }
}

export function userStatus(currentState = {}, action) {
    switch (action.type) {
        case USERSTATUS_LIST:
            return currentState = action
        case USERSTATUS_UPDATE:
            return {
                type: action.type,
                list:  currentState.list.map(current => current.id  === action.list.id ? action.list : current)
            }
        default:
            return currentState
    }
}