import instance from './instance'

export function getHorary(id = '') {
    return instance.get(`/settings/office_hours/${id}`)
}

export function postHorary(description,message_outside_attendance,offline_operator_message,department,days,activated,apply_to_bot) {
    return instance.post(`/settings/office_hours/`, {description,message_outside_attendance,offline_operator_message,department,days,activated,apply_to_bot})
}

export function putHorary(id, description,message_outside_attendance,offline_operator_message,department,days,activated,apply_to_bot) {
    return instance.put(`/settings/office_hours/`, {id, description,message_outside_attendance,offline_operator_message,department,days,activated,apply_to_bot})
}
