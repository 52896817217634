import {
	getGroup, postAddAdminMember,
	postAddMember,
	postGroup,
	postGroupMessage,
	postNoteGroup, postRemoveAdminMember,
	postRemoveMember,
	putGroupMemberUpdate,
} from '../../api/group'
import { history } from '../'
import socket from '../../api/socket'

export const GROUP_CREATE = 'GROUP_CREATE',
	GROUP_UPDATE = 'GROUP_UPDATE',
	GROUP_LIST = 'GROUP_LIST',

	GROUP_SELECT = 'GROUP_SELECT',
	GROUP_RESET = 'GROUP_RESET',
	GROUP_SELECTUPDATE = 'GROUP_SELECTUPDATE',
	GROUP_MESSAGE = 'GROUP_MESSAGE',
	GROUP_ADDMEMBER = 'GROUP_ADDMEMBER',
	GROUP_NOTE = 'GROUP_NOTE',
	GROUP_REMOVEMEMBER = 'GROUP_REMOVEMEMBER'

export function groupList(id) {
	return dispatch => {
		getGroup(id).then(response => {
			dispatch({
				type: GROUP_LIST,
				list: response.data,
			})
		}).catch(error => {
			const status = error.response.status
			if (status === 401) {
				history.push('/login')
			}
		})
	}
}

export function groupCreate(data, onSuccess, onError) {
	return dispatch => {
		postGroup(data).then(response => {
			dispatch({
				type: GROUP_CREATE,
				list: response.data,
			})
			onSuccess && onSuccess()
		}).catch(error => {
			const status = error.response.status
			if (status === 401) {
				history.push('/login')
			}
			onError && onError()
		})
	}
}

export function selectGroup(group) {
	return (dispatch, getState) => {

		dispatch({
			type: GROUP_RESET,
			select: true,
			load: true,
			loadCRM: true,
			loadHistory: true,
		})
		//const currPath = window.location.pathname.split('/').filter(x => x.length > 0)
		getGroup(group).then(response => {
			/*if (group && currPath.length > 0 && currPath.length !== 4 && currPath[0] === 'chats') {
				history.push(`/${window.location.pathname.split('/').filter(x => x.length > 0).join('/')}/${group}`)
			}*/
			dispatch({
				type: GROUP_SELECT,
				select: true,
				load: false,
				message: [],
				note: [],
				...response.data,
			})
			socket.emit('join', `group_${response.data.id}`)

			socket.on('add_member', (e) => {
				dispatch({ type: GROUP_ADDMEMBER, ...e })
			})
			socket.on('remove_member', (e) => {
				dispatch({ type: GROUP_REMOVEMEMBER, ...e })
			})
			socket.on('message', (e) => {
				dispatch({ type: GROUP_MESSAGE, message: { group_id: e.id, ...e.message } })
			})
			socket.on('note_group', (e) => {
				dispatch({ type: GROUP_NOTE, note: { group_id: e.id, ...e.message } })
			})

		}).catch(error => {
			const status = error.response.status
			if (status === 401) {
				history.push('/login')
			}
		})
	}
}


export function sendMessageGroup(data, onSuccess, onError) {
	return (dispatch, getState) => {
		postGroupMessage(data)
			.then((response) => {
				onSuccess && onSuccess()
			})
			.catch((error) => {
				onError && onError(error)

			})
	}
}


export function sendNoteGroup(data, onSuccess, onError) {
	return (dispatch, getState) => {
		postNoteGroup(data)
			.then((response) => {
				onSuccess && onSuccess()
				/*dispatch({
					type: GROUP_NOTE,
					note: response.data,
				})*/
			})
			.catch((err) => {
				const error = err?.response?.data?.error
				onError && onError(error)
			})
	}
}

export function addMemberGroup(data, onSuccess, onError) {
	return (dispatch, getState) => {
		postAddMember(data)
			.then((response) => {
				onSuccess && onSuccess()
			})
			.catch((err) => {
				const error = err?.response?.data?.error
				onError && onError(error)
			})
	}
}

export function removeMemberGroup(data, onSuccess, onError) {
	return (dispatch, getState) => {
		postRemoveMember(data)
			.then((response) => {
				onSuccess && onSuccess()
			})
			.catch((err) => {
				const error = err?.response?.data?.error
				onError && onError(error)
			})
	}
}

export function adminMemberGroup(data, onSuccess, onError) {
	return (dispatch, getState) => {
		const isAdmin = data.admin
		if (isAdmin) {
			postRemoveAdminMember(data)
				.then((response) => {
					onSuccess && onSuccess()
					dispatch({ type: GROUP_REMOVEMEMBER,id: getState().groupSelect.id, members: [response.data]})
				})
				.catch((err) => {
					const error = err?.response?.data?.error
					onError && onError(error)
				})
		} else {
			postAddAdminMember(data)
				.then((response) => {
					onSuccess && onSuccess()
					dispatch({ type: GROUP_REMOVEMEMBER,id: getState().groupSelect.id, members: [response.data]})
				})
				.catch((err) => {
					const error = err?.response?.data?.error
					onError && onError(error)
				})
		}
	}
}

export function updateGroup(data) {
	return (dispatch) => {
		delete data.id
		delete data.schema_list
		dispatch({
			type: GROUP_SELECTUPDATE,
			...data,
		})
	}
}

export function updateMemberGroup(data) {
	return (dispatch, getState) => {
		dispatch(
			updateGroup({ member: Array.isArray(getState().groupSelect.member) ? [...getState().groupSelect.member.map(x => x.id !== data.id_member ? x : { ...x, ...data })] : [] }),
		)
	}
}

export function addCustomerGroup(data, xThis) {
	return (dispatch, getState) => {
		dispatch(
			updateGroup({
				member: Array.isArray(getState().groupSelect.member) ? [...getState().groupSelect.member.map(x => x.id !== data.id_member ? x : {
					...x,
					load: true,
				})] : [{ load: true }],
			}),
		)

		putGroupMemberUpdate(data)
			.then((res) => {
				dispatch(
					updateGroup({
						member: [
							...getState().groupSelect.member.map((x) => x.id !== data.id_member ? x : { ...res.data }),
						],
					}),
				)
			})
			.catch((error) => console.log(error))
	}
}
