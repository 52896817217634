import {getBreak, postBreak, putBreak} from '../../api/break'
import { history } from '../'

export const BREAK_CREATE = 'BREAK_CREATE',
    BREAK_UPDATE = 'BREAK_UPDATE',
    BREAK_LIST = 'BREAK_LIST'

export function breaksList(id) {
    return dispatch => {
        getBreak(id).then(response => {
            dispatch({
                type: BREAK_LIST,
                list: response.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401){
                history.push('/login')
            }
        })
    }
}

export function breaksCreate(data, xThis) {
    return dispatch => {
        postBreak(data).then(response => {
            dispatch({
                type: BREAK_CREATE,
                list: response.data
            })
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(t.notifyCreate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error.response)
        })
    }
}


export function breaksUpdate(data, xThis) {
    return dispatch => {
        putBreak(data).then(response => {
            dispatch({
                type: BREAK_UPDATE,
                list: response.data
            })
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(t.notifyUpdate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error)
        })
    }
}