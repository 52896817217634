import {
    EMAILMARKETING_LIST,
    EMAILMARKETING_UPDATE,
    EMAILMARKETING_CREATE
} from "../actions";

export function emailMarketing(currentState = {}, action) {
    switch (action.type) {
        case EMAILMARKETING_CREATE:
            return {
                type: action.type,
                list: currentState.list ? currentState.list.concat(action.list) : [action.list]
            }
        case EMAILMARKETING_LIST:
            return currentState = action
        case EMAILMARKETING_UPDATE:
            return {
                type: action.type,
                list:  currentState.list.map(current => current.id  === action.list.id ? action.list : current)
            }
        default:
            return currentState
    }
}