import instance from './instance'

export function getInstagram(id = '') {
    return instance.get(`/settings/instagram-chat/${id}`)
}

export function postInstagram(data) {
    return instance.post(`/settings/instagram-chat/`, data)
}

export function putInstagram(data) {
    return instance.put(`/settings/instagram-chat/`, data)
}