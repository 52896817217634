import {duplicateNPS, getNPS, postNPS, putNPS, putQuestion} from "../../api/nps"
import {history} from '../'
export const
    NPS_LIST = 'NPS_LIST',
    NPS_LIST_LOADING = 'NPS_LIST_LOADING',
    //
    NPS_CREATE = 'NPS_CREATE',
    NPS_CREATE_LOADING = 'NPS_CREATE_LOADING',
    NPS_CREATE_ERROR = 'NPS_CREATE_ERROR',
    //
    NPS_READ = 'NPS_READ',
    NPS_READ_LOADING = 'NPS_READ_LOADING',
    //
    NPS_UPDATE = 'NPS_UPDATE',
    NPS_UPDATE_LOADING = 'NPS_UPDATE'

export function npsList() {
    return dispatch => {
        getNPS().then(response => {
            dispatch({
                type: NPS_LIST_LOADING,
                listLoading: true
            })
            dispatch({
                type: NPS_LIST,
                list: response.data
            })
            dispatch({
                type: NPS_LIST_LOADING,
                listLoading: false
            })
        }).catch(error => {
            /* The following lines are the standard behavior */
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function npsCreate(survey, onSuccess, onError) {
    return dispatch => {
        dispatch({
            type: NPS_CREATE_ERROR,
            createError: false
        })
        dispatch({
            type: NPS_CREATE_LOADING,
            createLoading: true
        })
        postNPS(survey)
            .then(response => {
                // Adding this default property here because this entity didn't come from API
                survey.created_at = new Date().toISOString()
                dispatch({
                    type: NPS_CREATE,
                    survey
                })
                onSuccess()
            })
            .catch(error => {
                dispatch({
                    type: NPS_CREATE_ERROR,
                    createError: true
                })
                /* The following lines are the standard behavior */
                const status = error.response.status
                if (status === 401) {
                    history.push('/login')
                } else {
                    onError()
                }
            })
            .finally(() => {
                dispatch({
                    type: NPS_CREATE_LOADING,
                    createLoading: false
                })
            })
    }
}

export function npsUpdate(survey, onSuccess, onError) {
    return dispatch => {
        putNPS(survey)
            .then(response => {
                dispatch({
                    type: NPS_UPDATE,
                    item: survey
                })
                onSuccess()
            })
            .catch(error => {
                dispatch({
                    type: NPS_CREATE_ERROR,
                    createError: true
                })
                /* The following lines are the standard behavior */
                const status = error.response.status
                if (status === 401) {
                    history.push('/login')
                } else {
                    onError && onError()
                }
            })
    }
}

export function npsDuplicate(survey, onSuccess, onError) {
    return () => {
        duplicateNPS(survey)
            .then(() => {
                onSuccess()
            })
            .catch(() => {
                onError()
            })
    }
}

export function npsRead(surveyId, onSuccess) {
    return dispatch => {
        dispatch({
            type: NPS_READ_LOADING,
            readLoading: true
        })
        dispatch({
            type: NPS_CREATE_ERROR,
            createError: false
        })
        getNPS(surveyId)
            .then(x => {
                dispatch({
                    type: NPS_READ,
                    item: x.data[0]
                })
                if (onSuccess) {
                    onSuccess(x.data[0])
                }
            })
            .catch(x => {
                // TODO Add custom handling
            })
            .finally(() => {
                dispatch({
                    type: NPS_READ_LOADING,
                    readLoading: false
                })
            })
    }
}

export function questionUpdate(question, survey, index) {
    return dispatch => {
        // Don't await on this, because this method is only used to deactivate questions
        putQuestion(question, survey, index)
            .then(() => {
                /* Nothing here, the question's been deactivated */
            })
    }
}
