import instance from "./instance";
import instancev2 from "./instancev2";

export function getCRMBusiness() {
  return instance.get(`/crm/business/activated`);
}
export function getCRMBusinessActive(page, limit) {
  return instance.get(
    `/crm/business/activated/paginated?page=${page}${
      limit ? `&limit=${limit}` : ""
    }`
  );
}

export function getCRMBusinessInactive(page, limit) {
  return instance.get(
    `/crm/business/inactivated/paginated?page=${page}&limit=${limit}`
  );
}

export function getCRMBusinessPaginated(crmId = "", page) {
  return instance.get(`/crm/business/${crmId}/paginated?page=${page}&limit=50`);
}

export function getCRMTemplate(id = "", page, data = false) {
  return instance.get(
    `/crm/templates${id !== "" ? `/${id}` : ""}${data ? "/data" : ""} ${
      page ? `?page=${page}` : ""
    }`
  );
}


export function getCRMTemplateV2(id = '') {
  return instancev2(`crm/templates/${id}`)
}

export function exportDataCRMTemplate(id = "") {
  return instance.post(`/crm/templates/${id}/data_export`);
}

export function postSingleRegister(template, data) {
  return instancev2.post(`/crm/templates/${template}/data/single`, data)
}

export function getCRMTemplateData(id_template = "", page, limit = 30, data) {
  return instance.post(
    `/crm/templates/${
      id_template?.length > 0 ? `${id_template}/` : ""
    }data/paginated?page=${page}&limit=${limit}`,
    data
  );
}

export function exportCRMTemplateData(id_template = "", data) {
  return instance.post(`/crm/templates/${id_template}/data/export`, data);
}

export function getCRMSearch(id = "", templateid) {
  return instance.get(
    `/crm/customers/search${id !== "" ? `?search=${id}` : ""}`,
    { headers: { templateid } }
  );
}

export function getCRMCustomSearch({
  term = "",
  page = 0,
  limit = 20,
  templateId,
}) {
  return instance.get(
    `/crm/customers/search/paginated?search=${term}&page=${page}&limit=${limit}`,
    { headers: { templateid: templateId ?? -1 } }
  );
}

export function getCRMCustomer(id, templateId) {
  return instance.get(
    `/crm/customers/${id}`,
    templateId ? { headers: { templateid: templateId } } : {}
  );
}

// Obtém customers paginado - caso utilize templateid é utilizado no header
export function getCRMCustomers({ page = 0, limit = 20, templateid }) {
  return instance.get(
    `/crm/customers/paginated?page=${page}&limit=${limit}`,
    templateid ? { headers: { templateid } } : { headers: { templateid: -1 } }
  );
}

export function postCRMTemplate(data) {
  return instance.post(`/crm/templates`, data);
}

export function postCRMCustomer(templateId, data) {
  return instance.post(`/crm/send_single_register`, { templateId, data });
}

export function putSingleUpdateCustomer(template, customer, data, templateid) {
  return instance.put(
    `/crm/business/${template}/data/${customer}`,
    data,
    templateid ? { headers: { templateid } } : { headers: { templateid: -1 } }
  );
}

export function getSingleCustomer(template, customer, templateid) {
  return instance.get(
    `/crm/business/${template}/data/${customer}`,
    templateid ? { headers: { templateid } } : { headers: { templateid: -1 } }
  );
}

// Realiza o update dos campos principais - "customer_"
export function putCRMCustomerFields(id, data) {
  return instance.put(`/crm/customers/${id}`, data);
}

// Realiza update no mailing específico para o resourceid e templateid
export function putCRMMailingTemplate(
  templateid,
  mailingid,
  resourceid,
  payload
) {
  return instance.put(
    `/crm/business/${mailingid}/data/${resourceid}`,
    payload,
    {
      headers: { templateid },
    }
  );
}

export function putCRMCustomer(data) {
  return instance.put(`/protocol/update/business`, data);
}

export function postBusinessFile(formData) {
  return instance.post(`/crm/send_file/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function putCRMBusiness(id, active, active_until) {
  return instance.put(`/crm/business/${id}`, { active, active_until });
}

export function postCRMBusinessError(id_business) {
  return instance.post(`crm/business/${id_business}/invalids/export`);
}

export function exportCRMBusiness(id_business) {
  return instance.post(`crm/business/${id_business}/data/export`);
}

export function putCRMTemplate(id, data) {
  return instance.put(`/crm/templates/${id}/edit`, data);
}

export function putCRMTemplateActive(id, active) {
  return instance.put(`/crm/templates/${id}`, { active });
}


export function billetGenerate(data, templateId) {
  return instance.post(`/crm/function/gerar_boleto`, data, {
    headers: { templateid: templateId ?? -1 },
  });
}

export function billetDownload(data, templateId) {
  return instance.post(`/crm/function/download_billet`, data, {
    headers: { templateid: templateId ?? -1 },
  });
}

export function emailBilletSend(data, templateId) {
  return instance.post(`/crm/function/envio_boleto_email`, data, {
    headers: { templateid: templateId ?? -1 },
  });
}
