import en from './en'
import es from './es'
import esAr from './esAr'
import esPer from './esPer'
import esMex from './esMex'
import br from './pt-br'
export const translations = {
   // "en-US": en,
    "es-ES": es,
    "es-Ar": esAr,
    "es-Per": esPer,
    "es-Mex": esMex,
    "pt-BR": br
}