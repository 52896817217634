import {getWhatsApp,postWhatsApp,putWhatsApp, postWhatsAppHSM, putWhatsAppHSM} from '../../api/whatsApp'
import {history} from '../'
export const WHATSAPP_CREATE = 'WHATSAPP_CREATE',
    WHATSAPP_UPDATE = 'WHATSAPP_UPDATE',
    WHATSAPP_LIST = 'WHATSAPP_LIST'

export function whatsAppList() {
    return dispatch => {
        getWhatsApp().then(response => {
            dispatch({
                type: WHATSAPP_LIST,
                list: response.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401){
                history.push('/login')
            }
        })
    }
}

export function whatsAppCreate(description,settings,activated, hsm,xThis) {
    return dispatch => {
        postWhatsApp(description,settings,activated).then(response => {
            dispatch({
                type: WHATSAPP_CREATE,
                list: response.data
            })
            hsm.map(x => x.id ? putWhatsAppHSM(x.id, response.data.id, x) : postWhatsAppHSM(response.data.id, x))
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(t.notifyCreate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error.response)
        })
    }
}


export function whatsAppUpdate(id, description,settings,activated, hsm, xThis) {
    return dispatch => {

        putWhatsApp(id, description,settings,activated).then(response => {
            dispatch({
                type: WHATSAPP_UPDATE,
                list: response.data
            })
            hsm.map(x => x.id ? putWhatsAppHSM(x.id, response.data.id, x) : postWhatsAppHSM(response.data.id, x))
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(t.notifyUpdate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error)
        })
    }
}