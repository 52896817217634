import {
	DASH_LIST,
	DASH_ATTENDANCE_LIST,
	DASH_ATTENDANCE_BY_CHANNEL_LIST,
	DASH_AGENTS_LIST,
	DASH_DEPARTMENTS_LIST,
	DASH_PROTOCOL_BY_HOUR_LIST,
	DASH_ATTENDANCE_TIME_LIST,
	DASH_TABS_LIST,
	DASH_TICKETS_LIST,
	MONITORING_LIST,
	ANALYTIC_LIST,
	DASH_ANALYTIC,
	DASH_RESET,
	DASH_UPDATE,
	DASH_ANALYTIC_FLOW,
	DASH_ANALYTIC_PROTOCOL,
	DASH_ANALYTIC_TICKET,
	DASH_ANALYTIC_OCCURRENCE,
	DASH_ANALYTIC_TRANSFER, DASH_ANALYTIC_MONITORING,
} from '../actions'

const initialState = {
	dashList: null,
	monitoringList: null,
	analyticList: null,
}

export function dash(currentState = initialState, action) {
	switch (action.type) {
		case DASH_LIST:
			return { ...currentState, dashList: { ...action.list } }
		case DASH_ATTENDANCE_LIST:
			return { ...currentState, dashAttendanceList: { ...action.list } }
		case DASH_ATTENDANCE_BY_CHANNEL_LIST:
			return { ...currentState, dashAttendanceByChannelList: action.list }
		case DASH_AGENTS_LIST:
			return { ...currentState, dashAgentsList: action.list }
		case DASH_DEPARTMENTS_LIST:
			return { ...currentState, dashDepartmentsList: action.list }
		case DASH_PROTOCOL_BY_HOUR_LIST:
			return { ...currentState, dashProtocolByHourList: { ...action.list } }
		case DASH_ATTENDANCE_TIME_LIST:
			return { ...currentState, dashAttendanceTimeList: { ...action.list } }
		case DASH_TABS_LIST:
			return { ...currentState, dashTabsList: action.list }
		case DASH_TICKETS_LIST:
			return { ...currentState, dashTicketsList: { ...action.list } }
		case MONITORING_LIST:
			return { ...currentState, monitoringList: { ...action.list } }
		case ANALYTIC_LIST:
			return { ...currentState, analyticList: { ...action.list } }
		case DASH_ANALYTIC:
			return { ...currentState, type: action.type, ...action.list }
		case DASH_UPDATE:
			return { ...currentState, ...action.list, protocolos: [] }
		case DASH_RESET:
			return null
		case DASH_ANALYTIC_FLOW:
			return { ...currentState, type: DASH_ANALYTIC, ...action.list }
		case DASH_ANALYTIC_TICKET:
			return { ...currentState, type: DASH_ANALYTIC, analyticTicketList: action.list, analyticTicketTotal: action.total }
		case DASH_ANALYTIC_MONITORING:
			return { ...currentState, type: DASH_ANALYTIC, analyticMonitoringList: action.list, analyticMonitoringTotal: action.total }
		case DASH_ANALYTIC_OCCURRENCE:
			return { ...currentState, type: DASH_ANALYTIC, analyticOccurrenceList: action.list, analyticOccurrenceTotal: action.total }
		case DASH_ANALYTIC_TRANSFER:
			return { ...currentState, type: DASH_ANALYTIC, analyticTransferList: action.data, analyticTransferTotal: action.total }
		case DASH_ANALYTIC_PROTOCOL:
			return { ...currentState, type: DASH_ANALYTIC, ...action.list }
		default:
			return currentState
	}
}
