import instance from "../instance";
import instancev2 from '../instancev2'

export function getUser(id = '') {
    return instance.get(`/settings/users/${id}`)
}

export function getUTC() {
    return instance.get(`/settings/utc`)
}

export function getUserReport() {
    return instance.get(`/settings/users/report`)
}

export function getUserStatus(id = '') {
    return instance.get(`/users/status`)
}
export function exportUserReport(date = '') {
    return instance.get(`settings/users/report/download?date=${JSON.stringify(date)}`)
}

export function putUserStatus({ pause_id, ...data }) {
    return instance.post(`/settings/users/status`,{id_break: pause_id,...data,active: data.action !== 'return'})
}

export function putChangeRamalStatus(data) {
    return instance.put(`/settings/users/change_ramal_status`,data)
}
export function putChangeRamalType(data) {
    return instance.put(`/settings/users/change_ramal_type`,data)
}

export function getStatusChangeRamal() {
		return instance.get(`/mobcall/status`)
}

export function postUser(formData) {
   return instance.post(`/settings/users/`, formData, {
       headers: {
           'Content-Type': 'multipart/form-data'
       }
   })
}

export function putUser(formData) {
   return instance.put(`/settings/users/`, formData, {
       headers: {
           'Content-Type': 'multipart/form-data'
       }
   })
}

export function putCpcNotification(data, id = '') {
    return instance.put(`/settings/users/`, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
