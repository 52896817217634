import {postLandingPage, putLandingPage, getLandingPage} from '../../api/landingPage'
import {history} from '../'
export const LANDINGPAGE_CREATE = 'LANDINGPAGE_CREATE',
    LANDINGPAGE_UPDATE = 'LANDINGPAGE_UPDATE',
    LANDINGPAGE_LIST = 'LANDINGPAGE_LIST'

export function landingPageList() {
    return (dispatch) => {
        getLandingPage()
            .then((response) => {
                dispatch({
                    type: LANDINGPAGE_LIST,
                    list: response.data,
                })
            })
            .catch((error) => {
                const status = error.response.status
                if (status === 401) {
                    history.push('/login')
                }
            })
    }
}

export function landingPageCreate(data, props) {
    return dispatch => {
        postLandingPage(data).then(response => {
            dispatch({
                type: LANDINGPAGE_CREATE,
                list: response.data
            })

            const {enqueueSnackbar} = props
            enqueueSnackbar('Landing Page criada com sucesso' , {variant: 'success'})

            props.history.push({
                pathname: '/configuracoes/landing-page',
            })
        }).catch(error => {
            console.log('err', error.response)
        })
    }
}


export function landingPageUpdate(id, data, props) {
    return dispatch => {
        putLandingPage(id, data).then(response => {
            dispatch({
                type: LANDINGPAGE_UPDATE,
                list: response.data
            })
            const {enqueueSnackbar} = props
            enqueueSnackbar('Landing Page atualizada com sucesso' , {variant: 'success'})
        }).catch(error => {
            console.log('err', error)
        })
    }
}