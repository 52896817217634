import {getSms,postSms,putSms} from '../../api/sms'
import {history} from '../'
export const SMS_CREATE = 'SMS_CREATE',
    SMS_UPDATE = 'SMS_UPDATE',
    SMS_LIST = 'SMS_LIST'

export function smsList() {
    return dispatch => {

        getSms().then(response => {
            dispatch({
                type: SMS_LIST,
                list: response.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401){
                history.push('/login')
            }
        })
    }
}

export function smsCreate(description,settings,department,activated,xThis) {
    return dispatch => {
        postSms(description,settings,department,activated).then(response => {
            dispatch({
                type: SMS_CREATE,
                list: response.data
            })
            const {enqueueSnackbar, t} = xThis.props
            enqueueSnackbar(t.notifyCreate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error.response)
        })
    }
}


export function smsUpdate(id, description,settings,department,activated, xThis) {
    return dispatch => {

        putSms(id, description,settings,department,activated).then(response => {
            dispatch({
                type: SMS_UPDATE,
                list: response.data
            })
            const {enqueueSnackbar, t} = xThis.props
            enqueueSnackbar(t.notifyUpdate , {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error.response)
        })
    }
}