import instance from "./instance"

export async function getNPS(id = '') {
    return instance.get(`/nps/${id}`)
}

export async function duplicateNPS(survey) {
    const surveyDuplicate = {
        ...survey
    }
    // Removing unnecessary fields
    delete surveyDuplicate.id
    delete surveyDuplicate.created_at
    delete surveyDuplicate.updated_at
    surveyDuplicate.description = `${surveyDuplicate.description} (1)`
    // Creating the NPS
    await postNPS(surveyDuplicate)
}

export async function postNPS(survey) {
    const surveyOnly = {
        ...survey
    }
    delete surveyOnly['questions']
    // Create NPS
    await instance.post(`/nps/`, survey).then(async response=>{
        if (response.status === 200) {
            const npsId = response.data[0] && response.data[0].id
            if (npsId) {
                // Create each question
                for (let i = 0; i < survey.questions.length; i++) {
                    const question = survey.questions[i]
                    question.nps = [
                        {
                            nps_group_id: npsId,
                            order: i + 1,
                            weight: question.weight
                        }
                    ]
                    await postQuestion(question, survey, i)
                }
            }
        } else {
            throw response
        }
    })

}

export async function putNPS(survey) {
    const surveyOnly = {
        ...survey
    }
    delete surveyOnly['questions']
    // Create NPS
    const surveyId = survey.id
    const surveyPost = {
        ...survey
    }
    delete surveyPost.id
    delete surveyPost.created_at
    delete surveyPost.questions
    await instance.put(`/nps/${surveyId}`, surveyPost).then(async (response) =>{
        if (response.status === 200) {
            const npsId = response.data[0] && response.data[0].id
            for (let i = 0; i < survey.questions.length; i++) {
                const question = survey.questions[i]
                question.nps = [
                    {
                        nps_group_id: npsId,
                        order: i + 1,
                        weight: question.weight
                    }
                ]            
                if (question.fresh) {
                    await postQuestion(question, survey, i)
                } else {
                    await putQuestion(question, survey, i)
                }
            }
        } else {
            throw response
        }
    })

}

export async function putQuestion(question, survey, index) {
    const response = await instance.put(`/nps/question/${question.id || ''}`, question)
    if (response.status !== 200) {
        throw response
    }
}

export async function postQuestion(question, survey, index) {
    const response = await instance.post(`/nps/question`, question)
    if (response.status !== 200) {
        throw response
    }
}
