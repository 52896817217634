import { SIEBEL_HISTORIC_LIST } from "../actions"

const defaultState = {
    list: null
}

export function siebel(currentState = defaultState, action) {
    switch (action.type) {
        case SIEBEL_HISTORIC_LIST:
            return action
            
        default:
            return currentState
    }
}