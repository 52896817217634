import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import reducer from './reducers'

const composeEnhancers =
	typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
				trace: true,
			})
		: compose

export const history = createBrowserHistory()

const store = createStore(
	reducer(history),
	composeEnhancers(applyMiddleware(thunk, routerMiddleware(history)))
)

window.store = store

export default ({ children }) => <Provider store={store}>{children}</Provider>
