import axios from 'axios'
//import Bowser from "bowser"
import { env } from '../env.js'
const instance = axios.create({
    baseURL: `${env.REACT_APP_API_URL}/api/v1`,
    headers: {
        'Content-Type': 'application/json',
        /*"browser-ra": JSON.stringify(Bowser.getParser(window.navigator.userAgent)),*/
        "Access-Control-Max-Age": 600
    }
});

instance.interceptors.request.use (
    config => {
      const token = localStorage.getItem('token')
      if (token) config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    error =>  Promise.reject (error)
)

export default instance