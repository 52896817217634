import {getEmail,postEmail,putEmail} from '../../api/email'
import {history} from '../'
export const EMAIL_CREATE = 'EMAIL_CREATE',
    EMAIL_UPDATE = 'EMAIL_UPDATE',
    EMAIL_LIST = 'EMAIL_LIST'

export function emailList() {
    return dispatch => {

        getEmail().then(response => {
            dispatch({
                type: EMAIL_LIST,
                list: response.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401){
                history.push('/login')
            }
        })
    }
}

export function emailCreate(description,settings,watson,activated,xThis) {
    return dispatch => {
        postEmail(description,settings,watson,activated).then(response => {
            dispatch({
                type: EMAIL_CREATE,
                list: response.data
            })
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(t.notifyCreate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error.response)
        })
    }
}


export function emailUpdate(id, description,settings,watson,activated, xThis) {
    return dispatch => {

        putEmail(id, description,settings,watson,activated).then(response => {
            dispatch({
                type: EMAIL_UPDATE,
                list: response.data
            })
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(t.notifyUpdate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error.response)
        })
    }
}