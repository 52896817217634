import instance from './instance'

export function getFaq(id = '') {
    return instance.get(`/settings/faq/${id}`)
}

export function postFaq(data) {
    return instance.post(`/settings/faq/`, data)
}

export function putFaq(data) {
    return instance.put(`/settings/faq/`, data)
}
