import {getChannels} from '../../api/channel'
import {history} from '../'
export const CHANNEL_LIST = 'CHANNEL_LIST'

export function channelList() {
    return dispatch => {

        getChannels().then(response => {
            dispatch({
                type: CHANNEL_LIST,
                list: response.data
            })
        }).catch(error => {
            console.log(error.response)
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}