import {
    NPS_CREATE, NPS_CREATE_ERROR, NPS_CREATE_LOADING,
    NPS_LIST, NPS_LIST_LOADING, NPS_READ, NPS_READ_LOADING, NPS_UPDATE, NPS_UPDATE_LOADING,
} from '../actions';

function getInitialState() {
    return {
        item: null,
        readLoading: false,
        list: [],
        listLoading: false,
        createError: false,
        createLoading: false,
        updateLoading: false,
    };
}

export function nps(currentState = getInitialState(), action) {
    switch(action.type) {
        case NPS_CREATE_ERROR:
            return {
                ...currentState,
                createError: action.createError,
            };
        case NPS_CREATE_LOADING:
            return {
                ...currentState,
                createLoading: action.createLoading
            };
        case NPS_CREATE:
            return {
                ...currentState,
                list: currentState.list ? currentState.list.concat(action.survey) : [ action.survey ],
            };
            //
        case NPS_LIST:
            return {
                ...currentState,
                list: action.list
            };
        case NPS_LIST_LOADING:
            return {
                ...currentState,
                listLoading: action.listLoading,
            };
            //
        case NPS_READ_LOADING:
            return {
                ...currentState,
                readLoading: action.readLoading,
            };
        case NPS_READ:
            return {
                ...currentState,
                item: action.item
            };
            //
        case NPS_UPDATE:
            if(action.item) {
                return {
                    ...currentState,
                    list: currentState.list.map(x => x?.id === action?.item?.id ? action.item : x)
                };
            }
            return {
                ...currentState,
            };
        case NPS_UPDATE_LOADING:
            return {
                ...currentState,
                updateLoading: action.updateLoading,
            };
        default:
            return currentState;
    }
}
