import {getDistribuition, postDistribuition, putDistribuition} from '../../api/distribuition'
import {history} from '../'
export const DISTRIBUITION_CREATE = 'DISTRIBUITION_CREATE',
    DISTRIBUITION_UPDATE = 'DISTRIBUITION_UPDATE',
    DISTRIBUITION_LIST = 'DISTRIBUITION_LIST';

export function distribuitionList() {
    return dispatch => {
        getDistribuition().then(response => {
            dispatch({
                type: DISTRIBUITION_LIST,
                list: response.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}

export function distribuitionCreate(data, xThis) {
    return dispatch => {
        postDistribuition(data).then(response => {
            dispatch({
                type: DISTRIBUITION_CREATE,
                list: response.data
            })
            const {enqueueSnackbar, t} = xThis.props
            enqueueSnackbar(t.notifyCreate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error.response)
        })
    }
}


export function distribuitionUpdate(data, xThis) {
    return dispatch => {
        putDistribuition(data).then(response => {
            if (xThis) {
                const {enqueueSnackbar} = xThis.props
                enqueueSnackbar('Distribuição atualizado com sucesso!', {variant: 'success'})
                xThis._dialog && xThis._dialog.handleClickOpen()
            }
            dispatch({
                type: DISTRIBUITION_LIST,
                list: JSON.parse(data)
            })

        }).catch(error => {
            console.log('err', error)
        })
    }
}
