import { UPDATE_FONTSIZE, DEFAULT_FONTSIZE } from '../actions/fontSize'

const INITIAL_STATE = {
    value: ''
}

export function fontSize(currentState = INITIAL_STATE, action) {
    switch (action.type) {
        case UPDATE_FONTSIZE:
            return { ...currentState, value: action.value }
        case DEFAULT_FONTSIZE:
            return { ...currentState, value: 100 }
        default:
            return currentState
    }
}