import {
    WATSON_CREATE,
    WATSON_LIST,
    WATSON_ERROR,
    WATSON_UPDATE
} from "../actions";

export function watson(currentState = {}, action) {
    switch (action.type) {
        case WATSON_CREATE:
            return {
                type: action.type,
                list: currentState.list ? currentState.list.concat(action.list) : [action.list]
            }
        case WATSON_LIST:
            return currentState = action
        case WATSON_UPDATE:
            return {
                type: action.type,
                list:  currentState.list.map(current => current.id  === action.list.id ? action.list : current)
            }
        default:
            return currentState
    }
}