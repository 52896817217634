import {getFlow,postFlow,postTemplate,getTemplate} from '../../api/flow'
import {history} from '../'
export const FLOW_CREATE = 'FLOW_CREATE',
    FLOW_LIST = 'FLOW_LIST',
    TEMPLATE_CREATE = 'TEMPLATE_CREATE',
    TEMPLATE_LIST = 'TEMPLATE_LIST'

export function flowList() {
    return dispatch => {
        getFlow().then(response => {
            dispatch({
                type: FLOW_LIST,
                flow: response.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401){
                history.push('/login')
            }
        })
    }
}
export function templateList() {
    return dispatch => {
        getTemplate().then(response => {
            dispatch({
                type: TEMPLATE_LIST,
                template: response.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401){
                history.push('/login')
            }
        })
    }
}

export function flowCreate(description,type,start,status,crmTable,flows,xThis) {
    return dispatch => {
        postFlow(description,type,start,status,crmTable,flows).then(response => {
            dispatch({
                type: FLOW_CREATE,
                flow: response.data
            })
            const {enqueueSnackbar} = xThis.props
            enqueueSnackbar('Flow cadastrado com sucesso!', {variant: 'success'})
            history.push('/flow')
        }).catch(error => {
            console.log('err', error.response)
        })
    }
}

export function templateCreate(description,type,daysOfWeek,start,minSend,maxSend,crmTable,flows,xThis) {
    return dispatch => {
        postTemplate(description,type,daysOfWeek,start,minSend,maxSend,crmTable,flows).then(response => {
            dispatch({
                type: TEMPLATE_CREATE,
                template: response.data
            })
            const {enqueueSnackbar} = xThis.props
            enqueueSnackbar('Template cadastrado com sucesso!', {variant: 'success'})
            history.push('/flow')
        }).catch(error => {
            console.log('err', error)
        })
    }
}