import instance from './instance'

export function getCampaign(id = '', page) {
    return instance.get(`/campaign/${id}?page=${page}`)
}

export function getCampaignShortner(id = '') {
    return instance.get(`/campaign/createshortlink/${id}`)
}

export function getContact(id = '', contactId = '', page, limit = 50) {
    return instance.get(`/campaign/${id}/contacts/${contactId}?page=${page}&limit=${limit}`)
}

export function getContactSearch(search) {
    return instance.get(`/campaign/contacts?search=${search}`)
}

export function postCampaign(formData) {
    return instance.post(`/campaign/`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export function putCampaign(id, activated) {
    return instance.put(`/campaign/`, {id, activated})
}
