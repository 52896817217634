import { LocalizationProvider } from '@material-ui/pickers'
import MomentUtils from '@material-ui/pickers/adapter/moment'
import * as Sentry from '@sentry/react'
import moment from 'moment'
import 'moment/locale/pt-br'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import ReactDOM from 'react-dom'
import Loadable from 'react-loadable'
import I18n from 'redux-i18n'
import Loader from './components/parts/loader/loader'
import { translations } from './locale/translations'
import StoreProvider from './store'

window.env = {}

//import App from './app'
const App = Loadable({ loader: () => import('./app'), loading: Loading })
function Loading({ error }) {
	if (error) {
		return error
	} else {
		return <Loader />
	}
}

if (process.env.NODE_ENV !== 'development') {
	Sentry.init({
		dsn: 'https://95f96c6e8b47538311eff57e5bfda453@o1145570.ingest.sentry.io/4505761667481600',
		integrations: [
			new Sentry.BrowserTracing({
				// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
				//tracePropagationTargets: ["localhost", `${env.REACT_APP_API_URL}`],
				routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
			}),
			new Sentry.Replay(),
		],
		// Performance Monitoring
		tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
		// Session Replay
		replaysSessionSampleRate: 0.2, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 0.2, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	})
}
moment.locale('pt-br')
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true

ReactDOM.render(
	<StoreProvider>
		<I18n translations={translations} initialLang="pt-Br">
			<LocalizationProvider
				dateAdapter={MomentUtils}
				locale={`pt-br`}
				dateLibInstance={moment}
			>
				<DndProvider backend={HTML5Backend}>
					<App />
				</DndProvider>
			</LocalizationProvider>
		</I18n>
	</StoreProvider>,
	document.getElementById('root')
)
