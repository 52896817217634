import { getSoulHistoric } from '../../api/soul'
import moment from "moment"
import {history} from '../'

export const SOUL_HISTORIC_LIST = 'SOUL_HISTORIC_LIST'

export function SoulHistoricList(data) {
    return (dispatch, getState) => {

        getSoulHistoric(data).then(response => {
            const list = []

            response.data.map(x => {
                let contrato = getState().protocolSelect.customers[0].data.contratos.filter(c => c.familia.filter(f => f.matricula === x.matricula_pet).length > 0)[0]
                let pet = contrato.familia.filter(f => f.matricula === x.matricula_pet)[0]
                x.data.map(y => {
                    list.push({...y,contrato: contrato.contrato, pet: `${x.matricula_pet} - ${pet.nome}`, matricula_pet: x.matricula_pet,date: `${moment(y.inicio, "DD/MM/YYYY HH:mm:ss").isValid() ? moment(y.inicio, "DD/MM/YYYY HH:mm:ss").format('DD/MM/YYYY') : '-' }`})
                })

            })
            dispatch({
                type: SOUL_HISTORIC_LIST,
                list: list.sort((a, b) => b.codigo - a.codigo)
            })
        }).catch(error => {
            console.log(error)
            const status = error.response.status
            if (status === 400) {
                console.error('soul.js: ', error.response.data.error)
                dispatch({
                    type: SOUL_HISTORIC_LIST,
                    error: error.response.data.error
                })
            }
            if (status === 401) {
                history.push('/login')
            }
        })
    }
}