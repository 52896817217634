import {
    EMAILSERVICE_CREATE,
    EMAILSERVICE_LIST,
    EMAILSERVICE_UPDATE
} from "../actions";

export function emailService(currentState = {}, action) {
    switch (action.type) {
        case EMAILSERVICE_CREATE:
            return {
                type: action.type,
                list: currentState.list ? currentState.list.concat(action.list) : [action.list]
            }
        case EMAILSERVICE_LIST:
            return currentState = action
        case EMAILSERVICE_UPDATE:
            return {
                type: action.type,
                list:  currentState.list.map(current => current.id  === action.list.id ? action.list : current)
            }
        default:
            return currentState
    }
}