import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import { auth } from './auth'
import { campaign } from './campaign'
import { department } from './department'
import { nps } from './nps'
import { whatsApp } from './whatsApp'
import { distribuition } from './distribuition'
import { config } from './config'
import { contact } from './contact'
import { crmBusiness, crmTemplate } from './crm'
import { watson } from './watson'
import { horary } from './horary'
import { group, groupSelect } from './group'
import { handle } from './handle'
import { user, userStatus } from './user'
import { sms } from './sms'
import { dash } from './dash'
import { breaks } from './break'
import { landingPage } from './landingPage'
import { i18nState } from "redux-i18n"
import {
    protocolAll,
    protocolAllBot,
    protocolAllHuman,
    protocolClosed,
    protocolClosedBot,
    protocolClosedHuman,
    protocolCount,
    protocolCountType,
    protocolDepartment,
    protocolVideoVoiceQueue,
    protocolVideoVoiceRun,
    protocolOpen,
    protocolOpenBot,
    protocolOpenHuman,
    protocolOpenWaiting,
    protocolOpenWaitingClient,
    protocolOpenWaitingOperator,
    protocolNPS,
    protocolNPSBot,
    protocolNPSHuman,
    protocolSelect,
    protocolFlow,
    protocolFlowBot,
    protocolFlowHuman,
    protocolLiveStream
} from './protocol'
import { email } from './email'
import { emailMarketing } from './emailmarketing'
import { emailService } from './emailService'
import { emailIMap } from './emailIMap'
import { instagram } from './instagram'
import { tab } from './tab'
import { sla, unitTime } from './sla'
import { faq } from './faq'
import { blockedWord } from './blockedWord'
import { channel } from './channel'
import { category } from './category'
import { flow, template } from './flow'
import { chat } from './chat'
import { fontSize } from './fontSize'
import { ticket } from './ticket'
import { siebel } from './siebel'
import { soul } from './soul'
import {flowV2} from './flowv2'
import {workspace, workflow} from './workflow'

export default (history) => combineReducers({
    i18nState,
    router: connectRouter(history),
    auth,
    campaign,
    category,
    channel,
    chat,
    config,
    contact,
    blockedWord,
    breaks,
    dash,
    distribuition,
    department,
    email,
    emailService,
    emailMarketing,
    emailIMap,
    faq,
    tab,
    horary,
    template,
    handle,
    instagram,
    flow,
    group,
    groupSelect,
    crmBusiness,
    crmTemplate,
    protocolOpen,
    protocolAll,
    protocolAllBot,
    protocolAllHuman,
    protocolOpenBot,
    protocolOpenHuman,
    protocolOpenWaiting,
    protocolFlow,
    protocolFlowBot,
    protocolFlowHuman,
    protocolOpenWaitingClient,
    protocolOpenWaitingOperator,
    protocolLiveStream,
    protocolNPS,
    protocolNPSBot,
    protocolNPSHuman,
    protocolClosed,
    protocolClosedBot,
    protocolCountType,
    protocolClosedHuman,
    protocolVideoVoiceQueue,
    protocolVideoVoiceRun,
    protocolCount,
    protocolDepartment,
    protocolSelect,
    sms,
    sla,
    unitTime,
    user,
    userStatus,
    watson,
    whatsApp,
    landingPage,
    nps,
    fontSize,
    ticket,
    siebel,
    soul,
    flowV2,
    workspace,
    workflow
})