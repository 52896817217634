import {
    DISTRIBUITION_CREATE,
    DISTRIBUITION_LIST,
    DISTRIBUITION_UPDATE
} from "../actions";

export function distribuition(currentState = {}, action) {
    switch (action.type) {
        case DISTRIBUITION_CREATE:
            return {
                type: action.type,
                list: currentState.list ? currentState.list.concat(action.list) : [action.list]
            }
        case DISTRIBUITION_LIST:
            return currentState = action
        case DISTRIBUITION_UPDATE:
            return {
                type: action.type,
                list:  currentState.list.map(current => current.id  === action.list.id ? action.list : current)
            }
        default:
            return currentState
    }
}