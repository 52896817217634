import {getFaq,postFaq,putFaq} from '../../api/faq'
import {history} from '../'

export const FAQ_CREATE = 'FAQ_CREATE',
    FAQ_UPDATE = 'FAQ_UPDATE',
    FAQ_LIST = 'FAQ_LIST'

export function faqList() {
    return dispatch => {
        getFaq().then(response => {
            dispatch({
                type: FAQ_LIST,
                list: response.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401){
                history.push('/login')
            }
        })
    }
}

export function faqCreate(tag, department, text, activated,xThis) {
    return dispatch => {
        postFaq(tag, department, text, activated).then(response => {
            dispatch({
                type: FAQ_CREATE,
                list: response.data
            })
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(t.notifyCreate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error.response)
        })
    }
}


export function faqUpdate(id, tag, department, text, activated, xThis) {
    return dispatch => {
        putFaq(id, tag, department, text, activated).then(response => {
            dispatch({
                type: FAQ_UPDATE,
                list: response.data
            })
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(t.notifyUpdate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error.response)
        })
    }
}