import {
    GROUP_CREATE,
    GROUP_LIST,
    GROUP_UPDATE,
    GROUP_SELECT, GROUP_RESET, GROUP_MESSAGE, GROUP_ADDMEMBER, GROUP_REMOVEMEMBER, GROUP_SELECTUPDATE, GROUP_NOTE
} from "../actions";
import socket from "../../api/socket";
import moment from "moment";

export function group(currentState = {}, action) {
    switch (action.type) {
        case GROUP_CREATE:
            return {
                type: action.type,
                list: currentState.list ? currentState.list.concat(action.list) : [action.list]
            }
        case GROUP_LIST:
            return currentState = action
        case GROUP_UPDATE:
            return {
                type: action.type,
                list:  currentState.list.map(current => current.id  === action.list.id ? action.list : current)
            }
        default:
            return currentState
    }
}


export function groupSelect(currentState = {select: false, load: false}, action) {
    switch (action.type) {
        case GROUP_SELECT:
            return {
                ...action
            }
        case GROUP_RESET:
            currentState.id !== undefined && socket.emit('leave', `group_${currentState.id}`)
            return {select: false, ...action}
        case GROUP_MESSAGE:
            let date = moment(action.message.created_at, 'DD/MM/YYYY HH:mm:ss')
            return (currentState.id === action.message.group_id && !currentState.message.find(x => x.id === action.message.id)) ? {
                ...currentState,
                message: [...currentState.message, {
                    ...action.message,
                    created_at: date._isValid ? date.format('DD/MM/YYYY HH:mm:ss') : action.message.created_at
                }]
            } : currentState
        case GROUP_ADDMEMBER:
            let members = action.members.filter(x => !currentState.member.find(member => member.id === x.id))
            return (currentState.id === action.id) ? {
                ...currentState,
                member: [...currentState.member, ...members]
            } : currentState
        case GROUP_REMOVEMEMBER:
            return (currentState.id === action.id) ? {
                ...currentState,
                member: currentState.member.map(m => action.members.find(a => a.id === m.id) ?? m)
            } : currentState
        case GROUP_SELECTUPDATE:
            return {
                ...currentState,
                ...action
            }
        case GROUP_NOTE:
            return {
                ...currentState,
                note: Array.isArray(currentState.note) ? [...currentState.note, action.note] : [action.note]
            }
        default:
            return currentState
    }
}