import {getWatson,postWatson,putWatson} from '../../api/watson'
import {history} from '../'
export const WATSON_CREATE = 'WATSON_CREATE',
    WATSON_UPDATE = 'WATSON_UPDATE',
    WATSON_ERROR = 'WATSON_ERROR',
    WATSON_LIST = 'WATSON_LIST'

export function watsonList() {
    return dispatch => {

        getWatson().then(response => {
            dispatch({
                type: WATSON_LIST,
                list: response.data
            })
        }).catch(error => {
            const status = error.response.status
            if (status === 401){
                history.push('/login')
            }
        })
    }
}

export function watsonCreate(description,settings,activated,xThis) {
    return dispatch => {
        postWatson(description,settings,activated).then(response => {
            dispatch({
                type: WATSON_CREATE,
                list: response.data
            })
            const {enqueueSnackbar, t} = xThis.props
            enqueueSnackbar(t.notifyCreate, {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            const {enqueueSnackbar} = xThis.props
            enqueueSnackbar( error.response.data.error, {variant: 'error'})
        })
    }
}


export function watsonUpdate(id, description,settings,activated, xThis) {
    return dispatch => {

        putWatson(id, description,settings,activated).then(response => {
            dispatch({
                type: WATSON_UPDATE,
                list: response.data
            })
            const {enqueueSnackbar,t} = xThis.props
            enqueueSnackbar(t.notifyUpdate , {variant: 'success'})
            xThis._dialog.handleClickOpen()
        }).catch(error => {
            console.log('err', error.response)
        })
    }
}