import instance from './instancev2'

export function getDistribuition(id = '') {
    return instance.get(`/settings/distribuition/${id}`)
}

export function postDistribuition(data) {
    return instance.post(`/settings/distribuition/`, data)
}

export function putDistribuition(data) {
    return instance.put(`/settings/distribuition/`, data)
}

