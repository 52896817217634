import React from "react";
import {
    Dialog as DialogStyle,
    DialogTitle as DialogTitleStyle,
    DialogContent as DialogContentStyle,
    DialogContentText as DialogContentTextStyle,
    IconButton as IconButtonStyle,
    OutlinedInput as OutlinedInputStyle,
    Button as ButtonStyle,
    LinearProgress as LinearProgressStyle,
    FormControl as FormControlStyle,
    FormHelperText as FormHelperTextStyle,
} from '@material-ui/core'
import SelectMultipleStyle from 'react-select';
import styled, {css} from 'styled-components'
import FormControlLabelStyle from '@material-ui/core/FormControlLabel'
import CheckboxStyle from '@material-ui/core/Checkbox'

export const Quality = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    height: fit-content;
    margin: 8px 0;
`

export const Status = styled.div`
  	width: 8px;
  	height: 8px;
  	border-radius: 50%;
  	&.GREEN {
  	  background-color: ${({theme}) => theme.online};
  	}
  	&.RED {
  	  background-color: ${({theme}) => theme.error};
  	}
  	&.YELLOW {
  	  background-color: ${({theme}) => theme.pause};
  	}
  	&.NEUTRO {
  	  background-color: ${({theme}) => theme.idle};
  	}
`

export const Dialog = styled(DialogStyle)`
    && {
        .dialog_container {
            width: 600px;
        }
    }
`

export const DialogTitle = styled(DialogTitleStyle)`
    && {
        &.dialog_title {
            color: #190C2E;
            &.red {
                h2 {
                    >span {
                        color: #CC3537;
                    }
                }
            }
        }
    }
`

export const DialogContent = styled(DialogContentStyle)`
    && {
         padding: 0 24px 16px;
    }
`

export const DialogText = styled.div`
    margin-bottom: 12px;
    p {
        &+p {
            margin-top: 6px;
        }
    }
`

export const DialogContentText = styled(DialogContentTextStyle)``

export const IconButton = styled(IconButtonStyle)``

export const LinearProgress = styled(LinearProgressStyle)`
    && {
        background: #E1DEE5;
        height: 4px;
        && > div {
            background: #E83CDB;
        }
    }
`

export const Button = styled(ButtonStyle)`
    && {
        background: #FFFFFF;
        color: #989898;

        svg {
            color: inherit;
            height: 14px;
            max-width: 14px;
            margin-right: 6px;
        }

        &:hover {
            background: #ccccccA6;
        }

        &:not(.next):not(.back):not(.add):not(.addPrivilege):not(.addMessage) {
            border: 1px solid #989898;
        }
        
        &.add_channel {
            color: #7D3CE8;

            &:hover {
                color: #4c2092;
            }
        }

         &.desatived_department {
            background: #D33434;
            color: #FFFFFF;
            width: 185px;
            border: none !important;
            margin-left: 16px;
            &:hover {
                background: #cd2721;
                color: FFFFFF
            }
        }

        &.next {
            background: #7D3CE8;
            color: #FFFFFF;

            &:hover {
                background: #7D3CE8A6;
                color: #FFFFFF;
            }
            &:disabled {
                color: rgba(0, 0, 0, 0.26);
                border: none;
                background-color: rgba(0, 0, 0, 0.12);
                cursor: initial;
            }
        }

        &.controlActives {
            width: 100px;
        }

        &.add {
            background: #7ACDD4;
            color: #FFFFFF;

            &:hover {
                background: #7ACDD4A6;
            }
        }
        &.addPrivilege {
            background: #fff;
            color: #7D3CE8;
        }

        &.back {
            border-radius: 3px;
            background: #F6F7FC;
            color: #7D3CE8;
            padding: 6px 16px;

            &:hover {
                background: #F6F7FCA6;
            }
        }
        &.addMessage {
            border-radius: 3px;
            border: none;
            background: #F6F7FC;
            color: #6B6871;
            padding: 6px 16px;
            svg {
                color: #7D3CE8;
            }
            &:hover {
                background: #F6F7FCA6;
            }
        }
        &.online {
            &:disabled {
                display: none;
                &+span {
                      display: none;
                }
            }
            
        }
        &.active {
            &.online {
                font-weight: bold;
                background-color: ${({ theme }) => `${theme.online}26`};
                border-color: ${({ theme }) => `${theme.online}26`};
                color: ${({ theme }) => theme.online};
            }

            &.crm {
                font-weight: bold;
                background-color: ${({ theme }) => `${theme.primary}26`};
                border-color: ${({ theme }) => `${theme.primary}26`};
                color: ${({ theme }) => theme.primary};
            }

            &.separate {
                font-weight: bold;
                background-color: #00c2b726;
                border: 1px solid #00c2b726;
                color: #00c2b7;
            }

            &.offline {
                font-weight: bold;
                background-color: ${({ theme }) => `${theme.offline}26`};
                border-color: ${({ theme }) => `${theme.offline}26`};
                color: ${({ theme }) => theme.offline};
            }

            &.whatsapp {
                color: #53cd5e;
                font-weight: bold;
                background-color: #53cd5e26;
                border-color: #53cd5e26;
            }

            &.chat {
                color: #7e57c2;
                font-weight: bold;
                background-color: #7e57c226;
                border-color: #7e57c226;
            }

            &.sms {
                color: #26c6da;
                font-weight: bold;
                background-color: #26c6da26;
                border-color: #26c6da26;
            }

            &.email {
                color: #ffca28;
                font-weight: bold;
                background-color: #ffca2826;
                border-color: #ffca2826;
            }

            &.messenger {
                color: #007ff7;
                font-weight: bold;
                background-color: #007ff726;
                border-color: #007ff726;
            }

            &.phone {
                color: #cd2721;
                font-weight: bold;
                background-color: #cd272126;
                border-color: #cd272126;
            }

            &.video {
                color: #108b7f;
                font-weight: bold;
                background-color: #108b7f26;
                border-color: #108b7f26;
            }

            &.store, &.landing_page {
                color: #4c2092;
                font-weight: bold;
                background-color: #4c209226;
                border-color: #4c209226;
            }

            &.instagram_chat {
                color: #c73184;
                font-weight: bold;
                background-color: #c7318426;
                border-color: #c7318426;
            }

            &.telegram {
                color: #0384c5;
                font-weight: bold;
                background-color: #0384c526;
                border-color: #0384c526;
            }
            &.reclame_aqui {
                color: #90b822;
                font-weight: bold;
                background-color: #90b82226;
                border-color: #90b82226;
            }
            &.rcs {
                color: #0323c5;
                font-weight: bold;
                background-color: #0323c526;
                border-color: #0323c526;
            }
            &.slack {
                color: #4a164b;
                font-weight: bold;
                background-color: #4a164b26;
                border-color: #4a164b26;
            }
            &.consumidor {
                color: #75bd36;
                font-weight: bold;
                background-color: #75bd3626;
                border-color: #75bd3626;
            }
            &.google_chat {
                color: #00AC47;
                font-weight: bold;
                background-color: #00AC4726;
                border-color: #00AC4726;
            }
            &.youtube {
                color: #FF0000;
                font-weight: bold;
                background-color: #FF000026;
                border-color: #FF000026;
            }
            &.inbot_chat {
                color: #94b6fd;
                font-weight: bold;
                background-color: #94b6fd26;
                border-color: #94b6fd26;
            }
        }
    }
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.add {
        margin-bottom: 12px;
        button  {
            width: calc(50% - 24px);
        }
    }
    &.end {
        justify-content: flex-end;
    }
    &.end_multi {
        justify-content: flex-end;
        gap: 6px;
    }
`

export const SelectMultiple = styled(SelectMultipleStyle).attrs((props) => ({
    ...props,
    theme: (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            neutral0: "#FFF",
            primary: "#7D3CE8",
            primary25: "rgba(125,60,232,.25)",
            primary50: "rgba(125,60,232,.5)",
            primary75: "rgba(125,60,232,.75)"
        }
    }),
    styles: {
        control: (data) => ({...data, width: props.widthControl ?? 220}),
        option: (base) => ({
			...base,
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center'
		}),
        menuPortal: (base) => ({...base, zIndex: 9999, backgroundColor: '#FFF'}),
        ...props.style
    },
    placeholder: props.placeholder ?? "Digite ou selecione",
    maxMenuHeight: 220,
    menuPlacement: "auto",
    menuPortalTarget: document.getElementById('root'),
}))`
    && {
        ${({error}) => error && css`>div {border-color: #F44336;}`}
    }
`

export const InputContainer = styled(FormControlStyle)`
    display: flex;
    flex-direction: column;
    width: 100%;
    && {
        ${({error}) => error && css`
            label {
                color: #F44336;
            }
        `}
    }
    .horizontal {
        display: flex;
        
    }
    .PhoneInput--focus {
        .PhoneInputInput {
            outline-color: #7D3CE8;
        }
    }
    .PhoneInputInput {
        padding: 9px 15px;
    }
    > ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        grid-gap: 10px;
        margin-bottom: 10px;
    }
    >div.label {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }
    >div {
        &.channelList {
            display: flex;
            grid-gap: 12px;
            flex-wrap: wrap;
        }
        &.selectCRM {
          display: flex;
          >div {
              >div {
                  width: 100%;
              }
              flex: 1 0;
              &+div{
                  margin-left: 10px;
                  flex: 1.5 0;
              }
          }
        }
        > span {
            margin: 0 12px;
            color: #48454C;
        }
        &.addNumber {
            display: flex;
            justify-content: space-between;
            > button {
                margin-left: 16px;
            }
            > div {
                width: 100%;
            }
        }
    }
    > label, > p {
        margin-bottom: 10px;
    }
    &.content {
        min-width: fit-content;
        width: fit-content;
        > label {
            font-size: 14px;
            font-weight: bold;
        }
        >p {
            font-size: 14px;
            margin-bottom: 0;
        }
    }
    &.options {
        > label {
            margin-bottom: 0;
        }
    }
    .add {
        align-self: flex-start;
    }
    &+&& {
        flex: 1 0;
    }
`

export const FormHelperText = styled(FormHelperTextStyle)`
    && {
        color: #F44336;
    }
`
export const FaqContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ul {
        list-style: none;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 10px;
        display: flex;
        grid-gap: 10px;
    }
    >${Button} {
        border-color: #FFFFFF;
        margin-top: 10px;
    }
    margin: 10px 0;
`
export const OutlinedInput = styled(OutlinedInputStyle).attrs((props) => ({
    ...props,
    labelWidth: 0,
    shrink: true
}))`
    && {
        width: ${({width}) => width ?? '100%'};
        &.cardDistribuition {
            font-size: 12px;
            color: #6B6871;  
            font-weight: 500;
        }
      &.sla_geral {
        width: 200px;
        padding-right: 0;
        >input {
          &+div {
            min-width: 120px;
          }
        }
      }
    }
`
export const Text = styled.h4`
    color: #48454C;
    font-weight: normal;
    font-size: 20px;
    margin: 24px 0;
`

export const FilesContainer = styled.div`
    color: ${props => props.theme.bg};
    display: flex;
    padding: 16px 0;
    flex-direction: column;
    align-items: flex-end;
    img {
        width: 100%;
    }
    ul {
        display: flex;
        list-style: none;
        width: 100%;
        flex-wrap: wrap;
        >li {
            display: flex;
            flex-direction: column;
            width: 7.5rem;
            margin-right: 1rem;
            margin-top: .5rem;
            >div {
                &:first-child{
                    background: rgba(77,188,172,0.5);
                    color: ${props => props.theme.bg};
                    height: 40px;
                    font-size: 1.2rem;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                }
                &:last-child{
                    background-color: ${props => props.theme.bg};
                    color: ${props => props.theme.text};
                    display: flex;
                    padding: .35rem;
                    justify-content: space-between;
                    > p {
                        max-width: 80%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    svg {
                        width: 1rem;
                        height: 1rem;
                        cursor: pointer;
                        stroke: ${props => props.theme.text};
                    }
                }
            }
        }
    }
`


export const InputFile = styled.input`
    display: none;
`


export const FormControlLabel = styled(FormControlLabelStyle)`
	&& {
      	font-size: 14px;
    }
`
export const Checkbox = styled(CheckboxStyle).attrs((props) => ({
    color: 'primary',
    size: 'small',
    ...props
}))``