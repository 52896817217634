import instance from './instancev2'
import instanceV1 from './instance'
import moment from 'moment'

export function putProtocolClose(id) {
	return instance.put(`/protocol/closed/${id}`)
}

export function getCountDepartmentProtocol(type, departmentId, channelId) {
	return instance.get(`/protocol/count_department?id_support=${type}&department_id=${JSON.stringify(
		departmentId
	)}&id_channel=${JSON.stringify(channelId)}
    `)
}

export function getCountSupportProtocol(type, departmentId, channelId, date) {
	return instance.get(`/protocol/count_support?id_support=${type}&department_id=${JSON.stringify(
		departmentId
	)}&id_channel=${JSON.stringify(channelId)}
    `)
}

export function getCountChannelProtocol(type, departmentId, channelId, ) {
	return instance.get(`/protocol/count_channel?id_support=${type}&department_id=${JSON.stringify(
		departmentId
	)}&id_channel=${JSON.stringify(channelId)}
    `)
}

export function getCountStatusProtocol(type, departmentId, channelId, date, user) {
	return instance.get(`/protocol/count_status?id_support=${type}
	&department_id=${JSON.stringify(departmentId)}
	${date ? `&date=${JSON.stringify(date)}` : ''}&id_channel=${JSON.stringify(channelId)}
	${user ? `&user=${JSON.stringify(user)}` : ''}
    `)
}

export function getProtocol({
	department,
	user,
	date,
	channel,
	support,
	status,
	page,
	orderby,
	orderbychannel,
	sla,
	search,
	limit,
}) {
	return instance.get(
		`/protocol?department=${JSON.stringify(department)}${orderbychannel ? `&orderbychannel=${JSON.stringify(orderbychannel)}` : ''}${orderby ? `&orderby=${orderby}` : ''}${
			user ? `&user=${JSON.stringify(user)}` : ''
		}${date ? `&date=${JSON.stringify(date.find(x => !x) ? [moment().subtract(5, 'years').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')] : date)}` : ''}&channel=${JSON.stringify(
			channel
		)}&support=${JSON.stringify(support)}&status=${JSON.stringify(status)}&sla=${JSON.stringify(sla)}&page=${page}&limit=${
			//limit ?? '20'
			50
		}${search ? `&search=${search}` : ''}`
	)
}

export function getProtocolById(id) {
	return instance.get(`/protocol/${id}`)
}

export function getProtocolByIdHistory(id) {
	return instance.get(`/protocol/history/${id}`)
}

export function getProtocolByIdCustomer(id) {
	return instance.get(`/protocol/customer/${id}`)
}

export function postProtocol(data) {
	return instance.post(`/protocol`, data)
}

export function postProtocolCallMe(protocol, data) {
	return instance.post(`/protocol/${protocol}/derivate_voice`, data)
}

export function postProtocolCallSchedule(data) {
	return instanceV1.post(`/weon/calls/schedule`, data)
}

export function getProtocolCustomer(protocol, customer) {
	return instance.get(`/protocol/${protocol}/customer/${customer}`)
}

export function putProtocolAddCustomer(data) {
	return instance.put(`/protocol/customer`, data)
}

export function putProtocolUpdateCustomer(protocol, customer, data) {
	return instance.put(`/protocol/${protocol}/customer/${customer}`, data)
}

export function postProtocolOcorrence(data) {
	return instance.post(`/protocol/occorrence`, data)
}

export function postProtocoTab(data) {
	return instance.post(`/protocol/occorrence`, data)
}

export function postFinalizeTab(data) {
	return instance.post(`/protocol/tab`, data)
}

export function postMultiFinalizeTab(data) {
	return instance.put(`/protocol/closedall`, data)
}


export function protocolTicket(data) {
	return instance.post(`/protocolticket/link_ticket`, data)
}