import {
    PHASE_LIST,
    CREATE_PHASE,
    FINALIZE_TICKET,
    UPDATE_TICKET,
    DISABLE_PHASE,
    UPDATE_PHASE,
    FILTER_PHASE, UPDATE_PAGE_PHASE,
} from '../actions'

export function ticket(currentState = [], action) {
    switch (action.type) {
        case PHASE_LIST:
            return action
        case CREATE_PHASE:
            return  {...currentState, type: action.type, list: currentState.list ? [...currentState.list, ...action.list] : action.list}
        case UPDATE_PHASE:
            return  {...currentState, type: action.type, list: currentState.list.map(x => {
                    let currTicket = action.list.find(y => y.id === x.id)
                    if (currTicket) {
                        return currTicket
                    } else {
                        return x
                    }
                })}
        case UPDATE_PAGE_PHASE:
            return { ...currentState, list: currentState.list.map(list => {
                    if (list.id === action.id) {
                        const currListTicket = list.ticket
                        action.status.map(key => {
                            action.list[key].tickets.map(ticket => {
                                if (!currListTicket[key].tickets.find(({id}) => id === ticket.id)){
                                    currListTicket[key].tickets.push(ticket)
                                }
                            })
                        })
                        return { ...list, ticket: currListTicket }
                    }else {
                        return list
                    }
                }) }
        case FILTER_PHASE:
            return {...currentState, filter: action.data}
        case FINALIZE_TICKET:
            return  {...currentState, type: action.type, list: currentState.list.map((x, index) => index === action.phase ? {...x, ticket: x.ticket.map(y => y.id === action.id ? {...y, closed: true} : y)} : x)}
        case DISABLE_PHASE:
                return {...currentState, list: currentState.list.filter(x => !action.list.find(y => y.id === x.id))}
        case UPDATE_TICKET:
            return { ...currentState, ...action }
        default:
            return currentState
    }
}