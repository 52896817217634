import instance from './instance'

export function getCategory(id = '') {
    return instance.get(`/ticket/phase${id !== '' ? `/${id}` : ''}`)
}

export function postCategory(description, department, sla, activated) {
    return instance.post(`/settings/tab/`, {description, status: 2, department, sla, activated})
}

export function putCategory(id, description, department, sla, activated) {
    return instance.put(`/settings/tab/`, {id, description, status: 2, department, sla, activated})
}
