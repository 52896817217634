import {
    FLOWV2_CREATE,
    FLOWV2_LIST,
    FLOWV2_UPDATE
} from "../actions/flowv2";

export function flowV2(currentState = {}, action) {
    switch (action.type) {
        case FLOWV2_CREATE:
            return {
                type: action.type,
                list: currentState.list ? [...currentState.list, action.flow] : [action.flow]
            }
        case FLOWV2_LIST:
            return currentState = action
        case FLOWV2_UPDATE:
            return {
                type: action.type,
                list: currentState.list.map(x => x.id  === action.flow.id ? {...x, ...action.flow} : x)
            }
        default:
            return currentState
    }
}