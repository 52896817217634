import {
    LANDINGPAGE_LIST,
    LANDINGPAGE_UPDATE,
    LANDINGPAGE_CREATE
} from "../actions";

export function landingPage(currentState = {}, action) {
    switch (action.type) {
        case LANDINGPAGE_CREATE:
            return {
                type: action.type,
                list: currentState.list ? currentState.list.concat(action.list) : [action.list]
            }
        case LANDINGPAGE_LIST:
            return currentState = action
        case LANDINGPAGE_UPDATE:
            return {
                type: action.type,
                list:  currentState.list.map(current => current.id  === action.list.id ? action.list : current)
            }
        default:
            return currentState
    }
}