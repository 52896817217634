import instance from './instance'

export function getDepartment(id = '') {
    return instance.get(`/settings/department/${id}`)
}

export function postDepartment(data) {
    return instance.post(`/settings/department/`, data)
}

export function putDepartment(data) {
    return instance.put(`/settings/department/`, data)
}

