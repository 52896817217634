import { CONFIG, CONFIG_UPDATE, UPDATE_CONFIG_PARTIAL } from '../actions'

export function config(currentState = {}, action) {
	switch (action.type) {
		case CONFIG:
			return action
		case CONFIG_UPDATE:
			return { ...currentState, ...action }
		case UPDATE_CONFIG_PARTIAL:
			return { ...currentState, ...action.payload }
		default:
			return currentState
	}
}
