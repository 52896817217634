import {
    CAMPAIGN_CREATE,
    CAMPAIGN_LIST,
    CAMPAIGN_UPDATE
} from "../actions";

export function campaign(currentState = {}, action) {
    switch (action.type) {
        case CAMPAIGN_CREATE:
            return {
                type: action.type,
                list: currentState.list ? [action.list, ...currentState.list] : [action.list]
            }
        case CAMPAIGN_LIST:
            return action.currentPage === 1 ?
                currentState = action : {
                    ...action,
                    list: [...currentState.list, ...action.list]
                }
        case CAMPAIGN_UPDATE:
            return {
                type: action.type,
                list:  currentState.list.map(current => current.campaignId  === action.list.campaignId ? action.list : current)
            }
        default:
            return currentState
    }
}