import instance from './instance'

export function getLandingPage(id= null) {
    return instance.get(`/landingpage/code${id ? `/${id}` : ''}`)
}


export function getLandingPageToken(id) {
    return instance.get(`/landingpage/code/token`)
}

export function postLandingPage(data) {
    return instance.post(`/landingpage/code`, data)
}

export function putLandingPage(id, data) {
    return instance.put(`/landingpage/code/${id}`, data)
}


export function getLandingPageImage(id = null) {
    return instance.get(`/landingpage/image${id ? `/${id}` : ''}`)
}


export function putLandingPageImage(data) {
    return instance.put(`/landingpage/image`, {data})
}

export function postLandingPageImage(id, data) {
    return instance.post(`/landingpage/image/${id}`, {data})
}