/* eslint-disable */
import {
  categoryProtocol,
  getMessage,
  getNote,
  getProtocolCount,
  getProtocolDeparment,
  getProtocolHistory,
  postMessage,
  postNote,
  postProtocolEmail,
  postProtocolPhone,
  postProtocolWhatsapp,
  putAssign,
  putDisable,
  putProtocol,
  putTabProtocol,
  putProtocolTransfer,
  statusProtocol,
  putPauseProtocol,
  putCpc,
} from "../../api/protocol";
import {
  getProtocol,
  getProtocolById,
  getProtocolCustomer,
  postProtocol,
  postProtocolOcorrence,
  putProtocolAddCustomer,
  putProtocolClose,
  postProtocoTab,
  putProtocolUpdateCustomer,
  postFinalizeTab,
  getProtocolByIdHistory,
  getProtocolByIdCustomer,
  postMultiFinalizeTab,
} from "../../api/protocolv2";
import { pathChannel } from "../../routes/path";
import socket from "../../api/socket";
import React from "react";
import { getCRMTemplate } from "../../api/crm";
import { groupList } from "./group";
import { history } from "../";
import { channelsData } from "../../components/parts/dialogv3/protocol/config";
import { env } from "../../env";
import { putTagProtocol, postTagProtocol } from "api/protocolTag";

export const EnumStatus = {
  WAITING: 1,
  AWAITING_CUSTOMER: 2,
  AWAITING_OPERATOR: 3,
  CLOSED: 4,
  VIDEO_VOICE_RUN: 5,
  VIDEO_VOICE_QUEUE: 6,
  FLOW: 7,
  NPS: 8,
  GROUP: 9,
  LIVE_STREAM: 10,
};

export const EnumSupport = {
  BOT: 1,
  HUMAN: 2,
};

export const PROTOCOL_COUNT = "PROTOCOL_COUNT",
  PROTOCOL_COUNTTYPE = "PROTOCOL_COUNTTYPE",
  PROTOCOL_COUNTDEPARTMENT = "PROTOCOL_COUNTDEPARTMENT",
  PROTOCOL_SELECT = "PROTOCOL_SELECT",
  PROTOCOL_SELECTUPDATE = "PROTOCOL_SELECTUPDATE",
  PROTOCOL_CUSTOMERUPDATE = "PROTOCOL_CUSTOMERUPDATE",
  PROTOCOL_RESET = "PROTOCOL_RESET",
  PROTOCOL_NOTE = "PROTOCOL_NOTE",
  PROTOCOL_ADDMESSAGE = "PROTOCOL_ADDMESSAGE",
  PROTOCOL_DISABLEIA = "PROTOCOL_DISABLEIA",
  PROTOCOL_MESSAGE = "PROTOCOL_MESSAGE",
  PROTOCOL_HISTORY = "PROTOCOL_HISTORY",
  PROTOCOL_OPEN = "PROTOCOL_OPEN",
  PROTOCOL_OPENUPDATE = "PROTOCOL_OPENUPDATE",
  PROTOCOL_OPENRESET = "PROTOCOL_OPENRESET",
  PROTOCOL_ALL = "PROTOCOL_ALL",
  PROTOCOL_ALLUPDATE = "PROTOCOL_ALLUPDATE",
  PROTOCOL_ALLRESET = "PROTOCOL_ALLRESET",
  PROTOCOL_ALLBOT = "PROTOCOL_ALLBOT",
  PROTOCOL_ALLBOTUPDATE = "PROTOCOL_ALLBOTUPDATE",
  PROTOCOL_ALLBOTRESET = "PROTOCOL_ALLBOTRESET",
  PROTOCOL_ALLHUMAN = "PROTOCOL_ALLHUMAN",
  PROTOCOL_ALLHUMANUPDATE = "PROTOCOL_ALLHUMANUPDATE",
  PROTOCOL_ALLHUMANRESET = "PROTOCOL_ALLHUMANRESET",
  PROTOCOL_CATEGORY = "PROTOCOL_CATEGORY",
  PROTOCOL_CATEGORYCHANGE = "PROTOCOL_CATEGORYCHANGE",
  PROTOCOL_CATEGORYUPDATE = "PROTOCOL_CATEGORYUPDATE",
  PROTOCOL_CATEGORYRESET = "PROTOCOL_CATEGORYRESET",
  PROTOCOL_OPENBOT = "PROTOCOL_OPENBOT",
  PROTOCOL_OPENBOTUPDATE = "PROTOCOL_OPENBOTUPDATE",
  PROTOCOL_OPENBOTRESET = "PROTOCOL_OPENBOTRESET",
  PROTOCOL_FLOWBOT = "PROTOCOL_FLOWBOT",
  PROTOCOL_FLOWBOTUPDATE = "PROTOCOL_FLOWBOTUPDATE",
  PROTOCOL_FLOWBOTRESET = "PROTOCOL_FLOWBOTRESET",
  PROTOCOL_OPENHUMAN = "PROTOCOL_OPENHUMAN",
  PROTOCOL_OPENHUMANUPDATE = "PROTOCOL_OPENHUMANUPDATE",
  PROTOCOL_OPENHUMANRESET = "PROTOCOL_OPENHUMANRESET",
  PROTOCOL_NPS = "PROTOCOL_NPS",
  PROTOCOL_NPSUPDATE = "PROTOCOL_NPSUPDATE",
  PROTOCOL_NPSRESET = "PROTOCOL_NPSRESET",
  PROTOCOL_NPSHUMAN = "PROTOCOL_NPSHUMAN",
  PROTOCOL_NPSHUMANUPDATE = "PROTOCOL_NPSHUMANUPDATE",
  PROTOCOL_NPSHUMANRESET = "PROTOCOL_NPSHUMANRESET",
  PROTOCOL_FLOWHUMAN = "PROTOCOL_FLOWHUMAN",
  PROTOCOL_FLOWHUMANUPDATE = "PROTOCOL_FLOWHUMANUPDATE",
  PROTOCOL_FLOWHUMANRESET = "PROTOCOL_FLOWHUMANRESET",
  PROTOCOL_FLOW = "PROTOCOL_FLOW",
  PROTOCOL_FLOWUPDATE = "PROTOCOL_FLOWUPDATE",
  PROTOCOL_FLOWRESET = "PROTOCOL_FLOWRESET",
  PROTOCOL_NPSBOT = "PROTOCOL_NPSBOT",
  PROTOCOL_NPSBOTUPDATE = "PROTOCOL_NPSBOTUPDATE",
  PROTOCOL_NPSBOTRESET = "PROTOCOL_NPSBOTRESET",
  PROTOCOL_OPENWAITING = "PROTOCOL_OPENWAITING",
  PROTOCOL_OPENWAITINGUPDATE = "PROTOCOL_OPENWAITINGUPDATE",
  PROTOCOL_OPENWAITINGRESET = "PROTOCOL_OPENWAITINGRESET",
  PROTOCOL_VIDEOVOICEQUEUE = "PROTOCOL_VIDEOVOICEQUEUE",
  PROTOCOL_VIDEOVOICEQUEUEUPDATE = "PROTOCOL_VIDEOVOICEQUEUEUPDATE",
  PROTOCOL_VIDEOVOICEQUEUERESET = "PROTOCOL_VIDEOVOICEQUEUERESET",
  PROTOCOL_VIDEOVOICERUN = "PROTOCOL_VIDEOVOICERUN",
  PROTOCOL_VIDEOVOICERUNUPDATE = "PROTOCOL_VIDEOVOICERUNUPDATE",
  PROTOCOL_VIDEOVOICERUNRESET = "PROTOCOL_VIDEOVOICERUNRESET",
  PROTOCOL_OPENWAITINGCLIENT = "PROTOCOL_OPENWAITINGCLIENT",
  PROTOCOL_OPENWAITINGCLIENTUPDATE = "PROTOCOL_OPENWAITINGCLIENTUPDATE",
  PROTOCOL_OPENWAITINGCLIENTRESET = "PROTOCOL_OPENWAITINGCLIENTRESET",
  PROTOCOL_OPENWAITINGOPERATOR = "PROTOCOL_OPENWAITINGOPERATOR",
  PROTOCOL_OPENWAITINGOPERATORUPDATE = "PROTOCOL_OPENWAITINGOPERATORUPDATE",
  PROTOCOL_OPENWAITINGOPERATORRESET = "PROTOCOL_OPENWAITINGOPERATORRESET",
  PROTOCOL_CLOSED = "PROTOCOL_CLOSED",
  PROTOCOL_CLOSEDUPDATE = "PROTOCOL_CLOSEDUPDATE",
  PROTOCOL_CLOSEDRESET = "PROTOCOL_CLOSEDRESET",
  PROTOCOL_CLOSEDBOT = "PROTOCOL_CLOSEDBOT",
  PROTOCOL_CLOSEDBOTUPDATE = "PROTOCOL_CLOSEDBOTUPDATE",
  PROTOCOL_CLOSEDBOTRESET = "PROTOCOL_CLOSEDBOTRESET",
  PROTOCOL_CLOSEDHUMAN = "PROTOCOL_CLOSEDHUMAN",
  PROTOCOL_CLOSEDHUMANUPDATE = "PROTOCOL_CLOSEDHUMANUPDATE",
  PROTOCOL_CLOSEDHUMANRESET = "PROTOCOL_CLOSEDHUMANRESET",
  PROTOCOL_LIVESTREAM = "PROTOCOL_LIVESTREAM",
  PROTOCOL_LIVESTREAMUPDATE = "PROTOCOL_LIVESTREAMUPDATE",
  PROTOCOL_LIVESTREAMRESET = "PROTOCOL_LIVESTREAMRESET",
  PROTOCOL_PUTCPC = "PROTOCOL_PUTCPC";

var sockets = [];
let socketCreate = false;
export function protocolCount() {
  return (dispatch) => {
    getProtocolCount()
      .then((response) => {
        dispatch({
          type: PROTOCOL_COUNT,
          data: response.data,
        });
      })
      .catch((error) => {
        console.log("err", error);
        const status = error.response.status;
        if (status === 401) {
          history.push("/login");
        }
      });
  };
}

export function protocolCountType(department) {
  return (dispatch) => {
    getProtocolCount(`[${department}]`, JSON.stringify(pathChannel))
      .then((response) => {
        dispatch({
          type: PROTOCOL_COUNTTYPE,
          bot: response.data.open.bot,
          human: response.data.open.human + response.data.category,
        });
      })
      .catch((error) => {
        console.log("err", error);
        const status = error.response.status;
        if (status === 401) {
          history.push("/login");
        }
      });
  };
}

export function protocolSocket(
  allDepartment,
  selectDepartment,
  selectChannel,
  selectStatus,
  category,
  isSearch,
  currentSocket
) {
  const status = [
      EnumStatus.WAITING,
      EnumStatus.AWAITING_CUSTOMER,
      EnumStatus.AWAITING_OPERATOR,
    ],
    channel = selectChannel ? selectChannel : pathChannel;

  sockets = [];

  let supportPath = window.location.pathname
    .split("/")
    .filter((x) => x.length > 0);

  if (supportPath.length >= 2 && supportPath[1] === "bot") {
    switch (supportPath[2]) {
      case "aberto":
        selectDepartment.map((dept) =>
          channel.map((ch) =>
            status.map((st) =>
              sockets.push(`department_${dept}_${EnumSupport.BOT}_${ch}_${st}`)
            )
          )
        );
        break;
      case "fechado":
        selectDepartment.map((dept) =>
          channel.map((ch) =>
            sockets.push(
              `department_${dept}_${EnumSupport.BOT}_${ch}_${EnumStatus.CLOSED}`
            )
          )
        );
        break;
      case "pesquisa":
        selectDepartment.map((dept) =>
          channel.map((ch) =>
            sockets.push(
              `department_${dept}_${EnumSupport.BOT}_${ch}_${EnumStatus.NPS}`
            )
          )
        );
        break;
      case "flow":
        selectDepartment.map((dept) =>
          channel.map((ch) =>
            sockets.push(
              `department_${dept}_${EnumSupport.BOT}_${ch}_${EnumStatus.FLOW}`
            )
          )
        );
        break;
      default:
        selectDepartment.map((dept) =>
          channel.map((ch) =>
            Object.values(EnumStatus).map((st) =>
              sockets.push(`department_${dept}_${EnumSupport.BOT}_${ch}_${st}`)
            )
          )
        );
        break;
    }
  } else if (supportPath.length >= 2 && supportPath[1] === "humano") {
    switch (supportPath[2]) {
      case "fila":
        selectDepartment.map((dept) =>
          channel.map((ch) =>
            sockets.push(
              `department_${dept}_${EnumSupport.HUMAN}_${ch}_${EnumStatus.WAITING}`
            )
          )
        );
        break;
      case "atendimento":
        selectDepartment.map((dept) =>
          channel.map((ch) =>
            sockets.push(
              `department_${dept}_${EnumSupport.HUMAN}_${ch}_${EnumStatus.AWAITING_OPERATOR}`
            )
          )
        );
        break;
      case "retorno_cliente":
        selectDepartment.map((dept) =>
          channel.map((ch) =>
            sockets.push(
              `department_${dept}_${EnumSupport.HUMAN}_${ch}_${EnumStatus.AWAITING_CUSTOMER}`
            )
          )
        );
        break;
      case "ligacao":
        selectDepartment.map((dept) =>
          channel.map((ch) =>
            sockets.push(
              `department_${dept}_${EnumSupport.HUMAN}_${ch}_${EnumStatus.VIDEO_VOICE_RUN}`
            )
          )
        );
        break;
      case "ligacao_fila":
        selectDepartment.map((dept) =>
          channel.map((ch) =>
            sockets.push(
              `department_${dept}_${EnumSupport.HUMAN}_${ch}_${EnumStatus.VIDEO_VOICE_QUEUE}`
            )
          )
        );
        break;
      case "pesquisa":
        selectDepartment.map((dept) =>
          channel.map((ch) =>
            sockets.push(
              `department_${dept}_${EnumSupport.HUMAN}_${ch}_${EnumStatus.NPS}`
            )
          )
        );
        break;
      case "livestream":
        selectDepartment.map((dept) =>
          channel.map((ch) =>
            sockets.push(
              `department_${dept}_${EnumSupport.HUMAN}_${ch}_${EnumStatus.LIVE_STREAM}`
            )
          )
        );
        break;
      case "flow":
        selectDepartment.map((dept) =>
          channel.map((ch) =>
            sockets.push(
              `department_${dept}_${EnumSupport.HUMAN}_${ch}_${EnumStatus.FLOW}`
            )
          )
        );
        break;
      case "grupo":
        selectDepartment.map((dept) =>
          [2].map((ch) =>
            sockets.push(
              `department_${dept}_${EnumSupport.HUMAN}_${ch}_${EnumStatus.GROUP}`
            )
          )
        );
        break;
      case "fechado":
        selectDepartment.map((dept) =>
          channel.map((ch) =>
            sockets.push(
              `department_${dept}_${EnumSupport.HUMAN}_${ch}_${EnumStatus.CLOSED}`
            )
          )
        );
        break;
      default:
        selectDepartment.map((dept) =>
          channel.map((ch) =>
            Object.values(EnumStatus).map((st) =>
              sockets.push(
                `department_${dept}_${EnumSupport.HUMAN}_${ch}_${st}`
              )
            )
          )
        );
        break;
    }
  } else {
    switch (supportPath[2]) {
      case "aberto":
        selectDepartment.map((dept) =>
          channel.map((ch) =>
            status.map((st) =>
              Object.values(EnumSupport).map((sp) =>
                sockets.push(`department_${dept}_${sp}_${ch}_${st}`)
              )
            )
          )
        );
        break;
      case "fechado":
        selectDepartment.map((dept) =>
          channel.map((ch) =>
            Object.values(EnumSupport).map((sp) =>
              sockets.push(
                `department_${dept}_${sp}_${ch}_${EnumStatus.CLOSED}`
              )
            )
          )
        );
        break;
      case "pesquisa":
        selectDepartment.map((dept) =>
          channel.map((ch) =>
            Object.values(EnumSupport).map((sp) =>
              sockets.push(`department_${dept}_${sp}_${ch}_${EnumStatus.NPS}`)
            )
          )
        );
        break;
      case "flow":
        selectDepartment.map((dept) =>
          channel.map((ch) =>
            Object.values(EnumSupport).map((sp) =>
              sockets.push(`department_${dept}_${sp}_${ch}_${EnumStatus.FLOW}`)
            )
          )
        );
        break;
      default:
        selectDepartment.map((dept) =>
          channel.map((ch) =>
            Object.values(EnumStatus).map((st) =>
              Object.values(EnumSupport).map((sp) =>
                sockets.push(`department_${dept}_${sp}_${ch}_${st}`)
              )
            )
          )
        );
        break;
    }
  }
  const connectSocket = (e) => {
    const leave = currentSocket.filter((x) => !sockets.includes(x));
    const join = sockets.filter((x) => !currentSocket.includes(x));
    leave.map((x) => socket.emit("leave", x));
    join.map((x) => socket.emit(!isSearch ? "join" : "leave", x));
  };

  socket.off("reconnect", connectSocket);
  socket.on("reconnect", connectSocket);
  connectSocket();
  return sockets;
}

export function protocolCountDepartment() {
  return (dispatch) => {
    getProtocolDeparment()
      .then((response) => {
        dispatch({
          type: PROTOCOL_COUNTDEPARTMENT,
          data: response.data,
        });
      })
      .catch((error) => {
        console.log("err", error);
      });
  };
}

export function finalizeProtocol(id, enqueueSnackbar) {
  return (dispatch) => {
    dispatch({
      type: PROTOCOL_RESET,
    });
    let currPath = window.location.pathname
      .split("/")
      .filter((x) => x.length > 0);
    if (currPath.length === 4 && currPath.includes(`${id}`)) {
      history.push(`/${currPath.filter((x) => x !== `${id}`).join("/")}/`);
    }
    putProtocolClose(id)
      .then(({ data }) => {
        enqueueSnackbar &&
          enqueueSnackbar("Protocolo finalizado com sucesso!", {
            variant: "success",
          });
      })
      .catch((err) => {
        enqueueSnackbar &&
          enqueueSnackbar(err, { key: "finalize", variant: "error" });
      });
  };
}

export function tabProtocol(tab, onSuccess, onError) {
  return (dispatch) => {
    postFinalizeTab(tab)
      .then(({ data }) => {
        if (data.errors || data[0].error) {
          onError && onError();
        } else {
          onSuccess && onSuccess();
        }
      })
      .catch((error) => {
        console.log("err", error);
        onError && onError();
      });
  };
}

export function tabMultiProtocol(tab, onSuccess, onError) {
  return (dispatch) => {
    postMultiFinalizeTab(tab)
      .then(({ data }) => {
        if (data.errors || data[0].error) {
          onError && onError();
        } else {
          onSuccess && onSuccess();
        }
      })
      .catch((error) => {
        console.log("err", error);
        onError && onError();
      });
  };
}

export function ocorrenceProtocol(data, snackbar) {
  return (dispatch) => {
    postProtocoTab(data)
      .then(({ data }) => {
        data[0].error
          ? snackbar(data[0].error, {
              key: "finalize",
              variant: "error",
            })
          : snackbar("Tabulação realizada com sucesso!", {
              variant: "success",
            });
      })
      .catch((error) => {
        console.log("err", error);
      });
  };
}

export function finishProtocol(data) {
  return (dispatch) => {
    putTabProtocol(data)
      .then((response) => {})
      .catch((error) => {
        console.log("err", error);
      });
  };
}

export function pauseProtocol() {
  return (dispatch, getState) => {
    putPauseProtocol(getState().protocolSelect.id, {
      pause: !getState().protocolSelect.pause,
    })
      .then((res) => {
        dispatch({
          type: PROTOCOL_SELECTUPDATE,
          pause: !getState().protocolSelect.pause,
        });
      })
      .catch((err) => console.log("err", err.response));
  };
}

export function addTagProtocol(data) {
  return (dispatch, getState) => {
    postTagProtocol({
      id_tag: data.id,
      id_protocol: getState().protocolSelect.id,
    })
      .then((res) => {
        dispatch({
          type: PROTOCOL_SELECTUPDATE,
          tags: [...getState().protocolSelect.tags, { id: data.id }],
        });
      })
      .catch((err) => console.log("err", err.response));
  };
}

export function putCpcProtocol(item_id, lote_id, templateId, data) {
  return (dispatch, getState) => {
    putCpc(item_id, lote_id, templateId, data)
      .then((res) => {
        dispatch({
          type: PROTOCOL_PUTCPC,
          payload: res.data
        });
      })
      .catch((err) => console.log("err", err.response));
  }
}

export function removeTagProtocol(data) {
  return (dispatch, getState) => {
    putTagProtocol({
      id_tag: data.id,
      id_protocol: getState().protocolSelect.id,
    })
      .then((res) => {
        dispatch({
          type: PROTOCOL_SELECTUPDATE,
          tags: getState().protocolSelect.tags.filter(
            ({ id }) => data.id !== id
          ),
        });
      })
      .catch((err) => console.log("err", err.response));
  };
}

export function selectProtocolStatus(id, status) {
  return (dispatch) => {
    statusProtocol(id, status)
      .then(() => {
        dispatch({
          type: PROTOCOL_CATEGORYCHANGE,
          protocol_id: id,
          protocol_workflow_status: status,
        });
        dispatch({
          type: PROTOCOL_SELECTUPDATE,
          protocol_workflow_status: status,
        });

        postNote(
          id,
          status === 1
            ? "Status: Pendente"
            : status === 2
            ? "Status: Em análise"
            : "Status: Executando"
        )
          .then(() => {
            getNote(id)
              .then((note) => {
                dispatch({
                  type: PROTOCOL_NOTE,
                  notes: note.data,
                });
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((error) => {
            console.log("err", error.response);
          });
      })
      .catch((err) => console.log(err));
  };
}

export function categorizeProtocol(id, tab, xThis) {
  return (dispatch) => {
    dispatch({
      type: PROTOCOL_RESET,
    });

    categoryProtocol(id, tab)
      .then((response) => {
        dispatch(selectProtocolStatus(id, 1));
        const { enqueueSnackbar } = xThis.props;
        enqueueSnackbar("Atendimento categorizado com sucesso!", {
          key: "finalize",
          variant: "success",
        });
      })
      .catch((error) => {
        console.log("err", error);
      });
  };
}

export function transferProtocol(
  protocol_id,
  note,
  new_department_id,
  new_user_id,
  xThis
) {
  return (dispatch) => {
    putProtocolTransfer(protocol_id, note, new_department_id, new_user_id)
      .then((response) => {
        const { enqueueSnackbar, setRef, buttonCloseRef } = xThis.props;
        xThis.buttonCloseRef?.click();
        enqueueSnackbar("Atendimento transferido com sucesso!", {
          key: "finalize",
          variant: "success",
        });
        setRef.current.handleClickOpen();
        const currPath = window.location.pathname
          .split("/")
          .filter((x) => x.length > 0);

        if (currPath.length > 0 && currPath[0] === "chats") {
          let path = window.location.pathname
            .split("/")
            .filter((x) => x.length > 0);
          path.length === 4 && path.pop();
          history.push(`/${path.join("/")}`);
        }
        dispatch({
          type: PROTOCOL_RESET,
        });
      })
      .catch((err) => {
        console.log("err", err);

        if (err?.response?.data?.message) {
          const { enqueueSnackbar } = xThis.props;
          enqueueSnackbar(err?.response?.data?.message, {
            key: "finalize",
            variant: "error",
          });
          xThis.setState({ isBtnDisabled: false });
        }
      });
  };
}

export function removeHistoric(protocol, protocol_id) {
  return (dispatch) => {
    let protocolHistoric = protocol.protocolHistoric;
    delete protocolHistoric[protocol_id];
    dispatch({
      type: PROTOCOL_SELECTUPDATE,
      ...protocol,
      protocolHistoric,
    });
  };
}

export function addCustomer({ id_protocol, crm_id }, xThis) {
  return (dispatch, getState) => {
    dispatch(
      updateProtocol({
        customers: Array.isArray(getState().protocolSelect.customers)
          ? [{ load: true }, ...getState().protocolSelect.customers]
          : [{ load: true }],
      })
    );
    /*if (xThis && xThis.props) {
				xThis.props.onCreateCusto mer && xThis.props.onCreateCustomer();
				xThis.props.setRef.current.handleClickOpen()
			}*/
    putProtocolAddCustomer({ id_protocol, crm_id })
      .then((res) => {
        dispatch(
          updateProtocol({
            customers: [
              { data: res.data.business, ...res.data },
              ...getState().protocolSelect.customers.filter(
                (x) => x.load !== true
              ),
            ],
          })
        )(xThis && xThis.state && xThis.state.sending) &&
          xThis.setState({ sending: false });
      })
      .catch((error) => console.log(error));
  };
}

export function updateCustomer(protocol, customer) {
  return (dispatch) => {
    socket.emit("leave", `crm_${protocol.id}`);
    socket.emit("join", `crm_${protocol.id}`);
    dispatch(
      updateProtocol({
        customers: protocol.customers.map((x) =>
          x.id === customer ? { ...x, load: true } : x
        ),
      })
    );
    getProtocolCustomer(protocol.id, customer)
      .then((res) => {
        dispatch(
          updateProtocol({
            customers: protocol.customers.map((x) =>
              x.id === customer
                ? {
                    ...x,
                    data: res.data.data,
                    load: false,
                  }
                : x
            ),
          })
        );
      })
      .catch((error) => console.log(error));
  };
}

export function selectHistoric(protocol_id, onSuccess, onError) {
	return (dispatch, getState) => {
		getProtocolById(protocol_id)
			.then(({ data }) => {
				if (data.error) {
					return console.log('error')
				}
				dispatch({
					type: PROTOCOL_SELECTUPDATE,
					...getState().protocolSelect,
					protocolHistoric: {
						...getState().protocolSelect.protocolHistoric,
						[protocol_id]: {
							...getState().protocolSelect.history.find(
								(x) => x.protocol_id === protocol_id,
							),
							...data,
						},
					},
				})
				onSuccess && onSuccess()
				return {
					...getState().protocolSelect,
					protocolHistoric: {
						...getState().protocolSelect.protocolHistoric,
						[protocol_id]: {
							...getState().protocolSelect.history.find(
								(x) => x.protocol_id === protocol_id,
							),
							...data,
						},
					},
				}
			})
			.catch((err) => {
				onError && onError(err.response.data)
				if (status === 401) {
					history.push('/login')
				}
			})
	}
}

export const sendMessage = async (data, onSuccess, onError) => {
  postMessage(data)
    .then((res) => {
      onSuccess && onSuccess();
    })
    .catch((err) => {
      console.error("err", err.response);
      onError && onError(err.response.data);
    });
};

/**
 * newProtocol - creates a new customer protocol with data
 * Its automatically redirects to customer service view and dispatches the selected protocol
 * @param {object} data - formData object
 * @param {object} history - react router history object
 * @returns Promise
 */
export function newProtocol(data, history) {
  return (dispatch) =>
    postProtocol(data)
      .then((response) => {
        const { data } = response;

        // TODO: warn - this could be a problem...
        // change view is disconnected with the dispatch action the async could create unexpected results...
        // solve: create another subview with the selected protocol
        // if (redirect) {
        // 	history.push('/chats/atendimento')
        // }
        // maybe need some delay to reset initial state on change view
        setTimeout(() => {
          dispatch(selectProtocol({ protocol_id: data.id, ...data }));
        }, 800);
        return response;
      })
      .catch((error) => {
        if (status === 401) {
          history.push("/login");
        }
        console.error("err", error.response);

        throw error;
      });
}

export function newProtocolV3(formData, onSuccess, onError) {
  return (dispatch) => {
    postProtocol(formData)
      .then((response) => {
        typeof onSuccess === "function" && onSuccess();
        if (formData.get("ia") === "true") {
          history.push(`/chats/bot/aberto`);
          dispatch(
            noteCreate({
              protocol_id: response.data.id,
              note: "I.A foi habilitada ao criar o protocolo e alocado no perfil do BOT",
            })
          );
        }
        if (!formData.get("protocol_id")) {
          const currURL = window.location.pathname
            .split("/")
            .filter((e) => e !== "");
          if (currURL[0] === "chats") {
            if (currURL.length > 3) {
              currURL.pop();
            }
            history.push(`/${currURL.join("/")}/${response.data.id}`);
          }
          dispatch(
            selectProtocol({ protocol_id: response.data.id, ...response.data })
          );
        } else {
          const { data } = response;
          dispatch(
            noteCreate({
              protocol_id: formData.get("protocol_id"),
              note: `Protocolo novo criado: ${data.id}
		Canal: ${channelsData.find((x) => x.id === data.id_channel).label}${
                data.session_video
                  ? `
		Link gerado: https://video.digitalk.com.br/${data.session_video}`
                  : ""
              }${
                formData.get("text")
                  ? `
Mensagem: ${formData.get("text")}`
                  : ""
              }`,
            })
          );
        }
      })
      .catch((error) => {
        if (status === 401) {
          history.push("/login");
        }
        typeof onError === "function" && onError(error.response.data.error);
        console.log("err", error);
        console.log("err", error.response);
      });
  };
}

// @deprecated - please use the generic newProtocol action
// comment: do not use the logic reference xThis here... it could create difficult bugs to solve
export function newProtocolWhatsapp(formData, xThis) {
  return (dispatch) => {
    postProtocol(formData)
      .then((response) => {
        const { enqueueSnackbar, setRef, handleUpdate } = xThis.props;
        xThis.handleReset();
        setRef.current.handleClickOpen();
        enqueueSnackbar("Protocolo criado com sucesso!", {
          variant: "success",
        });
        if (formData.get("ia") === "true") {
          history.push(`/chats/bot/aberto`);
          dispatch(
            noteCreate({
              protocol_id: response.data.id,
              note: "I.A foi habilitada ao criar o protocolo e alocado no perfil do BOT",
            })
          );
        }
        dispatch(
          selectProtocol({ protocol_id: response.data.id, ...response.data })
        );
      })
      .catch((error) => {
        if (status === 401) {
          history.push("/login");
        }
        xThis.setState({
          sending: false,
          errorMessage: error.response.data.error
            ? error.response.data.error
            : "Operação mal-sucedida. Contate o suporte!",
        });
        console.log("err", error.response);
      });
  };
}

export function newProtocolEmail(formData, xThis) {
  return (dispatch) => {
    postProtocolEmail(formData)
      .then((response) => {
        const { protocol } = response.data;
        const { enqueueSnackbar, yThis } = xThis.props;
        yThis._dialogChannel.handleClickOpen();
        enqueueSnackbar("Envio realizado com sucesso!", { variant: "success" });
        dispatch(selectProtocol({ protocol_id: protocol }));
      })
      .catch((error) => {
        console.log(error);
        console.log("err", error.response);
      });
  };
}

export function newProtocolPhone(cpf, department, tab, note, xThis) {
  return (dispatch) => {
    postProtocolPhone(cpf, department, tab, note)
      .then((response) => {
        const { enqueueSnackbar, yThis } = xThis.props;
        yThis._dialogChannel.handleClickOpen();
        enqueueSnackbar("Protocolo finalizado com sucesso!", {
          variant: "success",
        });
        dispatch(selectProtocol({ protocol_id: protocol }));
      })
      .catch((error) => {
        console.log("err", error.response);
      });
  };
}

export function disableIA(protocol) {
  const { protocol_id } = protocol;
  return (dispatch) => {
    putDisable(protocol_id)
      .then((response) => {
        dispatch({
          type: PROTOCOL_DISABLEIA,
        });
      })
      .catch((error) => {
        console.log("err", error.response);
      });
  };
}

export function searchHistory(protocol, search) {
  const { protocol_contact_id, protocol_phone } = protocol;
  return (dispatch) => {
    getProtocolHistory(protocol_contact_id, protocol_phone, search)
      .then((res) => {
        dispatch({
          type: PROTOCOL_HISTORY,
          history: res.data.protocols,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function updateProtocol(data) {
  return (dispatch) => {
    delete data.id;
    delete data.schema_list;
    dispatch({
      type: PROTOCOL_SELECTUPDATE,
      ...data,
    });
  };
}

export function updateSocketCustomer(data, customer_index) {
  return (dispatch) => {
    dispatch({
      type: PROTOCOL_CUSTOMERUPDATE,
      customer_index,
      ...data,
    });
  };
}

export function selectProtocol(protocol) {
  return (dispatch, getState) => {
    dispatch({
      type: PROTOCOL_RESET,
      id: protocol.id,
      select: true,
      load: true,
      loadCRM: true,
      loadHistory: true,
    });
    /*const currPath = window.location.pathname.split('/').filter(x => x.length > 0)
			console.log(currPath)
			if (protocol.id && currPath.length > 0 && currPath[0] === 'chats' && (currPath.length !== 4 || currPath[3] !== protocol.id)) {
				let path = window.location.pathname.split('/').filter((x) => x.length > 0)
				path.length === 4 && path.pop()
				history.push(`/${path.join('/')}/${protocol.id}`)
			}*/

    if (
      protocol.is_recording_screen &&
      (env.REACT_APP_API_URL === "https://api-eletrobras.digitalk.com.br" ||
        env.REACT_APP_API_URL ===
          "https://api-solutis-eletrobras-homol.digitalk.com.br" ||
        env.REACT_APP_API_URL === "http://localhost:6005")
    ) {
      fetch("http://localhost:8000/start", {
        method: "POST",
        "Content-Type": "application/json",
        body: JSON.stringify({
          protocol: protocol.id,
        }),
      })
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    }
    if (
      protocol.tab_status === 2 &&
      protocol.protocol_workflow &&
      protocol.protocol_workflow_status === 1
    ) {
      setTimeout(() => dispatch(selectProtocolStatus(protocol.id, 2)), 200);
    }
    //socket.emit('join', `crm_${protocol.id ?? protocol.protocol_id}_${getState().auth.id}`)

    getProtocolById(protocol.id ?? protocol.protocol_id)
      .then((select) => {
        dispatch({
          type: PROTOCOL_SELECT,
          select: true,
          load: false,
          loadCRM: true,
          loadHistory: true,
          messages: [],
          ...protocol,
          ...select.data,
        });

        getProtocolByIdHistory(protocol.id)
          .then((history) =>
            dispatch({
              type: PROTOCOL_SELECTUPDATE,
              loadHistory: false,
              history: history.data,
            })
          )
          .catch((err) => console.log(err));

				getProtocolByIdCustomer(protocol.id)
					.then((customer) => {
						if (
							Array.isArray(customer.data) &&
							customer.data.length > 0 &&
							Object.keys(customer.data[0].crm_ids).length > 0
						) {
							getCRMTemplate(customer.data[0].crm_ids.schema)
								.then((template) => {
									dispatch({
										type: PROTOCOL_SELECTUPDATE,
										loadCRM: false,
										template: template.data,
										customers: Array.isArray(customer.data)
											? customer.data.filter(
												(x) => getState().protocolSelect.id === x.id_protocol,
											)
											: [],
									})
								})
								.catch((err) => console.log(err))
						} else {
							let schema =
								Array.isArray(getState().config.department) &&
								select.data.department_id &&
								getState().config.department.find(
									({ id }) => id === select.data.department_id,
								).schema
							if (schema) {
								getCRMTemplate(schema)
									.then((template) => {
										dispatch({
											type: PROTOCOL_SELECTUPDATE,
											loadCRM: false,
											template: template.data,
										})
									})
									.catch((err) => console.log(err))
							} else {
								dispatch({
									type: PROTOCOL_SELECTUPDATE,
									loadCRM: false,
								})
							}
						}
					})
					.catch((err) => console.log(err))
			})
			.catch((err) => {
				const status = err.response?.status
				if (status === 400) {
					dispatch({
						type: PROTOCOL_SELECT,
						select: true,
						load: true,
						loadCRM: true,
						loadHistory: true,
						error: err.response?.data.error
					})
				}
				if (status === 401) {
					history.push('/login')
				}
			})
	}
}

export function protocolMessage(protocol, page) {
  return (dispatch) => {
    getMessage(protocol, page)
      .then((response) => {
        dispatch({
          type: PROTOCOL_ADDMESSAGE,
          currentPage: page,
          pages: response.data.pages,
          total: response.data.total,
          message: response.data.messages,
        });
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          history.push("/login");
        }
      });
  };
}

export function queue({ support, path, protocol }) {
  let type;
  if (support === "bot") {
    switch (path) {
      case "aberto":
        type = PROTOCOL_OPENBOTUPDATE;
        break;
      case "fechado":
        type = PROTOCOL_CLOSEDBOTUPDATE;
        break;
      case "pesquisa":
        type = PROTOCOL_NPSBOTUPDATE;
        break;
      case "flow":
        type = PROTOCOL_FLOWBOTUPDATE;
        break;
      default:
        type = PROTOCOL_ALLBOTUPDATE;
        break;
    }
  } else if (support === "humano") {
    switch (path) {
      case "fila":
        type = PROTOCOL_OPENWAITINGUPDATE;
        break;
      case "atendimento":
        type = PROTOCOL_OPENWAITINGOPERATORUPDATE;
        break;
      case "retorno_cliente":
        type = PROTOCOL_OPENWAITINGCLIENTUPDATE;
        break;
      case "ligacao":
        type = PROTOCOL_VIDEOVOICERUNUPDATE;
        break;
      case "ligacao_fila":
        type = PROTOCOL_VIDEOVOICEQUEUEUPDATE;
        break;
      case "fechado":
        type = PROTOCOL_CLOSEDHUMANUPDATE;
        break;
      case "pesquisa":
        type = PROTOCOL_NPSHUMANUPDATE;
        break;
      case "livestream":
        type = PROTOCOL_LIVESTREAMUPDATE;
        break;
      case "flow":
        type = PROTOCOL_FLOWHUMANUPDATE;
        break;
      default:
        type = PROTOCOL_ALLHUMANUPDATE;
        break;
    }
  } else {
    switch (path) {
      case "aberto":
        type = PROTOCOL_OPENUPDATE;
        break;
      case "fechado":
        type = PROTOCOL_CLOSEDUPDATE;
        break;
      case "pesquisa":
        type = PROTOCOL_NPSUPDATE;
        break;
      case "flow":
        type = PROTOCOL_FLOWUPDATE;
        break;
      default:
        type = PROTOCOL_ALLUPDATE;
        break;
    }
  }
  return (dispatch, getState) => {
    if (
      protocol &&
      protocol.status === EnumStatus.CLOSED &&
      getState().protocolSelect &&
      getState().protocolSelect.id === protocol.id
    ) {
      dispatch({ type: PROTOCOL_RESET });
    }
    dispatch({ type, ...protocol, auth: getState().auth });
  };
}

export function protocol(
  path,
  department,
  user,
  date,
  channel,
  resetQueue,
  resetProtocol,
  page,
  orderby,
  sla,
  search
) {
  let closed, support, status, type, limit;
  let supportPath = window.location.pathname
    .split("/")
    .filter((x) => x.length > 0);
  if (supportPath.length >= 2 && supportPath[1] === "bot") {
    support = [EnumSupport.BOT];
    switch (path) {
      case "aberto":
        type = PROTOCOL_OPENBOT;
        closed = [false];
        status = [
          EnumStatus.WAITING,
          EnumStatus.AWAITING_CUSTOMER,
          EnumStatus.AWAITING_OPERATOR,
        ];
        break;
      case "fechado":
        type = PROTOCOL_CLOSEDBOT;
        closed = [true];
        status = [EnumStatus.CLOSED];
        break;
      case "pesquisa":
        type = PROTOCOL_NPSBOT;
        closed = [false];
        status = [EnumStatus.NPS];
        limit = 50;
        break;
      case "flow":
        type = PROTOCOL_FLOWBOT;
        closed = [false];
        status = [EnumStatus.FLOW];
        limit = 50;
        break;
      default:
        type = PROTOCOL_ALLBOT;
        closed = [true, false];
        status = [
          EnumStatus.WAITING,
          EnumStatus.AWAITING_CUSTOMER,
          EnumStatus.AWAITING_OPERATOR,
          EnumStatus.CLOSED,
          EnumStatus.NPS,
          EnumStatus.FLOW,
        ];
        break;
    }
  } else if (supportPath.length >= 2 && supportPath[1] === "humano") {
    support = [EnumSupport.HUMAN];
    switch (path) {
      case "aberto":
        type = PROTOCOL_OPENHUMAN;
        closed = [false];
        status = [
          EnumStatus.WAITING,
          EnumStatus.AWAITING_CUSTOMER,
          EnumStatus.AWAITING_OPERATOR,
        ];
        break;
      case "fila":
        type = PROTOCOL_OPENWAITING;
        closed = [false];
        status = [EnumStatus.WAITING];
        break;
      case "atendimento":
        type = PROTOCOL_OPENWAITINGOPERATOR;
        closed = [false];
        status = [EnumStatus.AWAITING_OPERATOR];
        break;
      case "retorno_cliente":
        type = PROTOCOL_OPENWAITINGCLIENT;
        closed = [false];
        status = [EnumStatus.AWAITING_CUSTOMER];
        break;
      case "ligacao":
        type = PROTOCOL_VIDEOVOICERUN;
        closed = [false];
        status = [EnumStatus.VIDEO_VOICE_RUN];
        break;
      case "ligacao_fila":
        type = PROTOCOL_VIDEOVOICEQUEUE;
        closed = [false];
        status = [EnumStatus.VIDEO_VOICE_QUEUE];
        break;
      case "fechado":
        type = PROTOCOL_CLOSEDHUMAN;
        closed = [true];
        status = [EnumStatus.CLOSED];
        limit = 50;
        break;
      case "grupo":
        type = PROTOCOL_CLOSEDHUMAN;
        closed = [true];
        status = [EnumStatus.CLOSED];
        limit = 50;
        break;
      case "livestream":
        type = PROTOCOL_LIVESTREAM;
        closed = [true];
        status = [EnumStatus.LIVE_STREAM];
        limit = 50;
        break;
      case "flow":
        type = PROTOCOL_FLOWHUMAN;
        closed = [true];
        status = [EnumStatus.FLOW];
        limit = 50;
        break;
      case "pesquisa":
        type = PROTOCOL_NPSHUMAN;
        closed = [false];
        status = [EnumStatus.NPS];
        limit = 50;
        break;
      default:
        type = PROTOCOL_ALLHUMAN;
        closed = [true, false];
        status = Object.values(EnumStatus);
        break;
    }
  } else {
    support = Object.values(EnumSupport);
    switch (path) {
      case "aberto":
        type = PROTOCOL_OPEN;
        closed = [false];
        status = [
          EnumStatus.WAITING,
          EnumStatus.AWAITING_CUSTOMER,
          EnumStatus.AWAITING_OPERATOR,
        ];
        break;
      case "fechado":
        type = PROTOCOL_CLOSED;
        closed = [true];
        status = [EnumStatus.CLOSED];
        break;
      case "pesquisa":
        type = PROTOCOL_NPS;
        closed = [false];
        status = [EnumStatus.NPS];
        break;
      case "flow":
        type = PROTOCOL_FLOW;
        closed = [false];
        status = [EnumStatus.FLOW];
        break;
      default:
        type = PROTOCOL_ALL;
        closed = [true, false];
        status = Object.values(EnumStatus);
        break;
    }
  }
  if (
    supportPath.length >= 2 &&
    supportPath[1] === "humano" &&
    path === "grupo"
  ) {
    return (dispatch) => dispatch(groupList());
  }
  return (dispatch) => {
    /*if (resetProtocol) {
				dispatch({type: PROTOCOL_RESET})
			}*/
    if (resetQueue) {
      dispatch({ type: `${type}RESET` });
    }
    let data = {
      department,
      channel,
      date,
      closed,
      orderby,
      user,
      support,
      sla,
      page,
      status,
    };

    if (data.orderby === "channel") {
      data.orderby = "desc";
      data.orderbychannel = channel;
    }
    data = user && user.length > 0 ? { ...data, user } : data;
    data = limit ? { ...data, limit } : data;
    data = search !== "" ? { ...data, search } : data;
    getProtocol(data)
      .then((response) => {
        const status = response.status;
        dispatch({
          type,
          currentPage: page ? page : 1,
          pages: response.data.length > 0 ? page + 1 : page,
          search,
          protocols: response.data,
          status: status
        });
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        if (status === 401) {
          history.push("/login");
        }
      });
  };
}

export function protocolOtherQueue(
  path,
  department,
  user,
  date,
  channel,
  resetQueue,
  resetProtocol,
  page,
  orderby,
  sla,
  search,
  set
) {
  const fnc = async () => {
    let response = {};
    let supportPath = window.location.pathname
      .split("/")
      .filter((x) => x.length > 0);
    if (supportPath.length >= 2) {
      if (path !== "aberto" || supportPath[1] !== "bot") {
        let data = {
          department,
          channel,
          sla,
          orderby,
          support: [EnumSupport.BOT],
          page: 1,
          status: [
            EnumStatus.WAITING,
            EnumStatus.AWAITING_CUSTOMER,
            EnumStatus.AWAITING_CUSTOMER,
          ],
          limit: 50,
        };
        user.length > 0 && (data = { ...data, user });
        search !== "" && (data = { ...data, search, date });
        const res = await getProtocol(data);
        res.data.length > 0 && (response["abertos_bot"] = res.data);
      }
      if (path !== "fila" || supportPath[1] !== "humano") {
        let data = {
          department,
          channel,
          sla,
          orderby,
          support: [EnumSupport.HUMAN],
          page: 1,
          status: [EnumStatus.WAITING],
          limit: 50,
        };
        user.length > 0 && (data = { ...data, user });
        search !== "" && (data = { ...data, search, date });
        const res = await getProtocol(data);
        res.data.length > 0 && (response["fila"] = res.data);
      }
      if (path !== "atendimento" || supportPath[1] !== "humano") {
        let data = {
          department,
          channel,
          sla,
          orderby,
          support: [EnumSupport.HUMAN],
          page: 1,
          status: [EnumStatus.AWAITING_OPERATOR],
          limit: 50,
        };
        user.length > 0 && (data = { ...data, user });
        search !== "" && (data = { ...data, search, date });
        const res = await getProtocol(data);
        res.data.length > 0 && (response["atendimento"] = res.data);
      }
      if (path !== "retorno_cliente" || supportPath[1] !== "humano") {
        let data = {
          department,
          channel,
          sla,
          orderby,
          support: [EnumSupport.HUMAN],
          page: 1,
          status: [EnumStatus.AWAITING_CUSTOMER],
          limit: 50,
        };
        user.length > 0 && (data = { ...data, user });
        search !== "" && (data = { ...data, search, date });
        const res = await getProtocol(data);
        res.data.length > 0 && (response["retorno_cliente"] = res.data);
      }
      if (path !== "ligacao" || supportPath[1] !== "humano") {
        let data = {
          department,
          channel,
          sla,
          orderby,
          support: [EnumSupport.HUMAN],
          page: 1,
          status: [EnumStatus.VIDEO_VOICE_RUN],
          limit: 50,
        };
        user.length > 0 && (data = { ...data, user });
        search !== "" && (data = { ...data, search, date });
        const res = await getProtocol(data);
        res.data.length > 0 && (response["ligacao"] = res.data);
      }
      if (path !== "ligacao_fila" || supportPath[1] !== "humano") {
        let data = {
          department,
          channel,
          sla,
          orderby,
          support: [EnumSupport.HUMAN],
          page: 1,
          status: [EnumStatus.VIDEO_VOICE_QUEUE],
          limit: 50,
        };
        user.length > 0 && (data = { ...data, user });
        search !== "" && (data = { ...data, search, date });
        const res = await getProtocol(data);
        res.data.length > 0 && (response["ligacao_fila"] = res.data);
      }
      if (path !== "fechado" || supportPath[1] !== "bot") {
        let data = {
          department,
          channel,
          sla,
          orderby,
          support: [EnumSupport.BOT],
          page: 1,
          status: [EnumStatus.CLOSED],
          limit: 50,
        };
        user.length > 0 && (data = { ...data, user });
        search !== "" && (data = { ...data, search, date });
        const res = await getProtocol(data);
        res.data.length > 0 && (response["fechados_bot"] = res.data);
      }
      if (path !== "fechado" || supportPath[1] !== "humano") {
        let data = {
          department,
          channel,
          sla,
          orderby,
          support: [EnumSupport.HUMAN],
          page: 1,
          status: [EnumStatus.CLOSED],
          limit: 50,
        };
        user.length > 0 && (data = { ...data, user });
        search !== "" && (data = { ...data, search, date });
        const res = await getProtocol(data);
        res.data.length > 0 && (response["fechados_humano"] = res.data);
      }
      if (path !== "livestream" || supportPath[1] !== "humano") {
        let data = {
          department,
          channel,
          sla,
          orderby,
          support: [EnumSupport.HUMAN],
          page: 1,
          status: [EnumStatus.LIVE_STREAM],
          limit: 50,
        };
        user.length > 0 && (data = { ...data, user });
        search !== "" && (data = { ...data, search, date });
        const res = await getProtocol(data);
        res.data.length > 0 && (response["livestream"] = res.data);
      }

      if (path !== "pesquisa" || supportPath[1] !== "humano") {
        let data = {
          department,
          channel,
          sla,
          orderby,
          support: [EnumSupport.HUMAN],
          page: 1,
          status: [EnumStatus.NPS],
          limit: 50,
        };
        user.length > 0 && (data = { ...data, user });
        search !== "" && (data = { ...data, search, date });
        const res = await getProtocol(data);
        res.data.length > 0 && (response["pesquisa_humano"] = res.data);
      }

      if (path !== "pesquisa" || supportPath[1] !== "bot") {
        let data = {
          department,
          channel,
          sla,
          orderby,
          support: [EnumSupport.BOT],
          page: 1,
          status: [EnumStatus.NPS],
          limit: 50,
        };
        user.length > 0 && (data = { ...data, user });
        search !== "" && (data = { ...data, search, date });
        const res = await getProtocol(data);
        res.data.length > 0 && (response["pesquisa_bot"] = res.data);
      }
      if (path !== "flow" || supportPath[1] !== "humano") {
        let data = {
          department,
          channel,
          sla,
          orderby,
          support: [EnumSupport.HUMAN],
          page: 1,
          status: [EnumStatus.FLOW],
          limit: 50,
        };
        user.length > 0 && (data = { ...data, user });
        search !== "" && (data = { ...data, search, date });
        const res = await getProtocol(data);
        res.data.length > 0 && (response["flow_humano"] = res.data);
      }

      if (path !== "flow" || supportPath[1] !== "bot") {
        let data = {
          department,
          channel,
          sla,
          orderby,
          support: [EnumSupport.BOT],
          page: 1,
          status: [EnumStatus.FLOW],
          limit: 50,
        };
        user.length > 0 && (data = { ...data, user });
        search !== "" && (data = { ...data, search, date });
        const res = await getProtocol(data);
        res.data.length > 0 && (response["flow_bot"] = res.data);
      }
    }
    set && set(response);
  };
  fnc();
}

export function noteCreate(data, xThis) {
  return (dispatch) => {
    postNote(data)
      .then((response) => {
        /*dispatch({
							type: PROTOCOL_NOTE,
							notes: response.data,
						})*/
        if (xThis !== undefined && xThis) {
          if (xThis.messages && xThis.messages.lastElementChild) {
            xThis.messages.lastElementChild.scrollIntoView();
          }
        }
      })
      .catch((error) => {
        console.log("err", error.response);
      });
  };
}
